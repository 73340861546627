// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect } from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import { Modal, Card, Spin, Space, Row, Select } from 'antd';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { AuditOutlined } from '@ant-design/icons';
import { PageLayout } from '../../../components/pageLayout/PageLayout';
import { ReportMonitorStockSearchForm } from '../../../constants/global/SearchForm';
import { ReportMonitorStockColumn } from '../../../constants/global/columnTableForm';
import { GET, GET_BRANCH, GET_MONITOR_STOCK_REPORT, FECTH_UPDATE_PRODUCT_SERIAL, GET_PRODUCT_NAME_OPTION } from '../../../services/index';
import { CSVLink } from "react-csv";
import { auth } from '../../../utils/firebase';
import { useCurrentState } from '../../../utils/hook';
import { mergeObjectData } from '../../../functions/fn';
import { Overview } from './styled-component';

const MonitorStock = () => {

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [optionBranch, setOptionBranch] = useState([])
  const [optionProductName, setOptionProductName] = useState([])
  const [dataExport, setDataExport] = useState([])
  const { user } = useCurrentState(auth);
  const [branchDefault, setBranchDefault] = useState("")
  const [typeDefault, setTypeDefault] = useState("")
  const [serialStatusDefault, setSerialStatusDefault] = useState("")
  const [summary, setSummary] = useState([])
  const [defaultSearch, setDefaultSearch] = useState({ branch: "", product: "", type: '', serial_status: "", itemTypeReportMonitor: '', serialStatusReportMonitor: '' })
  
  const optionItemType = [
    { label: 'ทั้งหมด', value: 'TOTAL' },
    { label: 'มือหนึ่ง', value: '1' },
    { label: 'มือสอง', value: '2' },
    { label: 'พิเศษ', value: '3' }
  ];


  const optionSerialStatus = [
    { value: 'TOTAL', label: 'ทั้งหมด' },
    { value: 'READY', label: 'พร้อมขาย' },
    { value: 'NOT_READY', label: 'ไม่พร้อมขาย' },
  ]

  useEffect(() => {
    setTypeDefault("1")
    setSerialStatusDefault('READY')
    if (user && user.branch) {
      getOption()
      setBranchDefault(user.branch === "CENTER" ? "" : user.branch)
      getReportMonitorStock({ branch: user.branch === "CENTER" ? "" : user.branch, product: "", type: '1', serial_status: "READY" });
      setDefaultSearch({ branch: user.branch === "CENTER" ? "" : user.branch, product: "", type: '1', serial_status: "READY", itemTypeReportMonitor: '1', serialStatusReportMonitor: 'READY' });
      getOptionBranch()
    }
  }, [user]);

  const getOption = async () => {
    try {
      const res = await GET(GET_PRODUCT_NAME_OPTION);

      const { success, data } = res;
      if (success) {
        // const product = data
        setOptionProductName(data)
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  }

  const getOptionBranch = async () => {
    try {
      const res = await GET(GET_BRANCH);
      const { success, result } = res;
      if (success) {

        const option = result.branch.map((el) => {
          return {
            label: el.label,
            value: el.value
          }
        })

        const optionCheck = user.branch === "CENTER" ? option : option.filter((el) => {
          if (el.value === user.branch) {
            return {
              label: el.label,
              value: el.value
            }
          }
        })


        setOptionBranch([...optionCheck, { value: "", label: "ทุกสาขา" }]);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };



  const getReportMonitorStock = async (data) => {

    data.branch = data.branch || ''
    data.product = data.product || ''
    data.type = data.type || ''
    data.serial_status = data.serial_status || ''

    if (data.type === 'TOTAL') {
      data.type = ''
    }

    if (data.serial_status === 'TOTAL') {
      data.serial_status = ''
    }

    const { branch, product, type, serial_status } = data

    let typeConvert = typeDefault === 'TOTAL' ? '' : typeDefault
    // let typeConvert2 = typeDefault === 'TOTAL' ? 'TOTAL' : typeDefault
    let serialStatusConvert = serialStatusDefault === 'TOTAL' ? '' : serialStatusDefault
    setDefaultSearch({ branch: branch, product: product, type: typeConvert, serial_status: serialStatusConvert, itemTypeReportMonitor: typeConvert, serialStatusReportMonitor: serialStatusConvert });

    setLoading(true);
    try {
      let res = await GET(GET_MONITOR_STOCK_REPORT(branch, product, typeConvert, serialStatusConvert));

      let branchSearch = data.branch || ''
      let serialStatusSearch = data.serial_status || ''
      // setBranchSearch(branchSearchDefault)
      localStorage.setItem('branchReportMonitorStock', branchSearch)
      // localStorage.setItem('itemTypeReportMonitorStock', typeConvert2)
      localStorage.setItem('serialStatusReportMonitorStock', serialStatusSearch)


      const { success, result } = res;
      if (success) {
        const resultData = result.report.map((val, i) => {
          return {
            no: i + 1,
            ...val,
          };
        });
        const dataEx = result.report.map((el, i) => {
          return {
            'รหัสสินค้า': el.item_code,
            'ชื่อสินค้า': el.item_name,
            'Serial Status': el.serial_status,
            'จำนวน': el.stock,
            'ระหว่างส่ง': el.inTransit,
            'จอง': el.reserve,
            'คงเหลือ': el.remaining,
          };
        });

        const prepareSummaryData = result.report.map((el, i) => {
          return {
            stock: el.stock,
            inTransit: el.inTransit,
            reserve: el.reserve,
            remaining: el.remaining,
          };
        });

        const summaryData = [mergeObjectData(prepareSummaryData)].map((el) => {
          let data = [
            { label: 'จำนวนทั้งหมด', value: el.stock, color: 'purple' },
            { label: 'ระหว่างส่ง', value: el.inTransit, color: 'geekblue' },
            { label: 'จอง', value: el.reserve, color: 'gold' },
            { label: 'คงเหลือ', value: el.remaining, color: 'green' },
          ]
          return data
        })

        setDataExport(dataEx)
        setDataSource(resultData);
        setSummary(summaryData);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const updateDataProductsCurrent = async () => {
    try {
      setLoading(true);
      const res = await GET(FECTH_UPDATE_PRODUCT_SERIAL)
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            getReportMonitorStock({ branch: branchDefault, type: typeDefault })
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }

  }

  const onClearCustom = (setValue, formSearch) => {
    formSearch.forEach((val) => setValue(val.name, null));
    setTypeDefault('')
    setSerialStatusDefault('')
    setDefaultSearch({ branch: null, product: null, type: null, serial_status: null, itemTypeReportMonitor: null, serialStatusReportMonitor: null });
  }


  const setField = (data, ref) => {
    if (ref === 'type') {
      if (data === undefined) {
        data = ''
      }
      setTypeDefault(data)
    } else if (ref === 'serial_status') {
      if (data === undefined) {
        data = ''
      }
      setSerialStatusDefault(data)
    }
  }

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>

          <PageLayout
            searchLayout={{
              title: 'รายงานสต็อก',
              icon: <AuditOutlined />,
              spanSearch: 24,
              formSearch: ReportMonitorStockSearchForm({ option: { optionBranch, optionItemType, optionProductName, optionSerialStatus }, data: { branchDefault, typeDefault, serialStatusDefault }, fn: { setField } }),
              onSearch: (data) => getReportMonitorStock(data),
              // dataSearchDefault: defaultSearch,
              onClearCustom,
            }}
            tableLayout={{
              columns: ReportMonitorStockColumn(),
              dataSource: dataSource,
              extraContent: (
                <Row
                  style={{
                    width: '100%',
                  }}
                  align="bottom"
                  justify="space-between">
                  <Space>
                    {summary[0] !== undefined ? summary[0].map((item, index) => {
                      return <Overview key={index} color={item.color}>{`${item.label}:  ${item.value === undefined ? '' : item.value}`}</Overview>;
                    }) : <></>}
                  </Space>
                  <Space>
                    <ContainerButton right>
                      <ButtonTheme useFor="RANDOM" title={'ดึงข้อมูลใหม่'} onClick={() => updateDataProductsCurrent()}/>
                      <CSVLink data={dataExport} filename={"รายงานสต็อก"}><ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} /></CSVLink>
                    </ContainerButton>
                  </Space>
                </Row>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default MonitorStock;
