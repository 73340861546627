import React from 'react';
import { Col, Row } from 'antd';
import { InputAreaLabel, InputLabel, InputPassword, InputNumber, InputNumberOnly, InputPrice,InputLabelBlack } from '../../components/forms/input';
import { TextXSMall, TextSmall } from '../../components/forms/text';
import { SelectDropdown, SelectMultiple, TreeSelectDropdown, SelectDropdownBanner, SelectDropdownDefault, SelectGroup, SelectOptionGroup } from '../../components/forms/dropdown.js';
import { DatePickerForm, RangPickerTheme } from '../../components/forms/datePicker';
import { CheckboxTheme, CheckboxGroupTheme, CheckboxOption, CheckboxDefault } from '../../components/forms/checkbox';
import { TreeGroupTheme } from '../../components/forms/tree';
import { UploadFilelist, UploadTheme, UploadThemeWithHeader } from '../../components/forms/upload';
import { RangeDatePickerForm } from '../../components/forms/rangeDatePicker';
import { AutoCompleteForm } from '../../components/forms/autoComplete';
import { RadioTheme } from '../../components/forms/radio';
import { TimePickerForm } from '../../components/forms/timePicker';
import { SwitchTheme } from '../../components/forms/switch';
import { ViewImage, ViewImageGroup } from '../../components/image/index';
import { SyncIDcard } from '../../components/modal/CustomerList/SyncIDcard';
import { ModalOtherTel } from '../../components/modal/CustomerList/ModalOtherTel';
import { TextForm } from '../../components/forms/textForm';
import { ModalSignature } from '../../components/modal/SecondHand/ModalSignature.js';
import TagLabel from "../../components/tag/TagLabel"
import { IconTextForm } from '../../components/forms/iconTextForm.js';

const RenderForm = ({
  formList,
  setValue,
  onChange,
  clearErrors,
  onClear,
  getValues,
  errors,
  control,
  renderButton,
  spanSearch,
  styleRow,
  justify,
  ...rest
}) => {
  function handleChange(e, item, option) {
    if (!e) {
      return;
    }

    switch (item.type) {
      case 'DROPDOWN':
      case 'SELECT':
      case 'SELECT_GROUP':
      case 'DATE_PICKER':
      case 'RANG_PICKER':
      case 'TIME_PICKER':
      case 'RADIO':
        setValue(item.name, e);
        break;
      case 'FILE_UPLOAD':
      case 'FILE_UPLOAD_WITH_HEADER':
      case 'IMAGE_UPLOAD':
        setValue(item.name, e.fileList);
        break;
      case 'SELECT_MODAL':
        setValue(item.name, e);
        break;
      default:
        setValue(item.name, e.target.value);
        break;
    }

    onChange && onChange(e, item, option);
    clearErrors && clearErrors(item.name);
  }

  function handleClear(e, item) {
    setValue(item.ref, '');
    onClear && onClear(e, item);
  }

  const stuff = {
    control,
    errors,
    getValues,
    setValue,
    handleChange,
    handleClear,
  };
  const renderCustomForm = (f) => {
    if (f.oneLine) {
      return (
        <>
          <Row>
            <Col>{renderRequiredStyle(f.rules, f.label, f.showRequired, f.type, f.hiddenRequired, f.remark)}</Col>
            <Col style={{ marginLeft: '10px' }}>{renderInputType(f, stuff)}</Col>
            <Col style={{ marginLeft: '10px' }}>{renderComponent(f, stuff)}</Col>
          </Row>
        </>
      );
    }
    else if (f?.iconText) {
      return (
        <>
          <Row>
            <Col span={24}>{renderComponent(f, stuff)}</Col>
          </Row>
        </>
      );
    }
    else {
      return (
        <>
          {renderRequiredStyle(f.rules, f.label, f.showRequired, f.type, f.hiddenRequired, f.remark)}
          {renderLabelRed(f)}
          {renderInputType(f, stuff)}
          {renderComponent(f, stuff)}
        </>
      );
    }
  };

  spanSearch = spanSearch ? spanSearch : 24;

  return (
    <Row gutter={[16, 16]} style={styleRow} justify={justify || 'center'}>
      {formList.map((f) => {
        return !f.hidden ? (
          f.title ? (
            <>
              <Row style={{ width: '100%', marginTop: '8px', paddingLeft: '8px' }}>
                <TextSmall color={'#0751A9'} bold={true} text={f.title} />
              </Row>
              {f.data.map((v, k) => {
                return (
                  <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: v.span }} lg={{ span: v.span }} style={{ ...v.style }} key={k}>
                    {renderRequiredStyle(v.rules, v.label, v.showRequired, v.type, v.hiddenRequired, f.remark)}
                    {renderInputType(v, stuff)}
                    {renderComponent(v, stuff)}
                    {renderTextError(v.label, v.name, errors, v.defaultValue)}
                  </Col>
                );
              })}
            </>
          ) : (
            <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: f.span }} lg={{ span: f.span }} style={{ ...f.style }}>
              {renderCustomForm(f)}
              {/* {renderComponent(f, stuff)} */}
              {renderTextError(f.label, f.name, errors, f.defaultValue)}
            </Col>
          )
        ) : null;
      })}

      <Col xs={{ span: 24 }} sm={{ span: 24 }} xl={{ span: spanSearch }} lg={{ span: spanSearch }} style={{ padding: 0, display: 'flex', marginTop: '2%' }}>
        {renderButton}
      </Col>
    </Row>
  );
};

const renderTextError = (label, name, errors, defaultValue) => {
  return errors[name] ? <TextXSMall color={'red'} text={renderTypeError(label, name, errors, defaultValue)} /> : '';
};

const renderTypeError = (label, name, errors) => {
  switch (errors[name].type) {
    case 'required':
      return errors[name].message
        ? errors[name].message
        : errors[name].ref.name === 'statusApprove'
          ? 'กรุณาเลือกอย่างใดอย่างหนึ่ง'
          : `โปรดระบุ${label}`;
    case 'pattern':
      return errors[name].message ? errors[name].message : `รูปแบบ ${label} ไม่ถูกต้อง`;
    case 'maxLength':
      return errors[name].message ? errors[name].message : `ระบุไม่เกิน ${errors[name].message} ตัวอักษร`;
    case 'minLength':
      return errors[name].message ? errors[name].message : `ระบุไม่น้อยกว่า ${errors[name].message} ตัวอักษร`;
    case 'min':
      return `โปรดระบุจำนวนไม่น้อยกว่า ${errors[name].message}`;
    case 'max':
      return `โปรดระบุจำนวนไม่เกิน ${errors[name].message}`;
    case 'same':
      return errors[name].message ? errors[name].message : `ข้อมูล ${errors[name].message}`;
    default:
      return `${errors[name].message}`;
  }
};

const renderRequiredStyle = (rules, label, showRequired, type, hiddenRequired, remark) => {
  return type !== 'TEXT_DISPLAY' ? (
    <>
      <div style={{ display: 'flex' }}>
        {hiddenRequired ? <></> : (!!rules && rules.required) || showRequired ? <TextSmall text={ `*`} color={'red'} /> : ''}
        {/* {(!!rules && rules.required) || showRequired ? <TextSmall text={type !== 'CHECKBOX' ? `*` : ''} color={'red'} /> : ''} */}
        <TextSmall text={label} bold={'500'} />
      </div>
      <div>
        {remark ? <TextSmall text={` ${remark}`} color={'red'} /> : ''}
      </div>
    </>
  ) : (
    ''
  );
};

const renderComponent = (e, stuff) => {
  switch (e.component) {
    case 'SYNC_ID_CARD':
      return <SyncIDcard item={e} {...stuff} />;
    case 'MODAL_OTHER_TEL':
      return <ModalOtherTel item={e} {...stuff} />;
    case 'TEXT_FORM':
      return <TextForm item={e} {...stuff} />;
    case 'MODAL_SIGNATURE':
      return <ModalSignature item={e} {...stuff} />;
    case 'TAG':
      return <TagLabel item={e} {...stuff} />;
    case 'ICON_TEXT_FORM':
      return <IconTextForm item={e} {...stuff} />;
    default:
      break;
  }
};

const renderInputType = (e, stuff) => {
  switch (e.type) {
    case 'INPUT':
      return <InputLabel item={e} {...stuff} />;
    case 'INPUT_BLACK_FILED':
      return <InputLabelBlack item={e} {...stuff} />;
    case 'INPUT_NUMBER_ONLY':
      return <InputNumberOnly item={e} {...stuff} />;
    case 'INPUT_NUMBER':
      return <InputNumber item={e} {...stuff} />;
    case 'INPUT_AREA':
      return <InputAreaLabel item={e} {...stuff} />;
    case 'INPUT_PASSWORD':
      return <InputPassword item={e} {...stuff} />;
    case 'CHECKBOX':
      return <CheckboxTheme item={e} {...stuff} />;
    case 'CHECKBOX GROUP':
      return <CheckboxGroupTheme item={e} {...stuff} />;
    case 'CHECKBOX_OPTION':
      return <CheckboxOption item={e} {...stuff} />;
    case 'CHECKBOX_DEFAULT':
      return <CheckboxDefault item={e} {...stuff} />;
    case 'SELECT':
      return <SelectDropdown item={e} {...stuff} />;
    case 'SELECT_DEFAULT':
      return <SelectDropdownDefault item={e} {...stuff} />;
    case 'SELECT_GROUP':
      return <SelectGroup item={e} {...stuff} />;
    case 'RADIO':
      return <RadioTheme item={e} {...stuff} />;
    case 'DATE_PICKER':
      return <DatePickerForm item={e} {...stuff} />;
    case 'RANG_PICKER':
      return <RangPickerTheme item={e} {...stuff} />;
    case 'SWITCH':
      return <SwitchTheme item={e} {...stuff} />;
    case 'VIEWIMAGE':
      return <ViewImage item={e} {...stuff} />;
    case 'VIEW_IMAGE_GROUP':
      return <ViewImageGroup item={e} {...stuff} />;
    case 'FILE_UPLOAD':
      return <UploadTheme item={e} {...stuff} />;
    case 'FILE_LIST_UPLOAD':
      return <UploadFilelist item={e} {...stuff} />;
    case 'FILE_UPLOAD_WITH_HEADER':
      return <UploadThemeWithHeader item={e} {...stuff} />;
    case 'TIME_PICKER':
      return <TimePickerForm item={e} {...stuff} />;
    case 'TREE_SELECT':
      return <TreeGroupTheme item={e} {...stuff} />;
    case 'RANGE_DATE_PICKER':
      return <RangeDatePickerForm item={e} {...stuff} />;
    case 'AUTO_COMPLETE':
      return <AutoCompleteForm item={e} {...stuff} />;
    case 'INPUT_PRICE':
      return <InputPrice item={e} {...stuff} />;
    case 'TEXT':
      return <TextSmall text={e.value} />;
    // case 'SELECT_BANNER':
    //   return <SelectDropdownBanner item={e} {...stuff} />;
    case 'PHOTO_IDCARD':
      return <img src={e.defaultValue} alt="id card" style={{ width: 148 }} />;
    case 'SELECT_MATCH_SELECT_WIDTH':
      return <SelectDropdown item={e} {...stuff} dropdownMatchSelectWidth={false} />;
    // case 'CHECKBOX':
    //   return <CheckboxTheme item={e} {...stuff} />;
    // case 'RADIO':
    //   return <RadioTheme item={e} {...stuff} />;

    // case 'UPLOAD_IMAGE':
    //   return <UploadImageComponent item={e} {...stuff} />;
    // case 'UPLOAD_IMAGE_PREVIEW':
    //   return <UploadImagePreviewComponent item={e} {...stuff} />;
    // case 'TEXT_DISPLAY':
    //   return (
    //     <div style={{display: 'flex'}}>
    //       <TextSmall bold text={e.label} />: &nbsp;
    //       <TextSmall text={e.defaultValue} />
    //     </div>
    //   );
    default:
      return
  }
};

const renderLabelRed = (f) => {
  return f?.labelRed ? <div style={{ paddingLeft: '20px' }}><TextXSMall color={'red'} text={f.labelRed} /></div> : '';
};

export default RenderForm;
