import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { PUT, EDIT_UNLOCK_ICLOUD_STATUS_CRM } from '../../../services/index';
import { Icon } from '../../../resources/icon';
import { color } from '../../../resources/color'

export const UnlockStatusCRM = ({ title, visible, onClose, data }) => {
  const {
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (JSON.stringify(data && data.record) !== '{}' && data.record) {
      const dataValue = Object.keys(data.record);
      dataValue.forEach((val) => setValue(val, data.record[val]));
      setRecord(data.record);
    }
  }, [data]);

  const openConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันการเปลียนสถานะ',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onSubmit = async (params) => {
    setLoading(true);
    try {
      let res = await PUT(EDIT_UNLOCK_ICLOUD_STATUS_CRM(record.contract_no), { unlock_status: params });
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            data.reApi();
            onCloseMain();
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onCloseMain = () => {
    clearErrors();
    onClose();
  };
  return (
    <Modal title={title} open={visible} width={550} onCancel={onCloseMain} footer={null} destroyOnClose={true} maskClosable={false}>
      <ContainerButton left>
        {(data.record.unlock_status?.code === 'CONTACT_ADMIN' || data.record.unlock_status?.code === 'NO_STATUS') && (
          <ButtonTheme useFor="CUSTOM" title="สร้างสัญญาใหม่" bgcolor={color.create} onClick={() => openConfirm('NEW_CONTRACT')} loading={loading} />
        )}
        {(data.record.unlock_status?.code === 'NEW_CONTRACT' || data.record.unlock_status?.code === 'NO_STATUS') && (
          <ButtonTheme useFor="CONTACT" title="ติดต่อเจ้าหน้าที่" onClick={() => openConfirm('CONTACT_ADMIN')} loading={loading} />
        )}
        <ButtonTheme useFor="UNLOCK" title="ยืนยันขอรหัส" onClick={() => openConfirm('WAIT_APPROVE')} loading={loading} />

      </ContainerButton>
      <ContainerButton right>
        <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
      </ContainerButton>
    </Modal>
  );
};
