import { Col, Modal, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Icon } from '../../../resources/icon';
import { ContainerButton } from '../../../styles/styledComponentGlobal';

import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { FORM_PRODUCT_DOWN_PAYMENT } from '../../../constants/global/actionform';
import { optionsRoundPayment } from '../../../screens/monitor/monitor-customer/hook/useGetOptions';
import {
  CALCULATE_AMOUNT_DOWN_PAYMENT,
  CALCULATE_MONTH_DOWN_PAYMENT,
  DOWN_PAYMENT_UPDATE_PRODUCT,
  GET,
  GET_DOWN_PAYMENT,
  GET_PRODUCT_COLOR_DOWNPAYMENT,
  GET_PRODUCT_COMBO_DOWNPAYMENT,
  GET_PRODUCT_MODEL_DOWNPAYMENT,
  GET_PRODUCT_STORAGE_DOWNPAYMENT,
  POST,
} from '../../../services/index';
import { ButtonTheme } from '../../buttons';
import _ from 'lodash'

export const DownPaymentProductModal = ({ title, visible, onClose, data }) => {
  const [loading, setLoading] = useState(false);
  const [downpaymentNo, setDownpaymentNo] = useState('');
  const [optionsProducts, setOptionsProducts] = useState([]);
  const [optionsStorage, setOptionsStorage] = useState([]);
  const [optionsColor, setOptionsColor] = useState([]);
  const [chooseCheckboxCombo, setChooseCheckboxCombo] = useState([]);
  const [checkboxCombo, setCheckboxCombo] = useState([]);
  const [optionsCombo, setOptionsCombo] = useState([]);
  const [optionsPeriodPayment, setOptionsPeriodPayment] = useState([]);
  const [model, setModel] = useState(null);
  const [storage, setStorage] = useState(null);
  const [color, setColor] = useState(null);
  const [combo, setCombo] = useState(null);
  const [round, setRound] = useState(null);
  const [periodNumber, setPeriodNumber] = useState(null);
  const [downPaymentCalc, setDownPaymentCalc] = useState(null);
  const [roundAmount, setRoundAmount] = useState(null);
  const [downPayment, setDownPayment] = useState(null);
  const [deposit, setDeposit] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [downAmount, setDownAmount] = useState(null)

  const type = 'DOWNPAYMENT';
  const {
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
    setError,
  } = useForm();

  const { Text, Title, Paragraph } = Typography;

  const clearAll = () => {
    setModel('');
    setStorage('');
    setColor('');
    setRound('');
    setPeriodNumber('');
    setDownPaymentCalc('');
    setRoundAmount('');
    setDownPayment('');
    setDeposit('');
    setDownpaymentNo('');
    setOptionsProducts([]);
    setOptionsStorage([]);
    setOptionsColor([]);
    setOptionsPeriodPayment([]);
    setDisabled(false);
    setIsClose(true);
    setCheckboxCombo([])
    setChooseCheckboxCombo([])
    setCombo([])
  };

  useEffect(async () => {
    if (visible) {
      if (JSON.stringify(data && data.record[0]) !== '{}' && data.record[0]) {
        const { downpayment_no, model, storage, color, period, id_card, down_payment_type, combo } = data.record[0];
        const dataValue = Object.keys(data.record);
        dataValue.forEach((val) => setValue(val, data.record[val]));
        setDownpaymentNo(downpayment_no);
        setModel(model?.name);
        setStorage(storage?.name);
        setColor(color?.name);
        setCombo(combo)
        setIsClose(false);
  
        if (down_payment_type === 'รายเดือน') {
          setRound('MONTH');
          setValue('round_payment', 'MONTH');
        } else if (down_payment_type === 'รายสัปดาห์') {
          setRound('WEEK');
          setValue('round_payment', 'WEEK');
        } else {
          setRound(null);
        }
  
        if (combo && combo.length > 0) {
          const checked = combo.map((el) => {
            return el?.type
          })
          setValue('additionalProduct', checked)
          setChooseCheckboxCombo(checked)
  
          combo.map((el) => {
            setValue(el?.type,  el?.name)
          })
        }
  
        setValue('model', model?.name);
        setValue('storage', storage?.name);
        setValue('color', color?.name);
  
        let periodValue = period.split(' ')[0] || null;
        setPeriodNumber(periodValue);
        setValue('period_payment', periodValue);
  
        await onFetchDownPayment({ id_card: id_card });
        await onFetchOptionsProducts(type);
        await onFetchOptionsStorage(type, model?.code);
        await onFetchOptionsColor(type, model?.code, storage?.code);
        await onFetchOptionsCombo(model?.name, storage?.name)
      }
    }
  }, [visible, data.record[0]]);

  useEffect(async () => {
    if (downPayment) {
      const { model, storage, color } = downPayment.product;
      const { round_payment, round_amount, time_payment, paid_amount, amount } = downPayment.deposit[0];
      setDeposit(downPayment.deposit[0]);
      setRound(round_payment);
      setPeriodNumber(time_payment);

      await onFetchOptionsPeriodPayment({
        type: type,
        product: {
          model: model,
          storage: storage,
          color: color,
        },
        // round_payment: round_payment,
        combo: downPayment?.combo || []
      });

      setDownAmount(amount)
      setDownPaymentCalc(amount - paid_amount);
      setRoundAmount(round_amount);
      setDisabled(paid_amount < Number(round_amount) * (time_payment - 1) ? true : false);
    }
  }, [downPayment]);

  useEffect(() => {
    if (!(model && storage && color && round && periodNumber) && isClose) {
      onClose();
    }
  }, [model, storage, color, round, periodNumber, isClose]);
  const onCloseMain = () => {
    const dataValue = ['model', 'storage', 'color', 'round_payment', 'period_payment', 'disabledFiled'];
    dataValue.forEach((val) => setValue(val, ''));
    setValue('additionalProduct', [])
    clearAll();
  };

  const onSubmit = async (params) => {
    setLoading(true);
    try {
      const payload = {
        product: {
          color: params.color,
          model: params.model,
          storage: params.storage,
        },
        // price_down: deposit.amount, //เงินดาวน์ทั้งหมด
        price_down: downAmount, //เงินดาวน์ทั้งหมด =>
        round_payment: params.round_payment, //รายปีหรือเดือน
        time_payment: params.period_payment, //จำนวนออม
        // round_amount: deposit.round_amount, //จำนวนต่อรอบ
        round_amount: roundAmount.toString(), //จำนวนต่อรอบ =>
        // remain_amount: deposit.remain_amount, //คงเหลือ
        remain_amount: downPaymentCalc, //คงเหลือ => 
        downpayment_no: downpaymentNo, // เลขที่ออมดาวน์
        combo: combo || []
      };

      const res = await POST(DOWN_PAYMENT_UPDATE_PRODUCT, payload);
      const { message } = res;
      Modal.success({
        title: message,
        afterClose: () => {
          onCloseMain();
        },
      });
    } catch (error) {
      Modal.error({ title: error?.message });
    } finally {
      setLoading(false);
    }
  };

  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันการแก้ไขสินค้า',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onFetchDownPayment = async (body) => {
      const { data } = await POST(GET_DOWN_PAYMENT, body);
      setDownPayment(data[0]);
  };

  const onFetchDataCalcPayment = async (body) => {
    const { data } = await POST(CALCULATE_AMOUNT_DOWN_PAYMENT, body);

    setDownAmount(data.downPayment)
    setDownPaymentCalc(data.downPayment - deposit.paid_amount);
    setRoundAmount(data.round_amount);
  };

  const onFetchOptionsPeriodPayment = async (body) => {
    const res = await POST(CALCULATE_MONTH_DOWN_PAYMENT, body);
    const period = res.MaxMonth.map((el) => {
      return {
        value: el,
        label: el.toString(),
      };
    });
    setOptionsPeriodPayment(period);
  };

  const onFetchOptionsProducts = async (type) => {
    const res = await GET(GET_PRODUCT_MODEL_DOWNPAYMENT(type));
    const model = res.model.map((el) => {
      return {
        value: el.label,
        label: el.label,
        image: el.image,
        product_master_code: el.product_master_code
      };
    });
    setOptionsProducts(model);
  };

  const onFetchOptionsStorage = async (type, model) => {
    let res = await GET(GET_PRODUCT_STORAGE_DOWNPAYMENT(type, model));
    const storage = res.storage.map((el) => {
      return {
        value: el.label,
        label: el.label,
        storage_code: el.storage_code
      };
    });
    setOptionsStorage(storage);
  };

  const onFetchOptionsColor = async (type, model, storage) => {
    let res = await GET(GET_PRODUCT_COLOR_DOWNPAYMENT(type, model, storage));
    const color = res.color.map((el) => {
      return {
        value: el.label,
        label: el.label,
        image: el.image,
        color_code: el?.color_code
      };
    });
    setOptionsColor(color);
  };

  const onFetchOptionsCombo = async (model, storage) => {

    const res = await POST(GET_PRODUCT_COMBO_DOWNPAYMENT, {
      name: model,
      storage: storage,
    });
    const checkbox = res?.result?.checkbox?.map((el) => {
      return {
        value: el?.values,
        label: el?.values
      }
    })
    setCheckboxCombo(checkbox || [])
    setOptionsCombo(res?.result?.combo || [])
  };

  const handleProductModelCode = (value) => {
    const filtered = optionsProducts.filter((el) => el.value === value)
    return filtered[0]?.product_master_code || ''
  }

  const handleProductStorageCode = (value) => {
    const filtered = optionsStorage.filter((el) => el.value === value)
    return filtered[0]?.storage_code || ''
  }

  const onChange = async (value, ref) => {
    switch (ref) {
      case 'model':
        const modelValue = handleProductModelCode(value);
        onFetchOptionsStorage(type, modelValue);
        setValue(ref, value);
        setOptionsColor([]);
        setValue('storage', null);
        setValue('color', null);
        setValue('round_payment', null);
        setValue('period_payment', null);
        setValue('additionalProduct', []);

        if (combo) {
          combo.map((el) => {
            return setValue(el?.type, null);
          });
        }

        setModel(value);
        setStorage(getValues('storage'));
        setColor(getValues('color'));
        setRound(getValues('round_payment'));
        setPeriodNumber(getValues('period_payment'));
        setDownAmount('-');
        setRoundAmount('-');
        setCombo([]);
        setCheckboxCombo([]);
        setChooseCheckboxCombo([]);
        setOptionsCombo([]);
        break;
      case 'storage':
        const modelValues = handleProductModelCode(getValues('model'));
        const storageValue = handleProductStorageCode(value);
        onFetchOptionsColor(type, modelValues, storageValue);
        setValue(ref, value);
        setValue('color', '');
        setValue('round_payment', '');
        setValue('period_payment', '');
        setValue('additionalProduct', []);

        if (combo) {
          combo.map((el) => {
            return setValue(el?.type, null);
          });
        }

        setModel(getValues('model'));
        setStorage(getValues('storage'));
        setColor(getValues('color'));
        setRound(getValues('round_payment'));
        setRoundAmount('-');
        setDownAmount('-');
        setPeriodNumber(getValues('period_payment'));
        setCombo([]);
        setCheckboxCombo([]);
        setChooseCheckboxCombo([]);
        setOptionsCombo([]);
        break;
      case 'color':
        setValue(ref, value);
        setValue('round_payment', '');
        setValue('period_payment', '');
        setValue('additionalProduct', []);

        if (combo) {
          combo.map((el) => {
            return setValue(el?.type, null);
          });
        }

        setModel(getValues('model'));
        setStorage(getValues('storage'));
        // setColor(getValues('color'));
        setColor(value);
        setRound(getValues('round_payment'));
        setRoundAmount('-');
        setDownAmount('-');
        setPeriodNumber(getValues('period_payment'));
        setCombo([]);
        setOptionsCombo([]);
        // setCheckboxCombo([]);
        setChooseCheckboxCombo([]);
        await onFetchOptionsCombo(model, storage);
        break;
      case 'round_payment':
        onFetchOptionsPeriodPayment({
          type: type,
          product: {
            color: getValues('color'),
            model: getValues('model'),
            storage: getValues('storage'),
          },
          // round_payment: value,
          combo: combo || []
        });

        setValue(ref, value);
        setValue('period_payment', '');

        setModel(getValues('model'));
        setStorage(getValues('storage'));
        setColor(getValues('color'));
        setRound(getValues('round_payment'));
        setPeriodNumber(getValues('period_payment'));
        break;
      case 'period_payment':
        setValue(ref, value);
        onFetchDataCalcPayment({
          product: {
            color: getValues('color'),
            model: getValues('model'),
            storage: getValues('storage'),
          },
          type: type,
          round_payment: getValues('round_payment'),
          max_month: Number(value),
          combo: combo
        });

        setModel(getValues('model'));
        setStorage(getValues('storage'));
        setColor(getValues('color'));
        setRound(getValues('round_payment'));
        setPeriodNumber(getValues('period_payment'));
        break;
      case 'additionalProduct':
        setValue('additionalProduct', value)
        setValue('round_payment', null)
        setValue('period_payment', null)
        setChooseCheckboxCombo(value)
        setCombo([])
        setRoundAmount('-');
        setDownAmount('-');

        if (combo&& combo?.length > 0) {
          combo.map((el) => {
            return setValue(el?.type, null);
          });
        }
        break;
      default:
        break;
    }
  };

  const handleChooseProductCombo = (e, type) => {
    let current = _.cloneDeep(combo)
    let choose = []
    let findType = current.filter((item) => item.type === type)
    const findCombo = optionsCombo?.filter((el) => el?.name === e)

    if (findType?.length === 0) {
      choose = findCombo
    } else {
      let temp = current.filter(item => item.type !== type);
      choose = [...temp, findCombo]
    }

    setCombo(choose)
  }

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('th-TH').format(amount);
  };

  return (
    <>
      <Modal
        title={title || 'modal'}
        open={visible}
        width={500}
        onCancel={onCloseMain}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        style={{border: 'unset'}}>
        <Spin spinning={loading}>
          <form onSubmit={handleSubmit(onConfirm)}>
            <RenderForm
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              formList={FORM_PRODUCT_DOWN_PAYMENT({
                product: {
                  model: model,
                  storage: storage,
                  color: color,
                  round_payment: round,
                  period_payment: periodNumber,
                  disabledFiled: disabled,
                  combo: combo,
                },
                data: {chooseCheckboxCombo},
                options: {optionsColor, optionsStorage, optionsProducts, optionsPeriodPayment, optionsRoundPayment, optionsCombo, checkboxCombo},
                fn: {onChange, handleChooseProductCombo},
              })}
              setValue={setValue}
              getValues={getValues}
            />
            <Row style={{alignItems: 'left', display: 'flex', justifyContent: 'left', marginBottom: 10, paddingInline: 20}}>
              <Spin spinning={loading} tip={'กำลังดึงข้อมูล'}></Spin>
            </Row>
            <Row style={{alignItems: 'left', display: 'flex', justifyContent: 'left', marginBottom: 10, paddingInline: 20}}>
              <Col xs={{span: 24}} md={{span: 24}} xl={{span: 24}} lg={{span: 24}} style={{display: 'flex', justifyContent: 'left'}}>
                {/* <Text strong>เงินดาวน์</Text>:&thinsp;{deposit && deposit?.amount === '' ? 0 : formatAmount(deposit?.amount)} บาท */}
                <Text strong>เงินดาวน์</Text>:&thinsp;{downAmount === null ? 0 : downAmount === '-' ? '-' : formatAmount(downAmount)} บาท
              </Col>
            </Row>
            <Row style={{alignItems: 'left', display: 'flex', justifyContent: 'left', marginBottom: 10, paddingInline: 20}}>
              <Col xs={{span: 24}} md={{span: 24}} xl={{span: 24}} lg={{span: 24}} style={{display: 'flex', justifyContent: 'left'}}>
                <Text strong>ยอดออมต่องวด</Text>:&thinsp;
                {roundAmount && roundAmount === null ? 0 : roundAmount === '-' ? '-' : formatAmount(roundAmount)} บาท
              </Col>
            </Row>
            <Row style={{alignItems: 'left', display: 'flex', justifyContent: 'left', marginBottom: 10, paddingInline: 20}}>
              <Col xs={{span: 24}} md={{span: 24}} xl={{span: 24}} lg={{span: 24}} style={{display: 'flex', justifyContent: 'left'}}>
                <Text strong>ชำระแล้ว</Text>:&thinsp;{deposit && deposit?.paid_amount === '' ? 0 : formatAmount(deposit?.paid_amount)} บาท
              </Col>
            </Row>
            <Row style={{alignItems: 'left', display: 'flex', justifyContent: 'left', marginBottom: 10, paddingInline: 20}}>
              <Col xs={{span: 24}} md={{span: 24}} xl={{span: 24}} lg={{span: 24}} style={{display: 'flex', justifyContent: 'left'}}>
                <Text strong>ยอดออมคงเหลือที่ต้องออม</Text>&thinsp;:{deposit && deposit?.paid_amount === '' ? 0 : formatAmount(downPaymentCalc)} บาท
              </Col>
            </Row>
            <ContainerButton right>
              <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
              <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
            </ContainerButton>
          </form>
        </Spin>
      </Modal>
    </>
  );
};
