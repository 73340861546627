export const createParams = (value) => {
  let params = [];
  let result = '';

  Object.keys(value).forEach((key) => {
    if (value[key]) params.push(key + '=' + value[key]);
  });

  if (params.length !== 0) result += params.join('&');

  return result;
};