import React, { useState, useEffect } from 'react';
import { Modal, Card, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Wrapper from '../../../views/wrapper/Wrapper';
import RenderForm from '../../../constants/global/RenderForm';
import { CreateEmailForm } from '../../../constants/global/actionform';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { auth } from '../../../utils/firebase';
import { useAuthState } from '../../../utils/hook';

import { GET, POST, GET_ACCOUNT_RANDOM, GET_OPTION_EMAIL, CREATE_ACCOUNT, SORT_MAIL_TEL } from '../../../services/index';


const CreateEmail = () => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const { user } = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [confirmUseTel, setConfirmUseTel] = useState(false);
  const [valueDefault, setValueDefault] = useState({});
  const [optionEmail, setOptionEmail] = useState([]);
  const [loadingRandom, setLoadingRandom] = useState(true);

  useEffect(async () => {
    await getInitRandom();
    getOptionEmail();
  }, []);

  const getInitRandom = async () => {
    try {
      let res = await GET(GET_ACCOUNT_RANDOM);
      const { success, result } = res;
      if (success) {
        const dataValue = Object.keys(result);
        dataValue.forEach((val) => { val === "mail" ? setValue(val, `ufriend.${result[val]}`) : setValue(val, result[val]) });
        setValueDefault(result);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoadingRandom(false);
    }
  };

  const getInitRandomTelMail = async () => {
    let iCloudTel = getValues('telIcloud')
    try {
      let res = await POST(SORT_MAIL_TEL, {telIcloud: iCloudTel});
      const { success, result } = res;
      if (success) {
        setValue('telMail', result['tel'])
        let newDefaultValue = { ...valueDefault, telMail: result['tel'] }
        setValueDefault(newDefaultValue);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoadingRandom(false);
    }
  };

  const getOptionEmail = async () => {
    try {
      let res = await GET(GET_OPTION_EMAIL);
      const { success, result } = res;
      if (success) {
        setOptionEmail(result);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onChangeCustom = async (value, ref) => {
    if (ref === 'confirmUseTel') {
      if (value) {
        await getInitRandomTelMail()
      } else {
        setValue('telMail', '')
      }
      setConfirmUseTel(value);
      setValue('confirmUseTel', value);
    } else if (ref === 'domainName') {
      setValue('domainName', value);
    }
  };

  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยันบันทึกข้อมูล',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการบันทึกข้อมูล ใช่หรือไม่',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onSubmit = async (params) => {
    setLoading(true);

    const { birthDate, domainName, firstName, passIcloud, telIcloud, lastName, mail, passMail, telMail, pin, confirmUseTel } = params;
    const obj = {
      userLogin: user.email,
      birthDate,
      firstName,
      passMail,
      telIcloud,
      lastName,
      mail: `${mail}${domainName}`,
      passIcloud,
      telMail: confirmUseTel ? telMail : '',
      pin,
      status : 'WAIT_REGISTER'
    };

    try {
      let res = await POST(CREATE_ACCOUNT, obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            setLoadingRandom(true);
            getInitRandom();
            setConfirmUseTel(false);
            setValue('confirmUseTel', false);
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onRandomTelEmail = async (ref) => {
    if (ref === 'telMail') {
      try {
        // let res = await GET(RANDOM_TEL);
        let res = await POST(SORT_MAIL_TEL);
        const { success, result } = res;
        if (success) {
          setValue('telMail', result.tel);
          valueDefault.telMail = result.tel;
        }
      } catch (err) {
        const { message, error } = err;
        Modal.error({
          title: message,
          content: error || '',
        });
      } finally {
        setLoading(false)
      }
    }
    setValueDefault(valueDefault);
  };

  return (
    <Wrapper>
      <h1>สมัคร Email</h1>
      <hr />
      {loadingRandom ? (
        <Card style={{ textAlign: 'center' }}>
          <Spin />
          <h2>{'กำลัง Random ข้อมูล...'}</h2>
        </Card>
      ) : (
        <form style={{ width: '80%', margin: 'auto' }} onSubmit={handleSubmit(onConfirm)}>
          {JSON.stringify(valueDefault) != '{}' && valueDefault && (
            <RenderForm
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              formList={CreateEmailForm({
                onChange: onChangeCustom,
                onRandomTelEmail,
                defaultValue: { ...valueDefault, confirmUseTel },
                options: { email: optionEmail },
              })}
              justify={'left'}
              renderButton={
                <>
                  <ContainerButton right>
                    <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
                  </ContainerButton>
                </>
              }
            />
          )}
        </form>
      )}
    </Wrapper>
  );
};

export default CreateEmail;
