/* eslint-disable default-case */
import React, { useEffect, useRef, useState } from 'react';
import { PageLayout } from '../../../components/pageLayout/PageLayout';
import { useForm } from 'react-hook-form';
import Wrapper from '../../../views/wrapper/Wrapper';
import { Modal, Card, Space, Button, Table, Spin, Row, Col, Typography, Checkbox, Image } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import {
  CONFIRM_CONTRACT_BY_CUSTOMER_SECOND_HAND,
  POST,
  POST_SECOND_HAND_DETAIL,
} from '../../../services';
import { convertStrToFormat } from '../../../functions/fn';
import { ButtonTheme } from '../../../components/buttons';
import moment from 'moment';
import { Icon } from '../../../resources/icon';
import { TextLarge, TextSmall } from '../../../components/forms/text';
import { SignSecondHandDetailForm, SignSecondHandSearchForm } from '../form';
import { color } from '../../../resources/color';
import { ModalSignatureSecondHand } from '../modal/ModalSignature';

const { Title, Text } = Typography;

const AphSignRecordSecondHand = () => {

  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [documentNumber, setDocumentNumber] = useState('')
  const [signatureImage, setSignatureImage] = useState('');
  const [signatureImageBase64, setSignatureImageBase64] = useState('');
  const [disabledSignButton, setDisabledSignButton] = useState(true);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
  const [htmlSource, setHtmlSource] = useState('');
  const form = SignSecondHandDetailForm();

  const [dataSource, setDataSource] = useState({});
  const [disabledCheckBox, setDisabledCheckBox] = useState(true);
  const [checkBox, setCheckBox] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const admin_branch = localStorage.getItem('admin_branch');

  useEffect(async () => {
  }, [])

  const onRefresh = () => {
    setDocumentNumber('')
    setSignatureImage('')
    setSignatureImageBase64('')
    setDisabledCheckBox(true)
    setDisabledSignButton(true)
    setDisabledSubmitButton(true)
    setHtmlSource('')
    setDataSource({})
  };

  const onSubmit = async () => {

    let obj = {
      documentNumber: documentNumber,
      imageSignature: signatureImage || '',
    };
    setLoadingSubmit(true)
    try {
      const res = await POST(CONFIRM_CONTRACT_BY_CUSTOMER_SECOND_HAND, obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message || '',
          afterClose: () => {
            onRefresh();
            // window.open('/buy-second-hand-report', '_self');
          },
        });
      }
    } catch (err) {
      const { error, message } = err;
      Modal.error({
        title: error || message || '',
      });
    } finally {
      setLoadingSubmit(false)
    }
  };

  const getDataDetailSecondHand = async (value) => {
    let docNo = value.docNo;
    setDocumentNumber(docNo)
    setLoading(true);
    try {
      const res = await POST(POST_SECOND_HAND_DETAIL(docNo));
      const { success, result, html } = res;
      if (success) {

        /* เช็คว่าเป็นของสาขาของ user หรื่อไม่ */
        if(result.branchCode !== admin_branch){
          return Modal.warning({
            title: `เลขสัญญานี้ไม่ใช่ของสาขา ${result.branch}`
          })
        }

        if (result.status === 'WAIT_SIGNATURE') {
          const newResult = {
            key: result?.ObjectId,
            document_no: docNo,
            name: result?.name,
            product_name: result?.product,
            serial_no: result?.serialNo,
            approve_paid_amount: result?.approve_paid_amount ? `${convertStrToFormat(result?.approve_paid_amount, 'money')} บาท` : '0 บาท',
          };
          setDataSource(newResult);
          setHtmlSource(html);
        } else {
          setDocumentNumber('')
          setSignatureImage('')
          setSignatureImageBase64('')
          setDisabledCheckBox(true)
          setDisabledSignButton(true)
          setDisabledSubmitButton(true)
          setHtmlSource('')
          setDataSource({})
          return Modal.warning({
            title: `สัญญาฉบับนี้อยู่ในสถานะ '${result.stausThaiLetter}'`
          })
        }

      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = (e) => {
    let scrollTop = e.target.scrollTop
    if (!Number.isInteger(e.target.scrollTop)) {
      scrollTop = Math.floor(e.target.scrollTop)
    }
    const bottom = (e.target.scrollHeight - scrollTop) === e.target.clientHeight;
    if (bottom) {
      setDisabledCheckBox(false);
    } else {
      if (checkBox === false) {
        setDisabledCheckBox(true);
      } else {
        setDisabledCheckBox(false)
      }

    }
  };

  const onEnabledSubmitButton = (e) => {
    setCheckBox(e.target.checked)
    setDisabledSignButton(!e.target.checked)

    if (e.target.checked === false) {
      setSignatureImage('')
      setSignatureImageBase64('')
    }
  }

  const openModal = (ref) => {
    if (ref === 'SIGNATURE') {
      setVisibleModal(true)
    }
  }

  const onGetImageUrl = (value) => {
    if (value) {
      setSignatureImage(value.imageUrl)
      setSignatureImageBase64(value.imageBase64)
      setDisabledSubmitButton(false)
    }
  }

  const openConfirm = () => {
    Modal.confirm({
      title: "ยืนยัน",
      icon: <Icon.warningAlert />,
      content: "ยืนยันการขาย",
      onOk() {
        onSubmit();
      },
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
    });
  }

  const onClearCustom = (setValue, formSearch) => {
    formSearch.map((val) => {
      setValue(val.name, null);
    });
    setDocumentNumber('')
    setSignatureImage('')
    setSignatureImageBase64('')
    setDisabledCheckBox(true)
    setDisabledSignButton(true)
    setDisabledSubmitButton(true)
    setHtmlSource('')
    setDataSource({})
  };

  return (
    <Wrapper>
      {
        loadingSubmit ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
            <Spin tip="Loading..." spinning={loadingSubmit}></Spin>
          </div>
        ) : (
          <Card>
            <PageLayout
              searchLayout={{
                title: 'เซ็นสัญญาซื้อขายรายการรับซื้อสินค้ามือสอง',
                icon: <FileTextOutlined />,
                spanSearch: 24,
                formSearch: SignSecondHandSearchForm({ options: {} }),
                onSearch: (data) => getDataDetailSecondHand(data),
                onClearCustom
              }}
              content={
                <Card style={{ borderRadius: '15px', marginTop: '2%' }}>
                  {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                      <Spin tip="Loading..." spinning={loading}></Spin>
                    </div>
                  ) : (
                    <>
                      {JSON.stringify(dataSource) !== '{}' && (
                        <div>
                          {form.map((field) => (
                            <div key={field.key} style={{ marginBottom: '10px', width: 'auto' }}>
                              <Row>
                                <Col span={6} style={{ fontWeight: 'bold', marginRight: '10px' }}>
                                  {field.label}
                                </Col>
                                {<Col span={12}>{dataSource[field.name]}</Col>}
                              </Row>
                            </div>
                          ))}
                        </div>
                      )}

                      {htmlSource !== '' && (
                        <>
                          <div style={{ marginBlock: '50px 30px' }}>
                            <Text strong underline>
                              รายละเอียดสัญญาซื้อขาย
                            </Text>
                          </div>
                          <Row style={{ justifyContent: 'center' }}>
                            <div
                              onScroll={handleScroll}
                              style={{ width: '100%', maxHeight: '300px', overflowY: 'scroll' }}
                              dangerouslySetInnerHTML={{ __html: htmlSource }}></div>
                            <div style={{ marginTop: '50px' }}>
                              <Checkbox onChange={(e) => onEnabledSubmitButton(e)} disabled={disabledCheckBox}>
                                ข้าพเจ้ายอมรับเงื่อนไขที่ทางบริษัทกำหนด
                              </Checkbox>
                              {/* <div style={{textAlign: 'center', marginTop: '20px'}}> */}
                              <ButtonTheme
                                useFor="CUSTOM"
                                disabled={disabledSignButton}
                                style={{ color: color.white }}
                                // bgcolor={color.theme}
                                title="ลงลายมือชื่อ"
                                onClick={() => openModal('SIGNATURE')}></ButtonTheme>
                              {/* </div> */}
                            </div>
                          </Row>
                        </>
                      )}
                      {signatureImage !== '' && (
                        <div style={{ textAlign: 'center' }}>
                          <Row style={{ justifyContent: 'center' }}>
                            <Image width={'500px'} src={signatureImageBase64} />
                          </Row>
                          <ButtonTheme
                            useFor="SUBMIT"
                            disabled={disabledSubmitButton}
                            title="ยืนยันการขาย"
                            onClick={() => openConfirm()}></ButtonTheme>
                        </div>
                      )}
                    </>
                  )}
                </Card>
              }
            />
          </Card>
        )
      }
      <ModalSignatureSecondHand
        visible={visibleModal}
        title={'ลงลายมือชื่อ'}
        onClose={() => setVisibleModal(false)}
        data={{
          fn: { onGetImageUrl },
          passValue: {
            signatureImage: '',
            edit: true,
          },
        }}
      />
    </Wrapper>
  );
};

export default AphSignRecordSecondHand;
