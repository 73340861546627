// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect} from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Spin} from 'antd';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {SettingOutlined} from '@ant-design/icons';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {ManageIcloudAccountDashboardSearchForm} from '../../../constants/global/SearchForm';
import {ManageIcloudAccountColumn} from '../../../constants/global/columnTableForm';
import {GET, POST, GET_ACCOUNT_WITH_CONTRACT} from '../../../services/index';
import {auth} from '../../../utils/firebase';
import {useCurrentState} from '../../../utils/hook';
import {ViewIcloudModal} from './modal/ViewIcloudModal';
// import {useCountRandom} from '../../../hook/useCountRandom';

const ManageIcloud = () => {
  const [loading, setLoading] = useState(false);

  const [visibleView, setVisibleView] = useState(false);
  const [title, setTitle] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [valueDefault, setValueDefault] = useState({});
  const {user} = useCurrentState(auth);

  const [checkCallApi, setCheckCallApi] = useState(false);

  useEffect(() => {
    if (user && user.branch && !checkCallApi) {
      getDataAccountWithContract({});
      setCheckCallApi(true);
    }
  }, [user]);

  const getDataAccountWithContract = async (data) => {
    data.tel = data.tel || '';
    data.mail = data.mail || '';

    const obj = {...data, branch: user.branch};
    try {
      setLoading(true);
      let res = await POST(GET_ACCOUNT_WITH_CONTRACT, obj);
      const {success, result} = res;
      if (success) {
        const resultData = result.map((val, i) => {
          return {
            rowNo: i + 1,
            fullName: val.contract[0].user[0].general.fullname,
            contractNo: val.contract[0].contract_no,
            itemName: val.contract[0].product.name,
            contractDate: val.contract[0].contractDate,
            telMail: val.telMail,
            telIcloud: val.telIcloud,
            mail: val.mail,
            passIcloud: val.passIcloud,
            pin: val.pin,
            statusContract: val.contract[0]?.status,
            status: val?.status ? val?.status : '',
            statusName: val?.statusName ? val?.statusName : '',
            telIclouds: val?.telIclouds ? val?.telIclouds : '',
            icloudFirstName: val?.icloudFirstName ? val?.icloudFirstName : '',
            icloudLastName: val?.icloudLastName ? val?.icloudLastName : '',
            birthDate: val?.birthDate ? val?.birthDate : '',
            id: val?._id ? val?._id : '',
          };
        });
        setDataSource(resultData);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const openModal = (ref, record) => {
    if (ref === 'view') {
      setTitle('ข้อมูลไอคลาวน์');
      setValueDefault(record);
      setVisibleView(true);
    }
  };

  const closeModal = (ref, record) => {
    if (ref === 'view') {
      setVisibleView(false);
    }
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'จัดการไอคลาวน์',
              icon: <SettingOutlined />,
              spanSearch: 24,
              formSearch: ManageIcloudAccountDashboardSearchForm(),
              onSearch: (data) => getDataAccountWithContract(data),
            }}
            tableLayout={{
              columns: ManageIcloudAccountColumn({openModal}),
              dataSource: dataSource,
              extraContent: <ContainerButton right></ContainerButton>,
            }}
          />
        </Spin>
      </Card>

      <ViewIcloudModal visible={visibleView} title={title} onClose={closeModal} data={{record: valueDefault, reApi: getDataAccountWithContract}} />
    </Wrapper>
  );
};

export default ManageIcloud;
