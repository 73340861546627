/* eslint-disable no-unused-vars */
//{
//   import { FormControl, Grid, MenuItem, Select } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import { ArrowBack, CallMerge } from '@material-ui/icons';
// import { Button, Input, InputNumber, Modal as ModalAnt, Skeleton, Tooltip, Typography, Divider, DatePicker as DatePickerAntd } from 'antd';
// import moment from 'moment/moment';
// import { useEffect, useRef, useState } from 'react';
// import { Link, useHistory, useParams } from 'react-router-dom';
// import ContractTable from '../../components/contract-table';
// import Loading from '../../components/loading/Loading';
// import Modal from '../../components/modal';
// import TrackLogTable from '../../components/table/TrackLog';
// import { splitTextMultipleToken } from '../../functions/fn';
// import { useContract } from '../../hook/useContract';
// import { getContractSummary, getPeriods } from '../../redux/api/contract';
// import { createTrackLog, getTrackInfo, getTrackLog } from '../../redux/api/track';
// import { Icon } from '../../resources/icon';
// import { ButtonTheme } from '../../components/buttons';
// import {
//   BarsOutlined,
//   ExclamationCircleOutlined,
//   MinusOutlined,
//   MobileOutlined,
//   PlusOutlined,
//   ReloadOutlined,
//   SoundFilled,
//   WarningOutlined,
// } from '@ant-design/icons';
// import OtherPaymentTable from './views/other-payment-table';

// import {
//   ACTIVATED_LOST_MODE_MIRADORE,
//   ADD_CONFIG_PROFILE_MIRADORE,
//   GET,
//   GET_ALL_CONFIG_ID_MIRADORE,
//   GET_DEVICE_INFO_MIRADORE,
//   POST,
//   PUT,
// } from '../../services';
// import { deActivatedLostModeMiradore, deleteConfigProfileMiradore } from '../../utils/auto-admin/api';
// import { auth } from '../../utils/firebase';
// import { useAuthState } from '../../utils/hook';
// import Wrapper from '../../views/wrapper/Wrapper';
// import { Background, Div, Td, TextLabel, TextValue } from './styled-component';
// import ActionLogTable from '../../components/table/ActionLog';
// import PlaySound from '../../components/modal/TrackPayment/PlaySoundModal';
// import ActionLog from '../../components/modal/TrackPayment/LogActionModal';
// import { icloud } from '../../redux/api/icloud';
// import { color } from '../../resources/color';


// const useStyles = makeStyles({
//   select: {
//     '& > div:focus': {
//       background: 'none',
//     },
//   },
//   input_label: {
//     fontSize: '8px',
//   },
// });

// const { Text, Title, Paragraph } = Typography;

// const contentMDM = (
//   <Paragraph style={{ color: 'white' }}>
//     สามารถใช้แอปพลิเคชั่น ได้แก่ ธนาคาร, รับสาย-โทรออก, แอปพลิเคชั่น Ufriend, LINE, Facebook และ Messenger
//   </Paragraph>
// );
// const contentMDMLostModeEnabled = <Paragraph style={{ color: 'white' }}>สามารถเปิดใช้งาน Lost Mode บนโทรศัพท์ของลูกค้า</Paragraph>;
// const contentMDMLostModeDisable = <Paragraph style={{ color: 'white' }}>สามารถปิดใช้งาน Lost Mode บนโทรศัพท์ของลูกค้า</Paragraph>;
// const contentLostStatusProcessMDM = <Paragraph style={{ color: 'white' }}>กรุณารอ ขณะนี้ระบบกำลังดำเนินการ</Paragraph>;
// const contentLockStatusProcessMDM = (<Paragraph style={{ color: 'white' }}> กรุณารอ ขณะนี้ระบบกำลังดำเนินการ</Paragraph>
// );
// const getStageRange = (stage) => {
//   switch (stage) {
//     case 5:
//       return '60+';
//     case 4:
//       return '46-60';
//     case 3:
//       return '31-45';
//     case 2:
//       return '15-30';
//     case 1:
//       return '1-14';
//     default:
//       return '-';
//   }
// };

// const getTypeLock = (typeLock) => {
//   switch (typeLock) {
//     case 'MDM':
//       return 'MDM';
//     case 'ICLOUD':
//       return 'iCloud';
//     case 'MDMICLOUD':
//       return 'MDM+iCloud';
//     default:
//       return '-';
//   }
// };

// const TrackPaymentInfo = () => {
//   const { id } = useParams();
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [channel, setChannel] = useState('DEFAULT');
//   const [remark, setRemark] = useState('');
//   const classes = useStyles();
//   const { user } = useAuthState(auth);
//   const [loading, setLoading] = useState(true);
//   const [trackInfo, setTractInfo] = useState(null);
//   const [error, setError] = useState(null);
//   const [selectedDateData, setSelectedDateData] = useState(new Date());
//   const [showLog, setShowLog] = useState(false);
//   const [logs, setLogs] = useState([]);
//   const [statusContact, setStatusContact] = useState('DEFAULT');
//   const [loadingCreate, setLoadingCreate] = useState(false);
//   const [errorCreate, setErrorCreate] = useState(null);
//   const [selectedContract, setSelectedContract] = useState(null);
//   const [SN, setSN] = useState(null);
//   const [contractNo, setContractNo] = useState(null);
//   const configProfile = useRef({ id: null, name: null });
//   const filterConfigDevice = useRef([]);
//   const [MDMdeviceInfo, setMDMdeviceInfo] = useState({});
//   const [MDMdeviceInfoSkeleton, setMDMdeviceInfoSkeleton] = useState(false);
//   //const [lostModeStatus, setLostModeStatus] = useState(false);
//   const [lostModeStatus, setLostModeStatus] = useState('process');
//   const [lockStatus, setLockStatus] = useState('process');
//   const [lostModeDate, setLostModeDate] = useState('');
//   const [lostModeLoading, setLostModeLoading] = useState(false);
//   const [lockAppLoading, setLockAppLoading] = useState(false);
//   const [isLoad, setIsLoad] = useState(true);
//   const messageLostMode = useRef('กรุณาติดต่อกลับ Ufriend');
//   const telLostMode = useRef('0646169699');
//   const [reloading, setReloading] = useState(false);
//   const [playSoundVisible, setPlaySoundVisible] = useState(false);
//   const [actionLogVisible, setActionLogVisible] = useState(false);
//   const history = useHistory();
//   const { dispatchExportContractTerminated, contractExportTerminated } = useContract();

//   useEffect(async () => {
//     if (selectedContract) {

//       if (!!selectedContract.contract.type_lock && selectedContract.contract.type_lock.includes('MDM')) {
//         // let getSNformContract = (selectedContract.products.map((p) => p.serial_number))

//         let productSerial = selectedContract.products.map((el) => {
//           if (!el.info.name.includes('Pencil')) {
//             return el.serial_number;
//           }
//         });

//         let getSNformContract = productSerial.filter((el) => el !== undefined);
//         let [contract_no] = await Promise.all([trackInfo.contract_no.map((contract, i) => contract)]);

//         if (contract_no[0] !== null) {
//           setContractNo(contract_no[0]);
//         } else {
//           setContractNo('');
//         }

//         (async (getSNformContract) => {
//           // await getAllConfigProfile();
//           if (typeof getSNformContract == 'object' && getSNformContract.length > 0) {
//             if (getSNformContract[0].includes('-')) {
//               let splitSN = getSNformContract[0].split('-');
//               setSN(splitSN[0]);
//               await getDeviceInfoFromMiradore(splitSN[0]);
//             } else {
//               setSN(getSNformContract[0]);
//               await getDeviceInfoFromMiradore(getSNformContract[0]);
//             }
//           } else {
//             setMDMdeviceInfoSkeleton(true);
//           }
//         })(getSNformContract);
//       }
//     } else {
//       // setSelectedContract({ periods: [] });
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [selectedContract]);

//   useEffect(() => {
//     onGetData();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const onGetData = async () => {
//     let response = await getTrackInfo(id);
//     if (response.status === 200) {
//       let info = { ...response.data.data, contracts: null };
//       setTractInfo(info);
//       setLoading(false);
//       setReloading(false);
//       const contractResponse = await getContractSummary(response.data.data.contract_no);
//       if (contractResponse.status === 200) {
//         let info = { ...response.data.data, contracts: contractResponse.data.data };
//         setTractInfo(info);

//         const contract = info?.contracts.find((c) => c._id === info.contracts[0]._id);
//         setSelectedContract(contract);
//       } else {
//         setError(contractResponse.response.data.error);
//       }
//     } else {
//       setError(response.response.data.error);
//     }
//     setLoading(false);
//   };

//   useEffect(() => {
//     if ('configProfile' in MDMdeviceInfo) {
//       let checkConfig = MDMdeviceInfo.configProfile.filter((el) => el.configProfile_id === configProfile.current.id);
//       if (!!checkConfig && checkConfig.length !== 0) {
//         let convertDeployTime = convertTimeLocal(checkConfig[0].deploy_time);
//         const convertDateConfig = checkConfig.map((el) => {
//           return {
//             ...el,
//             deploy_time: convertDeployTime,
//           };
//         });

//         filterConfigDevice.current = convertDateConfig;
//         setMDMdeviceInfoSkeleton(true);
//       } else {
//         filterConfigDevice.current = checkConfig;
//         setMDMdeviceInfoSkeleton(true);
//       }
//     }
//   }, [MDMdeviceInfo]);

//   useEffect(async () => {
//     if (selectedContract?.periods && isLoad === true) {
//       const getTransaction = async () => {
//         return await Promise.all(
//           selectedContract.periods.map(async (el) => {
//             let res = await getPeriods(el.contract_no, el._id, el.paid_date);
//             if (res.data.length > 0) {
//               el.transaction = res.data;
//             }

//             return el;
//           }),
//         );
//       };
//       selectedContract.periods = await getTransaction();

//       setSelectedContract(selectedContract);
//       setIsLoad(false);
//     }
//   }, [selectedContract?.periods]);

//   const onChangeChannel = (e) => {
//     setChannel(e.target.value);
//   };

//   const onChangeRemark = (e) => {
//     setRemark(e.target.value);
//   };

//   const createLog = async () => {
//     if (!remark || remark === '') return false;
//     if (channel === 'DEFAULT') return false;
//     if (statusContact === 'DEFAULT') return false;
//     if (!selectedDate) return false;
//     let data = {
//       remark,
//       channel,
//       contact_status: statusContact,
//       next_action_date: selectedDate,
//       action_by: user.displayName,
//       payment_id: trackInfo?.payment_id,
//       amount: trackInfo?.amount,
//     };

//     setLoadingCreate(true);
//     const response = await createTrackLog(id, data);
//     if (response.status === 200) {
//       setErrorCreate(null);
//       reset();
//     } else {
//       setErrorCreate(response.response.data.error);
//     }
//     setLoadingCreate(false);
//     history.goBack();
//   };

//   const reset = () => {
//     setRemark('');
//     setChannel('DEFAULT');
//     setStatusContact('DEFAULT');
//     setSelectedDate(null);
//   };

//   const selectContract = (contractNumber) => {
//     const contract = trackInfo?.contracts.find((c) => c._id === contractNumber);
//     if (contract) return setSelectedContract(contract);
//   };

//   const onChangeDataDate = async (e) => {
//     setSelectedDateData(e);
//     let response = await getTrackInfo(id);
//     if (response.status === 200) {
//       const contractResponse = await getContractSummary(response.data.data.contract_no, e.toISOString());
//       if (contractResponse.status === 200) {
//         let info = { ...response.data.data, contracts: contractResponse.data.data };
//         setTractInfo(info);
//         const contract = info?.contracts.find((c) => c._id === info.contracts[0]._id);
//         setSelectedContract(contract);
//       } else {
//         setError(contractResponse.response.data.error);
//       }
//     } else {
//       setError(response.response.data.error);
//     }
//     setLoading(false);
//   };

//   const getLogs = async () => {
//     // setLoading(true);
//     let response = await getTrackLog(id);
//     if (response.status === 200) {
//       setLogs(response.data.data.logs);
//       setShowLog(true);
//     } else {
//       setError(response.response.data.error);
//     }
//     setLoading(false);
//   };

//   const convertTimeLocal = (value) => {
//     let splitDate = splitTextMultipleToken(value, ['.', ' ']);
//     let joinDate = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0] + ' ' + splitDate[3];
//     let convert = moment(joinDate).add(7, 'h').format('DD/MM/YYYY HH:mm');
//     return convert;
//   };

//   const getAllConfigProfile = async () => {
//     // setLoading(true)
//     try {
//       const res = await GET(GET_ALL_CONFIG_ID_MIRADORE);
//       const { data } = res;
//       const manageConfigProfile = data.Content.Items.ConfigProfile.map((el) => {
//         return {
//           id: el.ID._text,
//           name: el.Name._text,
//         };
//       });

//       const conditionAppBankConfigSubString = ['App', 'Bank'];
//       const appBankProfile = manageConfigProfile.filter((el) =>
//         conditionAppBankConfigSubString.every((term) => el.name.toLowerCase().includes(term.toLowerCase())),
//       );

//       if (appBankProfile.length !== 0) {
//         configProfile.current.id = appBankProfile[0].id;
//         configProfile.current.name = appBankProfile[0].name;
//       } else {
//         ModalAnt.error({
//           content: 'กรุณาไปเพิ่ม Configuration Profile App Bank & Chat "\n"ในหน้า Management บน Website Miradore',
//         });
//       }
//     } catch (err) {
//       const { error } = err;
//       ModalAnt.error({
//         title: error || '',
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const getDeviceInfoFromMiradore = async (sn) => {
//     let mdmSite = selectedContract?.contract?.mdm_site_code || null
//     let contractNumber = selectedContract?.contract?.contract_no || null

//     // setLoading(true);
//     try {
//       const res = await POST(GET_DEVICE_INFO_MIRADORE, { serialNumber: sn, type: trackInfo.mdm_provider_code, contract_no: contractNumber, mdm_site_code: mdmSite });
//       const { success, data } = res;
//       if (success) {
//         setLostModeStatus(data.Lostmode);
//         setLostModeDate(data.LostmodeDate);
//         setLockStatus(data.Lockapp)
//         setMDMdeviceInfo(data);


//         // let checkConfig = data.configProfile.filter((el) => el.configProfile_id === configProfile.current.id)

//         // if (!!checkConfig && checkConfig.length !== 0) {
//         //     let convertDeployTime = convertTimeLocal(checkConfig[0].deploy_time)
//         //     const convertDateConfig = checkConfig.map(el => {
//         //         return {
//         //             ...el,
//         //             deploy_time: convertDeployTime
//         //         }
//         //     })
//         //     filterConfigDevice.current = convertDateConfig
//         // } else {
//         //     filterConfigDevice.current = checkConfig
//         // }
//       }
//     } catch (err) {
//       const { error } = err;
//       ModalAnt.error({
//         title: error || err || 'ไม่สามารถรับข้อมูลจาก Miradore ได้',
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const onChange = (e, ref) => {
//     switch (ref) {
//       case 'message':
//         messageLostMode.current = e.target.value;
//         break;
//       case 'tel':
//         telLostMode.current = e.target.value;
//         break;
//       case 'statusContact':
//         setStatusContact(e.target.value);
//         break;
//       default:
//         break;
//     }
//   };

//   const openConfirm = (action) => {
//     switch (action) {
//       case 'lock_app':
//         return ModalAnt.confirm({
//           title: 'ยืนยันการล็อกแอปพลิเคชั่น?',
//           icon: <Icon.warningAlert />,
//           content: 'สามารถใช้แอปพลิเคชั่น ได้แก่ ธนาคาร, รับสาย-โทรออก, แอปพลิเคชั่น Ufriend, LINE, Facebook และ Messenger',
//           onOk() {
//             addConfigProfileToLockApp();
//           },
//           okText: 'ยืนยัน',
//           cancelText: 'ยกเลิก',
//         });
//       case 'unlock_app':
//         return ModalAnt.confirm({
//           title: 'ยืนยันการปลดล็อกแอปพลิเคชั่น?',
//           icon: <Icon.warningAlert />,
//           // content: 'ยืนยันการปลดล็อกแอปพลิเคชั่น?',
//           onOk() {
//             deleteConfigProfileToUnlockApp();
//           },
//           okText: 'ยืนยัน',
//           cancelText: 'ยกเลิก',
//         });
//       case 'enabled_lost_mode':
//         return ModalAnt.confirm({
//           title: 'ยืนยันเปิดใช้งาน Lost Mode?',
//           width: 500,
//           icon: <Icon.warningAlert />,
//           content: (
//             <>
//               {trackInfo && trackInfo.mdm_provider_code === "MIRADORE" &&
//                 <>
//                   <Text strong>ข้อความ: </Text>
//                   <textarea
//                     className="ant-input"
//                     defaultValue={messageLostMode.current}
//                     rows={2}
//                     placeholder={'กรุณากรอกข้อความ'}
//                     onChange={(e) => onChange(e, 'message')}
//                     style={{ margin: '5px 0px', borderRadius: '10px' }}
//                   />
//                   <Text strong>เบอร์โทรกลับ: </Text>
//                   <Input
//                     defaultValue={telLostMode.current}
//                     placeholder={'กรุณากรอกเบอร์โทร'}
//                     onChange={(e) => onChange(e, 'tel')}
//                     style={{ margin: '5px 0px', borderRadius: '10px' }}
//                   />
//                 </>
//               }

//             </>
//           ),
//           onOk() {
//             activateLostMode();
//           },
//           okText: 'ยืนยัน',
//           cancelText: 'ยกเลิก',
//         });
//       case 'disabled_lost_mode':
//         return ModalAnt.confirm({
//           title: 'ยืนยันปิดใช้งาน Lost Mode?',
//           icon: <Icon.warningAlert />,
//           onOk() {
//             deActivatedLostMode();
//           },
//           okText: 'ยืนยัน',
//           cancelText: 'ยกเลิก',
//         });
//       case 'icloud':
//         return ModalAnt.confirm({
//           title: 'ยืนยันเปิดใช้งาน Lost Mode?',
//           width: 500,
//           icon: <Icon.warningAlert />,
//           content: (
//             <>
//               <Text strong>ข้อความ: </Text>
//               <textarea
//                 className="ant-input"
//                 defaultValue={messageLostMode.current}
//                 rows={2}
//                 placeholder={'กรุณากรอกข้อความ'}
//                 onChange={(e) => onChange(e, 'message')}
//                 style={{ margin: '5px 0px', borderRadius: '10px' }}
//               />
//               <Text strong>เบอร์โทร: </Text>
//               <Input
//                 defaultValue={telLostMode.current}
//                 placeholder={'กรุณากรอกเบอร์โทร'}
//                 onChange={(e) => onChange(e, 'tel')}
//                 style={{ margin: '5px 0px', borderRadius: '10px' }}
//               />
//             </>
//           ),
//           onOk() {
//             icloudLostMode();
//           },
//           okText: 'ยืนยัน',
//           cancelText: 'ยกเลิก',
//         });
//       case 'unlock_icloud':
//         return ModalAnt.confirm({
//           title: 'ยืนยันปิดใช้งาน Lost Mode?',
//           icon: <Icon.warningAlert />,
//           onOk() {
//             unlockIcloud();
//           },
//           okText: 'ยืนยัน',
//           cancelText: 'ยกเลิก',
//         });
//       case 'play_sound':
//         setPlaySoundVisible(true);
//         break;
//       case 'action_log':
//         setActionLogVisible(true);
//         break;
//       default:
//         break;
//     }
//   };

//   const unlockIcloud = async () => {
//     setLoading(true);
//     try {
//       const play = await icloud({
//         contract_no: selectedContract.contract?.contract_no,
//         action: 'unlock',
//       });
//       const { success, result } = play.data;
//       if (success) {
//         ModalAnt.success({
//           title: result,
//         });
//       }
//     } catch (err) {
//       const { error } = err;
//       ModalAnt.error({
//         title: error || '',
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const icloudLostMode = async () => {
//     setLoading(true);
//     try {
//       const play = await icloud({
//         contract_no: selectedContract.contract?.contract_no,
//         action: 'lost',
//         tel: telLostMode.current,
//         message: messageLostMode.current,
//       });
//       const { success, result } = play.data;
//       if (success) {
//         ModalAnt.success({
//           title: result,
//         });
//       }
//     } catch (err) {
//       const { error } = err;
//       ModalAnt.error({
//         title: error || '',
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const addConfigProfileToLockApp = async () => {
//     let mdmSite = selectedContract?.contract?.mdm_site_code || null

//     let obj = {
//       contract_no: contractNo || '',
//       serialNumber: SN,
//       type: trackInfo.mdm_provider_code,
//       mdm_site_code: mdmSite
//     };

//     setLoading(true);
//     setLockAppLoading(true);
//     try {
//       const res = await PUT(ADD_CONFIG_PROFILE_MIRADORE, obj);
//       const { message, success } = res;
//       if (success) {
//         ModalAnt.success({
//           title: message,
//           afterClose: async () => {
//             setTimeout(() => {
//               setLockAppLoading(false);
//               getDeviceInfoFromMiradore(SN);
//             }, 5000);
//           },
//         });
//       }
//     } catch (err) {
//       const { error } = err;
//       ModalAnt.error({
//         title: error || '',
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const deleteConfigProfileToUnlockApp = async () => {
//     let mdmSite = selectedContract?.contract?.mdm_site_code || null

//     let obj = {
//       contract_no: contractNo || '',
//       serialNumber: SN,
//       type: trackInfo.mdm_provider_code,
//       mdm_site_code: mdmSite
//     };

//     setLoading(true);
//     setLockAppLoading(true);
//     try {
//       const res = await deleteConfigProfileMiradore(obj);
//       const { message, status } = res;
//       if (status) {
//         ModalAnt.success({
//           title: message,
//           afterClose: async () => {
//             setTimeout(() => {
//               setLockAppLoading(false);
//               getDeviceInfoFromMiradore(SN);
//             }, 5000);
//           },
//         });
//       }
//     } catch (err) {
//       const { error } = err;
//       ModalAnt.error({
//         title: error || '',
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const activateLostMode = async () => {
//     let mdmSite = selectedContract?.contract?.mdm_site_code || null

//     let obj = {
//       serialNumber: SN,
//       type: trackInfo.mdm_provider_code,
//       contract_no: contractNo || '',
//       message: messageLostMode.current,
//       phoneNumber: telLostMode.current,
//       mdm_site_code: mdmSite
//     };

//     setLoading(true);
//     setLostModeLoading(true);
//     try {
//       const res = await POST(ACTIVATED_LOST_MODE_MIRADORE, obj);
//       const { message, success } = res;
//       if (success) {
//         ModalAnt.success({
//           title: message,
//           afterClose: async () => {
//             setTimeout(() => {
//               setLostModeLoading(false);
//               getDeviceInfoFromMiradore(SN);
//             }, 7000);
//           },
//         });
//       }
//     } catch (err) {
//       const { error } = err;
//       ModalAnt.error({
//         title: error || '',
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const deActivatedLostMode = async () => {
//     let mdmSite = selectedContract?.contract?.mdm_site_code || null

//     let obj = {
//       serialNumber: SN,
//       type: trackInfo.mdm_provider_code,
//       contract_no: contractNo || '',
//       mdm_site_code: mdmSite
//     };

//     setLoading(true);
//     setLostModeLoading(true);
//     try {
//       const res = await deActivatedLostModeMiradore(obj);
//       const { message, success } = res;
//       if (success) {
//         ModalAnt.success({
//           title: message,
//           afterClose: async () => {
//             setTimeout(() => {
//               getDeviceInfoFromMiradore(SN);
//               setLostModeLoading(false);
//             }, 7000);
//           },
//         });
//       }
//     } catch (err) {
//       const { error } = err;
//       ModalAnt.error({
//         title: error || '',
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const reloadData = async () => {
//     setReloading(true);
//     onGetData();
//   };

//   // console.log(selectedContract);

//   return (
//     <Wrapper>
//       <Div>
//         <Modal
//           open={loading || loadingCreate || contractExportTerminated.loading}
//           noButton
//           heightAuto
//           style={{ backgroundColor: 'unset', boxShadow: 'unset' }}>
//           <Loading />
//         </Modal>
//         <Modal open={showLog} labelClose="ปิด" hideSubmit setOpen={setShowLog} btnBottomRight style={{ border: 'unset' }}>
//           <TrackLogTable logs={logs} />
//         </Modal>

//         <Grid container item style={{ height: '100%' }}>
//           <Link to="/track" style={{ marginBottom: '30px' }}>
//             <ArrowBack /> บันทึกติดตามหนี้
//           </Link>
//           <Background>
//             {trackInfo ? (
//               <table style={{ width: '100%' }}>
//                 <tbody>
//                   <tr>
//                     <Td>
//                       <TextLabel>ชื่อลูกค้า :</TextLabel> <TextValue>{trackInfo?.user?.general?.fullname}</TextValue>
//                     </Td>
//                     <Td>
//                       <TextLabel>เลขประจำตัวประชาชน :</TextLabel> <TextValue>{trackInfo?.user?.id_card}</TextValue>
//                     </Td>
//                     <Td>
//                       <TextLabel>เบอร์โทรศัพท์ :</TextLabel> <TextValue>{trackInfo?.user?.tel}</TextValue>
//                     </Td>
//                   </tr>
//                   <tr>
//                     <Td style={{ maxWidth: '300px' }}>
//                       <TextLabel>ที่อยู่ :</TextLabel> <TextValue>{trackInfo?.user?.address?.full}</TextValue>
//                     </Td>
//                   </tr>
//                   <tr>
//                     <Td>
//                       <span style={{ display: 'flex' }}>
//                         <TextLabel style={{ width: '30%' }}>นัดชำระวันที่</TextLabel>{' '}
//                         <DatePickerAntd
//                           value={selectedDate}
//                           onChange={(e) => setSelectedDate(e)}
//                           format={'DD/MM/YYYY'}
//                           placeholder="เลือกวันที่นัดชำระ"
//                         />
//                       </span>
//                     </Td>
//                     <Td>
//                       <span style={{ display: 'flex' }}>
//                         <TextLabel style={{ width: '15%' }}>ช่องทาง</TextLabel>
//                         <FormControl>
//                           <Select
//                             className={classes.select}
//                             labelId={`channel-label-${id}`}
//                             id={`channel-${id}`}
//                             value={channel}
//                             onChange={onChangeChannel}>
//                             <MenuItem value="DEFAULT" disabled>
//                               เลือก
//                             </MenuItem>
//                             <MenuItem value="CHAT">Chat</MenuItem>
//                             <MenuItem value="CALL">Call</MenuItem>
//                             <MenuItem value="ICLOUD">iCloud</MenuItem>
//                             <MenuItem value="MDM">MDM</MenuItem>
//                           </Select>
//                         </FormControl>
//                       </span>
//                     </Td>
//                     <Td>
//                       <span style={{ display: 'flex' }}>
//                         <TextLabel style={{ width: '80px' }}>สถานะ :</TextLabel>
//                         <FormControl>
//                           <Select
//                             className={classes.select}
//                             labelId={`statusContact-label-${id}`}
//                             id={`statusContact-${id}`}
//                             value={statusContact}
//                             onChange={(e) => onChange(e, 'statusContact')}>
//                             <MenuItem value="DEFAULT" disabled>
//                               เลือก
//                             </MenuItem>
//                             <MenuItem value="CAN_CONTACT">ติดต่อได้</MenuItem>
//                             <MenuItem value="CAN_NOT_CONTACT">ติดต่อไม่ได้</MenuItem>
//                             <MenuItem value="OTHERS">อื่นๆ</MenuItem>
//                           </Select>
//                         </FormControl>
//                       </span>
//                     </Td>
//                   </tr>
//                   <tr>
//                     <Td>
//                       <TextLabel style={{ fontSize: '18px', marginTop: '30px' }}>
//                         รวมค้างชำระ : {trackInfo?.amount ? new Intl.NumberFormat('th-TH').format(trackInfo?.amount) : 0}
//                       </TextLabel>
//                     </Td>
//                   </tr>
//                 </tbody>
//               </table>
//             ) : (
//               <Skeleton active />
//             )}
//             <div style={{ display: 'flex', marginTop: '30px' }}>
//               <div style={{ width: '100%' }}>
//                 {trackInfo ? (
//                   <>
//                     <div>
//                       <TextLabel>บันทึกการติดตาม</TextLabel>
//                       <Button style={{ marginLeft: '30px' }} onClick={() => getLogs()}>
//                         ประวัติการติดตาม
//                       </Button>
//                       <p>
//                         <span style={{ color: '#ffa39e', fontSize: '16px' }}>***</span> เลือกวันนัดชำระ ช่องทาง และกรอกบันทึกการติดตามเพื่อบันทึก
//                       </p>
//                       <textarea value={remark} onChange={onChangeRemark} style={{ width: '100%' }} rows="8"></textarea>
//                     </div>
//                     <Button
//                       type="primary"
//                       disabled={!remark || remark === '' || channel === 'DEFAULT' || statusContact === 'DEFAULT' || !selectedDate}
//                       className="save"
//                       style={{ width: '100%' }}
//                       onClick={() => createLog()}>
//                       บันทึก
//                     </Button>
//                     {errorCreate && (
//                       <div style={{ marginTop: '10px', color: '#E01E01' }}>
//                         <p>{errorCreate}</p>
//                       </div>
//                     )}
//                   </>
//                 ) : (
//                   <Skeleton active />
//                 )}
//               </div>
//               <div style={{ width: '100%' }}>
//                 {trackInfo ? (
//                   <div style={{ display: 'flex', gap: 50 }}>
//                     <div style={{ paddingLeft: '20px' }}>
//                       <TextLabel>บุคคลอ้างอิง</TextLabel>
//                       <p style={{ marginTop: '10px' }}>
//                         1. ({trackInfo?.user?.contact_points?.person1.relationship}) {trackInfo?.user?.contact_points?.person1.firstname}{' '}
//                         {trackInfo?.user?.contact_points?.person1.lastname} เบอร์โทร: {trackInfo?.user?.contact_points?.person1.tel}
//                       </p>
//                       <p>
//                         2. ({trackInfo?.user?.contact_points?.person2.relationship}) {trackInfo?.user?.contact_points?.person2.firstname}{' '}
//                         {trackInfo?.user?.contact_points?.person2.lastname} เบอร์โทร: {trackInfo?.user?.contact_points?.person2.tel}
//                       </p>
//                       <p>
//                         3. ({trackInfo?.user?.contact_points?.person3.relationship}) {trackInfo?.user?.contact_points?.person3.firstname}{' '}
//                         {trackInfo?.user?.contact_points?.person3.lastname} เบอร์โทร: {trackInfo?.user?.contact_points?.person3.tel}
//                       </p>
//                       {trackInfo?.user?.other_tel && (
//                         <div>
//                           <TextLabel>เบอร์โทรศัพท์อื่นๆ ของลูกค้า</TextLabel>
//                           {trackInfo?.user?.other_tel.map((el, i) => {
//                             return (
//                               <p key={i + 1} style={{ marginTop: '10px' }}>
//                                 - {el.tel} วันที่บันทึก: {new Intl.DateTimeFormat('th-TH').format(new Date(el.datetime))} สถานะ:{' '}
//                                 {el.status !== 'ACTIVE' ? 'เบอร์เก่า' : 'เบอร์รอง'}
//                               </p>
//                             );
//                           })}
//                         </div>
//                       )}
//                     </div>
//                     <div>
//                       <TextLabel>ข้อมูลภาคสนาม</TextLabel>
//                       {selectedContract?.overdued_days > 60 && (
//                         <Td>
//                           <Button type="primary" onClick={() => dispatchExportContractTerminated(selectedContract._id)}>
//                             ออกหนังสือบอกเลิกสัญญา
//                           </Button>
//                         </Td>
//                       )}
//                       <p style={{ marginTop: '5px' }}>ภาคสนาม: {selectedContract && selectedContract.name_trackker}</p>
//                       <p>Tracking No.: {selectedContract && selectedContract.tracking_no}</p>
//                     </div>
//                   </div>
//                 ) : (
//                   <Skeleton active />
//                 )}
//               </div>
//             </div>
//             <div style={{ borderBottom: '1px solid #222', marginTop: '20px' }}></div>
//             <div style={{ paddingTop: '10px' }}>
//               <p>รายการสัญญา</p>
//               {/* <span style={{ display: "flex" }}><p style={{ width: "25%" }}>วันที่ดึงข้อมูล</p> <DatePicker selected={selectedDateData} onChange={onChangeDataDate} /></span> */}
//               <div>
//                 {trackInfo?.contract_no &&
//                   trackInfo?.contract_no.map((contract, i) => (
//                     <Button
//                       type={contract === selectedContract?._id ? 'primary' : ''}
//                       key={contract}
//                       style={{ marginRight: '20px' }}
//                       onClick={() => selectContract(contract)}>
//                       {contract}
//                     </Button>
//                   ))}
//                 {
//                   <div style={{ marginTop: '20px', borderTop: '1px solid #ddd', paddingTop: '20px' }}>
//                     {selectedContract ? (
//                       <table style={{ width: '50%', marginBottom: '20px', float: 'left' }}>
//                         <tbody>
//                           <tr>
//                             <Td>
//                               <TextLabel>ชื่อสินค้า:</TextLabel>{' '}
//                               <TextValue>{selectedContract?.products.map((p) => p?.info?.name).join(',')}</TextValue>
//                             </Td>
//                             <Td>
//                               <TextLabel>SN:</TextLabel>{' '}
//                               <TextValue>
//                                 {selectedContract?.products.map((p) => p?.serial_number) !== ''
//                                   ? selectedContract?.products.map((p) => p.serial_number).join(', ')
//                                   : '-'}
//                               </TextValue>
//                             </Td>
//                           </tr>
//                           <tr>
//                             <Td>
//                               <TextLabel>วันที่ทำสัญญา:</TextLabel>{' '}
//                               <TextValue>{new Intl.DateTimeFormat('th-TH').format(new Date(selectedContract?.contract?.transaction_date))}</TextValue>
//                             </Td>
//                             <Td>
//                               <TextLabel>วันครบกำหนด:</TextLabel>{' '}
//                               <TextValue>
//                                 {selectedContract?.unpaid_periods_list[0]?.paid_date
//                                   ? new Intl.DateTimeFormat('th-TH').format(new Date(selectedContract?.unpaid_periods_list[0]?.paid_date))
//                                   : '-'}
//                               </TextValue>
//                             </Td>
//                           </tr>
//                           <tr>
//                             <Td>
//                               <TextLabel>ยอดจัดสินเชื่อ:</TextLabel>{' '}
//                               <TextValue>{new Intl.NumberFormat('th-TH').format(selectedContract?.contract?.capital_amount)}</TextValue>
//                             </Td>
//                             <Td>
//                               <TextLabel>งวดค้าง:</TextLabel> <TextValue>{selectedContract?.unpaid_periods} งวด</TextValue>
//                             </Td>
//                           </tr>
//                           <tr>
//                             <Td>
//                               <TextLabel>ค่าปรับ(%):</TextLabel> <TextValue>{selectedContract?.contract?.charge_overdue}</TextValue>
//                             </Td>
//                             <Td>
//                               <TextLabel>ค่าทวงถาม:</TextLabel> <TextValue>{selectedContract?.contract?.charge_warning}</TextValue>
//                             </Td>
//                           </tr>
//                           <tr>
//                             <Td>
//                               <TextLabel>รูปแบบการล็อคเครื่อง:</TextLabel> <TextValue>{getTypeLock(selectedContract?.contract?.type_lock)}</TextValue>
//                             </Td>

//                             <Td>
//                               <TextLabel>ผู้ให้บริการ:</TextLabel> <TextValue>{trackInfo.mdm_provider_code}</TextValue>
//                             </Td>
//                           </tr>
//                           <tr>
//                             <Td>
//                               <TextLabel>MDM Site:</TextLabel> <TextValue>{selectedContract && selectedContract.contract.mdm_site_code}</TextValue>
//                             </Td>
//                           </tr>
//                           <tr>
//                             {selectedContract?.contract?.type_lock.includes('ICLOUD') && (
//                               <>
//                                 <Td>
//                                   <Button
//                                     style={{ borderRadius: '6px', background: '#3443eb', borderColor: '#3443eb' }}
//                                     type="primary"
//                                     icon={<MobileOutlined />}
//                                     onClick={() => openConfirm('icloud')}>
//                                     Lost Mode iCloud
//                                   </Button>
//                                 </Td>
//                                 <Td>
//                                   <Button
//                                     style={{ borderRadius: '6px' }}
//                                     danger
//                                     type="primary"
//                                     icon={<MobileOutlined />}
//                                     onClick={() => openConfirm('unlock_icloud')}>
//                                     Unlock Lost Mode
//                                   </Button>
//                                 </Td>
//                               </>
//                             )}
//                           </tr>
//                           <tr>
//                             {/* Conditionally render the Play Sound button if type_lock includes 'ICLOUD' */}
//                             {selectedContract?.contract?.type_lock.includes('ICLOUD') && (
//                               <td>
//                                 <Button
//                                   style={{
//                                     borderRadius: '6px',
//                                     background: color.submit,
//                                     borderColor: color.submit
//                                   }}
//                                   type="primary"
//                                   icon={<SoundFilled />}
//                                   onClick={() => openConfirm('play_sound')}
//                                 >
//                                   Play Sound
//                                 </Button>
//                               </td>
//                             )}
//                             {/* Action Log button */}
//                             <td>
//                               <Button
//                                 style={{
//                                   borderRadius: '6px',
//                                   background: color.submit,
//                                   borderColor: color.submit
//                                 }}
//                                 type="primary"
//                                 icon={<BarsOutlined />}
//                                 onClick={() => openConfirm('action_log')}
//                               >
//                                 Action Log
//                               </Button>
//                             </td>
//                           </tr>

//                         </tbody>
//                       </table>
//                     ) : (
//                       //<Skeleton active />
//                       <div>
//                         <hr />
//                         <ButtonTheme
//                           loading={reloading}
//                           useFor="CUSTOM"
//                           title={'ดึงข้อมูลใหม่'}
//                           icon={<ReloadOutlined />}
//                           onClick={() => reloadData()}
//                         />
//                       </div>
//                     )}

//                     {selectedContract ? (
//                       !!selectedContract?.contract?.type_lock &&
//                       selectedContract?.contract?.type_lock.includes('MDM') && (
//                         <table style={{ width: '50%', marginBottom: '20px', float: 'right' }}>
//                           <tbody>
//                             <tr>
//                               <Td>
//                                 <TextLabel>
//                                   <u>MDM ข้อมูลสำหรับ {trackInfo.mdm_provider_code}</u>
//                                 </TextLabel>
//                               </Td>
//                               <Td>
//                                 <TextLabel>
//                                   <u>MDM เปิด/ปิด ใช้งาน Lost Mode บนโทรศัพท์ของลูกค้า</u>
//                                 </TextLabel>
//                               </Td>
//                             </tr>
//                             <tr>
//                             <Td>
//                                 <Tooltip title={lockStatus === 'false' ? contentMDM: contentLockStatusProcessMDM} placement="top">
//                                     <Button
//                                       type={
//                                         lockStatus === 'true' ? 'danger' :
//                                         lockStatus === 'false' ? 'primary' :
//                                         lockStatus === 'process' ? 'primary' : ''
//                                       }
//                                       onClick={() => openConfirm(
//                                         lockStatus === 'true' ? 'unlock_app' : 'lock_app'
//                                       )}
//                                       loading={lockAppLoading}
//                                       disabled={lockStatus === 'process'}
//                                     >
//                                       {lockStatus === 'true' ? 'Unlock App' :
//                                       lockStatus === 'false' ? 'Lock App' :
//                                       lockStatus === 'process' ? 'In Process' : ''}
//                                     </Button>
//                                 </Tooltip>

//                             </Td>
//                             <Td>
//                               <Tooltip title={lostModeStatus == "process" ? contentLostStatusProcessMDM : lostModeStatus == "false" ? contentMDMLostModeEnabled  : contentMDMLostModeDisable }>
//                                 <Button
//                                   type={lostModeStatus == "process" ? "primary" : lostModeStatus == "false" ? "primary"  : "danger" }

//                                   onClick={() => {
//                                     if (lostModeStatus == "true") {
//                                       openConfirm('disabled_lost_mode');
//                                     } else if (lostModeStatus != "process") {
//                                       openConfirm('enabled_lost_mode');
//                                     }

//                                   } }
//                                   loading={lostModeLoading}
//                                   style={lostModeStatus != "false" ? { marginRight: 10 } : {}}
//                                   disabled={lostModeStatus == "process"} // Disable button when status is "process"
//                                 >
//                                   {lostModeStatus == "process" ? "In Process" : lostModeStatus == "false" ? "Enable" : "Disable"}
//                                 </Button>
//                               </Tooltip>
//                             </Td>
//                             </tr>
//                             <tr>
//                               <Td>
//                                 <TextLabel>สถานะ Device:</TextLabel> <TextValue>{MDMdeviceInfo.status}</TextValue>
//                               </Td>
//                               <Td>
//                                 <TextLabel>สถานะ Lost Mode ของ Device:</TextLabel>{' '}
//                                 <TextValue>{lostModeStatus  == "process"? 'กำลังดำเนินการ':lostModeStatus == 'true'? 'เปิดใช้งาน': 'ปิดใช้งาน'}</TextValue>
//                               </Td>
//                             </tr>
//                             <tr>
//                               <Td>
//                                 <TextLabel>Deployment Time:</TextLabel> <TextValue>{MDMdeviceInfo.LockappDate || '-'}</TextValue>
//                               </Td>
//                               <Td>
//                                 <TextLabel>Deployment Time:</TextLabel> <TextValue>{MDMdeviceInfo.LostmodeDate || '-'}</TextValue>
//                               </Td>
//                             </tr>
//                             <tr>
//                               <Td>
//                                 <TextLabel>Name:</TextLabel>{' '}
//                                 <TextValue>
//                                   {!!MDMdeviceInfo.configProfile && MDMdeviceInfo.configProfile.map((el) => el.configProfile_id) !== ''
//                                     ? MDMdeviceInfo.configProfile.map((el) => el.configProfile_name).join(', ')
//                                     : '-'}
//                                 </TextValue>
//                               </Td>

//                               {/* <Td><TextLabel>Name:</TextLabel> <TextValue>{!!MDMdeviceLostModeInfo && MDMdeviceLostModeInfo[0]?.configProfile_id !== '' ? MDMdeviceLostModeInfo[0]?.configProfile_name : '-'}</TextValue></Td> */}
//                             </tr>
//                           </tbody>
//                         </table>
//                       )
//                     ) : (
//                       <Skeleton active />
//                     )}
//                     {selectedContract ? <ContractTable periods={selectedContract.periods} isLoad={isLoad} /> : <Skeleton active />}
//                     <Divider />
//                     {<OtherPaymentTable data={selectedContract?.otherPayment || []} />}
//                     {/* <ContractTable periods={selectedContract.periods} /> */}
//                     {/* {(selectedContract?.periods != null && typeof selectedContract.periods != 'undefined') ? <ContractTable periods={selectedContract.periods} /> : <Skeleton active />} */}
//                     {selectedContract ? (
//                       <table style={{ marginTop: '20px', paddingTop: '10px', width: '60%' }}>
//                         <tbody>
//                           <tr>
//                             <Td>
//                               <TextLabel>เกินกำหนด:</TextLabel> <TextValue>{selectedContract?.overdued_days}</TextValue> วัน
//                             </Td>
//                             <Td>
//                               <TextLabel>เกินกำหนดช่วงเวลา:</TextLabel> <TextValue>{getStageRange(selectedContract?.stage)}</TextValue>
//                             </Td>
//                           </tr>
//                           <tr>
//                             <Td>
//                               <TextLabel>ค่างวดค้างชำระ:</TextLabel>{' '}
//                               <TextValue>{new Intl.NumberFormat('th-TH').format(selectedContract?.total_unpaid_amount)}</TextValue>
//                             </Td>
//                             <Td>
//                               <TextLabel>ค่าปรับ:</TextLabel>{' '}
//                               <TextValue>{new Intl.NumberFormat('th-TH').format(selectedContract?.total_unpaid_charge_overdued)}</TextValue>
//                             </Td>
//                             <Td>
//                               <TextLabel>ค่าทวงถาม:</TextLabel>{' '}
//                               <TextValue>{new Intl.NumberFormat('th-TH').format(selectedContract?.total_unpaid_charge_warning)}</TextValue>
//                             </Td>
//                             <Td>
//                               <TextLabel>ค่าใช้จ่ายอื่นๆ:</TextLabel>{' '}
//                               <TextValue>{new Intl.NumberFormat('th-TH').format(selectedContract?.other_payment_total)}</TextValue>
//                             </Td>
//                           </tr>
//                           <tr>
//                             <Td>
//                               <TextLabel>ยอดปิดบัญชี: {new Intl.NumberFormat('th-TH').format(selectedContract?.total_amount_to_close)}</TextLabel>
//                             </Td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     ) : (
//                       <Skeleton active />
//                     )}
//                   </div>
//                 }
//               </div>
//             </div>
//           </Background>
//         </Grid>
//       </Div>
//       {/* <>
//           <PlaySound  visible={playSoundVisible}  />
//           <ActionLog  visible={actionLogVisible} />
//         </> */}
//       {selectedContract  &&selectedContract.contract?.contract_no && (
//         <>
//           <PlaySound contract_no={selectedContract.contract?.contract_no} visible={playSoundVisible} onCancel={() => setPlaySoundVisible(false)} />
//           <ActionLog contract_no={selectedContract.contract?.contract_no} serialNumber={SN} visible={actionLogVisible} onCancel={() => setActionLogVisible(false)} />
//         </>
//       )}

//     </Wrapper>
//   );
// };

// export default TrackPaymentInfo;
//}



/* Refactored and optimized version /*
/* eslint-disable no-unused-vars */
import { FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import { Button, Input, Modal as ModalAnt, Skeleton, Tooltip, Typography, Divider, DatePicker as DatePickerAntd } from 'antd';
import moment from 'moment/moment';
import { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ContractTable from '../../components/contract-table';
import Loading from '../../components/loading/Loading';
import Modal from '../../components/modal';
import TrackLogTable from '../../components/table/TrackLog';
import { splitTextMultipleToken } from '../../functions/fn';
import { useContract } from '../../hook/useContract';
import { getContractSummary, getPeriods } from '../../redux/api/contract';
import { createTrackLog, getTrackInfo, getTrackLog } from '../../redux/api/track';
import { Icon } from '../../resources/icon';
import { ButtonTheme } from '../../components/buttons';
import {
  BarsOutlined,
  MobileOutlined,
  ReloadOutlined,
  SoundFilled,

} from '@ant-design/icons';
import OtherPaymentTable from './views/other-payment-table';

import {
  ACTIVATED_LOST_MODE_MIRADORE,
  ADD_CONFIG_PROFILE_MIRADORE,
  GET_DEVICE_INFO_MIRADORE,
  POST,
  PUT,
} from '../../services';
import { deActivatedLostModeMiradore, deleteConfigProfileMiradore } from '../../utils/auto-admin/api';
import { auth } from '../../utils/firebase';
import { useAuthState } from '../../utils/hook';
import Wrapper from '../../views/wrapper/Wrapper';
import { Background, Div, Td, TextLabel, TextValue } from './styled-component';
import PlaySound from '../../components/modal/TrackPayment/PlaySoundModal';
import ActionLog from '../../components/modal/TrackPayment/LogActionModal';
import { icloud } from '../../redux/api/icloud';
import { color } from '../../resources/color';


const useStyles = makeStyles({
  select: {
    '& > div:focus': {
      background: 'none',
    },
  },
  input_label: {
    fontSize: '8px',
  },
});

const { Text, Paragraph } = Typography;

const contentMDM = (
  <Paragraph style={{ color: 'white' }}>
    สามารถใช้แอปพลิเคชั่น ได้แก่ ธนาคาร, รับสาย-โทรออก, แอปพลิเคชั่น Ufriend, LINE, Facebook และ Messenger
  </Paragraph>
);
const contentUnLockMDM = <Paragraph style={{ color: 'white' }}>สามารถปลด Lock การใช้งาน แอปพลิเคชั่น บนโทรศัพท์ของลูกค้า</Paragraph>;
const contentMDMLostModeEnabled = <Paragraph style={{ color: 'white' }}>สามารถเปิดใช้งาน Lost Mode บนโทรศัพท์ของลูกค้า</Paragraph>;
const contentMDMLostModeDisable = <Paragraph style={{ color: 'white' }}>สามารถปิดใช้งาน Lost Mode บนโทรศัพท์ของลูกค้า</Paragraph>;
const contentLostStatusProcessMDM = <Paragraph style={{ color: 'white' }}>กรุณารอ ขณะนี้ระบบกำลังดำเนินการ</Paragraph>;
const contentLockStatusProcessMDM = (<Paragraph style={{ color: 'white' }}> กรุณารอ ขณะนี้ระบบกำลังดำเนินการ</Paragraph>
);
const getStageRange = (stage) => {
  switch (stage) {
    case 5:
      return '60+';
    case 4:
      return '46-60';
    case 3:
      return '31-45';
    case 2:
      return '15-30';
    case 1:
      return '1-14';
    default:
      return '-';
  }
};

const getTypeLock = (typeLock) => {
  switch (typeLock) {
    case 'MDM':
      return 'MDM';
    case 'ICLOUD':
      return 'iCloud';
    case 'MDMICLOUD':
      return 'MDM+iCloud';
    default:
      return '-';
  }
};

const getLockStatusButtonType = (lockStatus) => {
  if (lockStatus === 'true') return 'danger';
  if (lockStatus === 'false') return 'primary';
  if (lockStatus === 'process') return 'primary';
  return '';
};

const getLockStatusButtonText = (lockStatus) => {
  if (lockStatus === 'true') return 'Unlock App';
  if (lockStatus === 'false') return 'Lock App';
  if (lockStatus === 'process') return 'In Process';
  return '';
};

const getLockStatusTooltipTitle = (lockStatus) => {
  if (lockStatus === 'process') {
    return contentLockStatusProcessMDM;
  } else if (lockStatus == 'false') {
    return contentMDM;
  } else if (lockStatus == 'true') {
    return contentUnLockMDM;
  }
  return ''; // default กรณีไม่มีเงื่อนไขที่ตรง
};



const getLostModeStatusButtonType = (lostModeStatus) => {
  if (lostModeStatus === 'process') return 'primary';
  if (lostModeStatus === 'false') return 'primary';
  return 'danger';
};

const getLostModeStatusButtonText = (lostModeStatus) => {
  if (lostModeStatus === 'process') return 'In Process';
  if (lostModeStatus === 'false') return 'Enable';
  return 'Disable';
};

const getLostModeStatusTooltipTitle = (lostModeStatus) => {
  if (lostModeStatus === 'process') return contentLostStatusProcessMDM;
  if (lostModeStatus === 'false') return contentMDMLostModeEnabled;
  return contentMDMLostModeDisable;
};

const TrackPaymentInfo = () => {
  const { id } = useParams();
  const [selectedDate, setSelectedDate] = useState(null);
  const [channel, setChannel] = useState('DEFAULT');
  const [remark, setRemark] = useState('');
  const classes = useStyles();
  const { user } = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [trackInfo, setTractInfo] = useState(null);
  const [error, setError] = useState(null);
  const [selectedDateData, setSelectedDateData] = useState(new Date());
  const [showLog, setShowLog] = useState(false);
  const [logs, setLogs] = useState([]);
  const [statusContact, setStatusContact] = useState('DEFAULT');
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [errorCreate, setErrorCreate] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);
  const [SN, setSN] = useState(null);
  const [contractNo, setContractNo] = useState(null);
  const configProfile = useRef({ id: null, name: null });
  const filterConfigDevice = useRef([]);
  const [MDMdeviceInfo, setMDMdeviceInfo] = useState({});
  const [MDMdeviceInfoSkeleton, setMDMdeviceInfoSkeleton] = useState(false);
  const [lostModeStatus, setLostModeStatus] = useState('process');
  const [lockStatus, setLockStatus] = useState('process');
  const [lostModeDate, setLostModeDate] = useState('');
  const [lostModeLoading, setLostModeLoading] = useState(false);
  const [lockAppLoading, setLockAppLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const messageLostMode = useRef('กรุณาติดต่อกลับ Ufriend');
  const telLostMode = useRef('0646169699');
  const [reloading, setReloading] = useState(false);
  const [playSoundVisible, setPlaySoundVisible] = useState(false);
  const [actionLogVisible, setActionLogVisible] = useState(false);
  const history = useHistory();
  const { dispatchExportContractTerminated, contractExportTerminated } = useContract();

  useEffect(() => {
    if (selectedContract?.contract?.type_lock?.includes('MDM')) {
      const productSerial = selectedContract.products
        .filter((product) => !product.info.name.includes('Pencil'))
        .map((product) => product.serial_number);

      if (productSerial.length > 0) {
        const getContractNo = trackInfo.contract_no[0] || '';
        setContractNo(getContractNo);

        const serialNumber = productSerial[0];
        handleDeviceInfo(serialNumber);
      }
    }
  }, [selectedContract]);
  const handleDeviceInfo = async (serialNumber) => {
    if (!serialNumber) {
      setMDMdeviceInfoSkeleton(true);
      return;
    }

    const formattedSerialNumber = serialNumber.includes('-')
      ? serialNumber.split('-')[0]
      : serialNumber;

    setSN(formattedSerialNumber);
    await getDeviceInfoFromMiradore(formattedSerialNumber);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getTrackInfo(id);

      if (response.status === 200) {
        const info = { ...response.data.data, contracts: null };
        setTractInfo(info);
        setLoading(false);
        setReloading(false);

        const contractResponse = await getContractSummary(info.contract_no);
        if (contractResponse.status === 200) {
          const updatedInfo = { ...info, contracts: contractResponse.data.data };
          setTractInfo(updatedInfo);
          setSelectedContract(updatedInfo.contracts[0]);
        } else {
          setError(contractResponse.response?.data?.error || 'Contract Summary Error');
        }
      } else {
        setError(response.response?.data?.error || 'Track Info Error');
      }
    } catch (err) {
      setError('Data fetching error');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (MDMdeviceInfo?.configProfile) {
      const checkConfig = MDMdeviceInfo.configProfile.filter((el) => el.configProfile_id === configProfile.current.id);

      if (checkConfig.length > 0) {
        const convertDateConfig = checkConfig.map((el) => ({
          ...el,
          deploy_time: convertTimeLocal(el.deploy_time), // Ensure correct conversion for each `deploy_time`
        }));

        filterConfigDevice.current = convertDateConfig;
      } else {
        filterConfigDevice.current = checkConfig;
      }

      setMDMdeviceInfoSkeleton(true);
    }
  }, [MDMdeviceInfo]);

  // Second useEffect
  useEffect(() => {
    const loadTransactions = async () => {
      if (selectedContract?.periods && isLoad) {
        const updatedPeriods = await Promise.all(
          selectedContract.periods.map(async (el) => {
            const res = await getPeriods(el.contract_no, el._id, el.paid_date);
            return res.data.length > 0 ? { ...el, transaction: res.data } : el;
          })
        );

        setSelectedContract((prevContract) => ({
          ...prevContract,
          periods: updatedPeriods,
        }));
        setIsLoad(false);
      }
    };

    loadTransactions();
  }, [selectedContract?.periods, isLoad]);

  const onChangeChannel = (e) => {
    setChannel(e.target.value);
  };

  const onChangeRemark = (e) => {
    setRemark(e.target.value);
  };

  const createLog = async () => {
    if (!remark || remark === '') return false;
    if (channel === 'DEFAULT') return false;
    if (statusContact === 'DEFAULT') return false;
    if (!selectedDate) return false;
    let data = {
      remark,
      channel,
      contact_status: statusContact,
      next_action_date: selectedDate,
      action_by: user.displayName,
      payment_id: trackInfo?.payment_id,
      amount: trackInfo?.amount,
    };

    setLoadingCreate(true);
    const response = await createTrackLog(id, data);
    if (response.status === 200) {
      setErrorCreate(null);
      reset();
    } else {
      setErrorCreate(response.response.data.error);
    }
    setLoadingCreate(false);
    history.goBack();
  };

  const reset = () => {
    setRemark('');
    setChannel('DEFAULT');
    setStatusContact('DEFAULT');
    setSelectedDate(null);
  };

  const selectContract = (contractNumber) => {
    const contract = trackInfo?.contracts.find((c) => c._id === contractNumber);
    if (contract) return setSelectedContract(contract);
  };

  const getLogs = async () => {
    // setLoading(true);
    let response = await getTrackLog(id);
    if (response.status === 200) {
      setLogs(response.data.data.logs);
      setShowLog(true);
    } else {
      setError(response.response.data.error);
    }
    setLoading(false);
  };

  const convertTimeLocal = (value) => {
    let splitDate = splitTextMultipleToken(value, ['.', ' ']);
    let joinDate = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0] + ' ' + splitDate[3];
    let convert = moment(joinDate).add(7, 'h').format('DD/MM/YYYY HH:mm');
    return convert;
  };

  const getDeviceInfoFromMiradore = async (sn) => {
    let mdmSite = selectedContract?.contract?.mdm_site_code || null
    let contractNumber = selectedContract?.contract?.contract_no || null

    // setLoading(true);
    try {
      const res = await POST(GET_DEVICE_INFO_MIRADORE, { serialNumber: sn, type: trackInfo.mdm_provider_code, contract_no: contractNumber, mdm_site_code: mdmSite });
      const { success, data } = res;
      setLostModeStatus('process');
      setLockStatus('process')
      if (success) {
        setLostModeStatus(data.Lostmode);
        setLostModeDate(data.LostmodeDate);
        setLockStatus(data.Lockapp)
        setMDMdeviceInfo(data);


        // let checkConfig = data.configProfile.filter((el) => el.configProfile_id === configProfile.current.id)

        // if (!!checkConfig && checkConfig.length !== 0) {
        //     let convertDeployTime = convertTimeLocal(checkConfig[0].deploy_time)
        //     const convertDateConfig = checkConfig.map(el => {
        //         return {
        //             ...el,
        //             deploy_time: convertDeployTime
        //         }
        //     })
        //     filterConfigDevice.current = convertDateConfig
        // } else {
        //     filterConfigDevice.current = checkConfig
        // }
      }
    } catch (err) {
      const { error } = err;
      ModalAnt.error({
        title: error || err || 'ไม่สามารถรับข้อมูลจาก Miradore ได้',
      });
    } finally {
      setLoading(false);
    }
  };

  const onChange = (e, ref) => {
    switch (ref) {
      case 'message':
        messageLostMode.current = e.target.value;
        break;
      case 'tel':
        telLostMode.current = e.target.value;
        break;
      case 'statusContact':
        setStatusContact(e.target.value);
        break;
      default:
        break;
    }
  };

  const openConfirm = (action) => {
    switch (action) {
      case 'lock_app':
        return ModalAnt.confirm({
          title: 'ยืนยันการล็อกแอปพลิเคชั่น?',
          icon: <Icon.warningAlert />,
          content: 'สามารถใช้แอปพลิเคชั่น ได้แก่ ธนาคาร, รับสาย-โทรออก, แอปพลิเคชั่น Ufriend, LINE, Facebook และ Messenger',
          onOk() {
            addConfigProfileToLockApp();
          },
          okText: 'ยืนยัน',
          cancelText: 'ยกเลิก',
        });
      case 'unlock_app':
        return ModalAnt.confirm({
          title: 'ยืนยันการปลดล็อกแอปพลิเคชั่น?',
          icon: <Icon.warningAlert />,
          // content: 'ยืนยันการปลดล็อกแอปพลิเคชั่น?',
          onOk() {
            deleteConfigProfileToUnlockApp();
          },
          okText: 'ยืนยัน',
          cancelText: 'ยกเลิก',
        });
      case 'enabled_lost_mode':
        return ModalAnt.confirm({
          title: 'ยืนยันเปิดใช้งาน Lost Mode?',
          width: 500,
          icon: <Icon.warningAlert />,
          content: (
            <>
              {trackInfo && trackInfo.mdm_provider_code === "MIRADORE" &&
                <>
                  <Text strong>ข้อความ: </Text>
                  <textarea
                    className="ant-input"
                    defaultValue={messageLostMode.current}
                    rows={2}
                    placeholder={'กรุณากรอกข้อความ'}
                    onChange={(e) => onChange(e, 'message')}
                    style={{ margin: '5px 0px', borderRadius: '10px' }}
                  />
                  <Text strong>เบอร์โทรกลับ: </Text>
                  <Input
                    defaultValue={telLostMode.current}
                    placeholder={'กรุณากรอกเบอร์โทร'}
                    onChange={(e) => onChange(e, 'tel')}
                    style={{ margin: '5px 0px', borderRadius: '10px' }}
                  />
                </>
              }

            </>
          ),
          onOk() {
            activateLostMode();
          },
          okText: 'ยืนยัน',
          cancelText: 'ยกเลิก',
        });
      case 'disabled_lost_mode':
        return ModalAnt.confirm({
          title: 'ยืนยันปิดใช้งาน Lost Mode?',
          icon: <Icon.warningAlert />,
          onOk() {
            deActivatedLostMode();
          },
          okText: 'ยืนยัน',
          cancelText: 'ยกเลิก',
        });
      case 'icloud':
        return ModalAnt.confirm({
          title: 'ยืนยันเปิดใช้งาน Lost Mode?',
          width: 500,
          icon: <Icon.warningAlert />,
          content: (
            <>
              <Text strong>ข้อความ: </Text>
              <textarea
                className="ant-input"
                defaultValue={messageLostMode.current}
                rows={2}
                placeholder={'กรุณากรอกข้อความ'}
                onChange={(e) => onChange(e, 'message')}
                style={{ margin: '5px 0px', borderRadius: '10px' }}
              />
              <Text strong>เบอร์โทร: </Text>
              <Input
                defaultValue={telLostMode.current}
                placeholder={'กรุณากรอกเบอร์โทร'}
                onChange={(e) => onChange(e, 'tel')}
                style={{ margin: '5px 0px', borderRadius: '10px' }}
              />
            </>
          ),
          onOk() {
            icloudLostMode();
          },
          okText: 'ยืนยัน',
          cancelText: 'ยกเลิก',
        });
      case 'unlock_icloud':
        return ModalAnt.confirm({
          title: 'ยืนยันปิดใช้งาน Lost Mode?',
          icon: <Icon.warningAlert />,
          onOk() {
            unlockIcloud();
          },
          okText: 'ยืนยัน',
          cancelText: 'ยกเลิก',
        });
      case 'play_sound':
        setPlaySoundVisible(true);
        break;
      case 'action_log':
        setActionLogVisible(true);
        break;
      default:
        break;
    }
  };

  const unlockIcloud = async () => {
    setLoading(true);
    try {
      const play = await icloud({
        contract_no: selectedContract.contract?.contract_no,
        action: 'unlock',
      });
      const { success, result } = play.data;
      if (success) {
        ModalAnt.success({
          title: result,
        });
      }
    } catch (err) {
      const { error } = err;
      ModalAnt.error({
        title: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const icloudLostMode = async () => {
    setLoading(true);
    try {
      const play = await icloud({
        contract_no: selectedContract.contract?.contract_no,
        action: 'lost',
        tel: telLostMode.current,
        message: messageLostMode.current,
      });
      const { success, result } = play.data;
      if (success) {
        ModalAnt.success({
          title: result,
        });
      }
    } catch (err) {
      const { error } = err;
      ModalAnt.error({
        title: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const addConfigProfileToLockApp = async () => {
    let mdmSite = selectedContract?.contract?.mdm_site_code || null

    let obj = {
      contract_no: contractNo || '',
      serialNumber: SN,
      type: trackInfo.mdm_provider_code,
      mdm_site_code: mdmSite
    };

    setLoading(true);
    setLockAppLoading(true);
    try {
      const res = await PUT(ADD_CONFIG_PROFILE_MIRADORE, obj);
      const { message, success } = res;
      if (success) {
        setLockStatus("process")
        ModalAnt.success({
          title: message,
          afterClose: async () => {
            setTimeout(() => {
              setLockAppLoading(false);
              getDeviceInfoFromMiradore(SN);
            }, 5000);
          },
        });
      }
    } catch (err) {
      const { error } = err;
      ModalAnt.error({
        title: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteConfigProfileToUnlockApp = async () => {
    let mdmSite = selectedContract?.contract?.mdm_site_code || null

    let obj = {
      contract_no: contractNo || '',
      serialNumber: SN,
      type: trackInfo.mdm_provider_code,
      mdm_site_code: mdmSite
    };

    setLoading(true);
    setLockAppLoading(true);
    setLockStatus("process")
    try {
      const res = await deleteConfigProfileMiradore(obj);
      const { message, status } = res;
      if (status) {
        setLockStatus("process")
        ModalAnt.success({
          title: message,
          afterClose: async () => {
            setTimeout(() => {
              setLockAppLoading(false);
              getDeviceInfoFromMiradore(SN);
            }, 5000);
          },
        });
      }
    } catch (err) {
      const { error } = err;
      ModalAnt.error({
        title: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const activateLostMode = async () => {
    let mdmSite = selectedContract?.contract?.mdm_site_code || null

    let obj = {
      serialNumber: SN,
      type: trackInfo.mdm_provider_code,
      contract_no: contractNo || '',
      message: messageLostMode.current,
      phoneNumber: telLostMode.current,
      mdm_site_code: mdmSite
    };

    setLoading(true);
    setLostModeLoading(true);
    try {
      const res = await POST(ACTIVATED_LOST_MODE_MIRADORE, obj);
      const { message, success } = res;
      setLostModeStatus("process")
      if (success) {
        setLostModeStatus("process")
        ModalAnt.success({
          title: message,
          afterClose: async () => {
            setTimeout(() => {
              setLostModeLoading(false);
              getDeviceInfoFromMiradore(SN);
            }, 7000);
          },
        });
      }
    } catch (err) {
      const { error } = err;
      ModalAnt.error({
        title: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const deActivatedLostMode = async () => {
    let mdmSite = selectedContract?.contract?.mdm_site_code || null

    let obj = {
      serialNumber: SN,
      type: trackInfo.mdm_provider_code,
      contract_no: contractNo || '',
      mdm_site_code: mdmSite
    };

    setLoading(true);
    setLostModeLoading(true);
    try {
      const res = await deActivatedLostModeMiradore(obj);
      setLostModeStatus("process")
      const { message, success } = res;
      if (success) {
        setLostModeStatus("process")
        ModalAnt.success({
          title: message,
          afterClose: async () => {
            setTimeout(() => {
              getDeviceInfoFromMiradore(SN);
              setLostModeLoading(false);
            }, 7000);
          },
        });
      }
    } catch (err) {
      const { error } = err;
      ModalAnt.error({
        title: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const reloadData = async () => {
    setReloading(true);
    fetchData();
  };

  return (
    <Wrapper>
      <Div>
        <Modal
          open={loading || loadingCreate || contractExportTerminated.loading}
          noButton
          heightAuto
          style={{ backgroundColor: 'unset', boxShadow: 'unset' }}>
          <Loading />
        </Modal>
        <Modal open={showLog} labelClose="ปิด" hideSubmit setOpen={setShowLog} btnBottomRight style={{ border: 'unset' }}>
          <TrackLogTable logs={logs} />
        </Modal>

        <Grid container item style={{ height: '100%' }}>
          <Link to="/track" style={{ marginBottom: '30px' }}>
            <ArrowBack /> บันทึกติดตามหนี้
          </Link>
          <Background>
            {trackInfo ? (
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <Td>
                      <TextLabel>ชื่อลูกค้า :</TextLabel> <TextValue>{trackInfo?.user?.general?.fullname}</TextValue>
                    </Td>
                    <Td>
                      <TextLabel>เลขประจำตัวประชาชน :</TextLabel> <TextValue>{trackInfo?.user?.id_card}</TextValue>
                    </Td>
                    <Td>
                      <TextLabel>เบอร์โทรศัพท์ :</TextLabel> <TextValue>{trackInfo?.user?.tel}</TextValue>
                    </Td>
                  </tr>
                  <tr>
                    <Td style={{ maxWidth: '300px' }}>
                      <TextLabel>ที่อยู่ :</TextLabel> <TextValue>{trackInfo?.user?.address?.full}</TextValue>
                    </Td>
                  </tr>
                  <tr>
                    <Td>
                      <span style={{ display: 'flex' }}>
                        <TextLabel style={{ width: '30%' }}>นัดชำระวันที่</TextLabel>{' '}
                        <DatePickerAntd
                          value={selectedDate}
                          onChange={(e) => setSelectedDate(e)}
                          format={'DD/MM/YYYY'}
                          placeholder="เลือกวันที่นัดชำระ"
                        />
                      </span>
                    </Td>
                    <Td>
                      <span style={{ display: 'flex' }}>
                        <TextLabel style={{ width: '15%' }}>ช่องทาง</TextLabel>
                        <FormControl>
                          <Select
                            className={classes.select}
                            labelId={`channel-label-${id}`}
                            id={`channel-${id}`}
                            value={channel}
                            onChange={onChangeChannel}>
                            <MenuItem value="DEFAULT" disabled>
                              เลือก
                            </MenuItem>
                            <MenuItem value="CHAT">Chat</MenuItem>
                            <MenuItem value="CALL">Call</MenuItem>
                            <MenuItem value="ICLOUD">iCloud</MenuItem>
                            <MenuItem value="MDM">MDM</MenuItem>
                          </Select>
                        </FormControl>
                      </span>
                    </Td>
                    <Td>
                      <span style={{ display: 'flex' }}>
                        <TextLabel style={{ width: '80px' }}>สถานะ :</TextLabel>
                        <FormControl>
                          <Select
                            className={classes.select}
                            labelId={`statusContact-label-${id}`}
                            id={`statusContact-${id}`}
                            value={statusContact}
                            onChange={(e) => onChange(e, 'statusContact')}>
                            <MenuItem value="DEFAULT" disabled>
                              เลือก
                            </MenuItem>
                            <MenuItem value="CAN_CONTACT">ติดต่อได้</MenuItem>
                            <MenuItem value="CAN_NOT_CONTACT">ติดต่อไม่ได้</MenuItem>
                            <MenuItem value="OTHERS">อื่นๆ</MenuItem>
                          </Select>
                        </FormControl>
                      </span>
                    </Td>
                  </tr>
                  <tr>
                    <Td>
                      <TextLabel style={{ fontSize: '18px', marginTop: '30px' }}>
                        รวมค้างชำระ : {trackInfo?.amount ? new Intl.NumberFormat('th-TH').format(trackInfo?.amount) : 0}
                      </TextLabel>
                    </Td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <Skeleton active />
            )}
            <div style={{ display: 'flex', marginTop: '30px' }}>
              <div style={{ width: '100%' }}>
                {trackInfo ? (
                  <>
                    <div>
                      <TextLabel>บันทึกการติดตาม</TextLabel>
                      <Button style={{ marginLeft: '30px' }} onClick={() => getLogs()}>
                        ประวัติการติดตาม
                      </Button>
                      <p>
                        <span style={{ color: '#ffa39e', fontSize: '16px' }}>***</span> เลือกวันนัดชำระ ช่องทาง และกรอกบันทึกการติดตามเพื่อบันทึก
                      </p>
                      <textarea value={remark} onChange={onChangeRemark} style={{ width: '100%' }} rows="8"></textarea>
                    </div>
                    <Button
                      type="primary"
                      disabled={!remark || remark === '' || channel === 'DEFAULT' || statusContact === 'DEFAULT' || !selectedDate}
                      className="save"
                      style={{ width: '100%' }}
                      onClick={() => createLog()}>
                      บันทึก
                    </Button>
                    {errorCreate && (
                      <div style={{ marginTop: '10px', color: '#E01E01' }}>
                        <p>{errorCreate}</p>
                      </div>
                    )}
                  </>
                ) : (
                  <Skeleton active />
                )}
              </div>
              <div style={{ width: '100%' }}>
                {trackInfo ? (
                  <div style={{ display: 'flex', gap: 50 }}>
                    <div style={{ paddingLeft: '20px' }}>
                      <TextLabel>บุคคลอ้างอิง</TextLabel>
                      <p style={{ marginTop: '10px' }}>
                        1. ({trackInfo?.user?.contact_points?.person1.relationship}) {trackInfo?.user?.contact_points?.person1.firstname}{' '}
                        {trackInfo?.user?.contact_points?.person1.lastname} เบอร์โทร: {trackInfo?.user?.contact_points?.person1.tel}
                      </p>
                      <p>
                        2. ({trackInfo?.user?.contact_points?.person2.relationship}) {trackInfo?.user?.contact_points?.person2.firstname}{' '}
                        {trackInfo?.user?.contact_points?.person2.lastname} เบอร์โทร: {trackInfo?.user?.contact_points?.person2.tel}
                      </p>
                      <p>
                        3. ({trackInfo?.user?.contact_points?.person3.relationship}) {trackInfo?.user?.contact_points?.person3.firstname}{' '}
                        {trackInfo?.user?.contact_points?.person3.lastname} เบอร์โทร: {trackInfo?.user?.contact_points?.person3.tel}
                      </p>
                      {trackInfo?.user?.other_tel && (
                        <div>
                          <TextLabel>เบอร์โทรศัพท์อื่นๆ ของลูกค้า</TextLabel>
                          {trackInfo?.user?.other_tel.map((el, i) => {
                            return (
                              <p key={i + 1} style={{ marginTop: '10px' }}>
                                - {el.tel} วันที่บันทึก: {new Intl.DateTimeFormat('th-TH').format(new Date(el.datetime))} สถานะ:{' '}
                                {el.status !== 'ACTIVE' ? 'เบอร์เก่า' : 'เบอร์รอง'}
                              </p>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div>
                      <TextLabel>ข้อมูลภาคสนาม</TextLabel>
                      {selectedContract?.overdued_days > 60 && (
                        <Td>
                          <Button type="primary" onClick={() => dispatchExportContractTerminated(selectedContract._id)}>
                            ออกหนังสือบอกเลิกสัญญา
                          </Button>
                        </Td>
                      )}
                      <p style={{ marginTop: '5px' }}>ภาคสนาม: {selectedContract && selectedContract.name_trackker}</p>
                      <p>Tracking No.: {selectedContract && selectedContract.tracking_no}</p>
                    </div>
                  </div>
                ) : (
                  <Skeleton active />
                )}
              </div>
            </div>
            <div style={{ borderBottom: '1px solid #222', marginTop: '20px' }}></div>
            <div style={{ paddingTop: '10px' }}>
              <p>รายการสัญญา</p>
              {/* <span style={{ display: "flex" }}><p style={{ width: "25%" }}>วันที่ดึงข้อมูล</p> <DatePicker selected={selectedDateData} onChange={onChangeDataDate} /></span> */}
              <div>
                {trackInfo?.contract_no &&
                  trackInfo?.contract_no.map((contract, i) => (
                    <Button
                      type={contract === selectedContract?._id ? 'primary' : ''}
                      key={contract}
                      style={{ marginRight: '20px' }}
                      onClick={() => selectContract(contract)}>
                      {contract}
                    </Button>
                  ))}
                {
                  <div style={{ marginTop: '20px', borderTop: '1px solid #ddd', paddingTop: '20px' }}>
                    {selectedContract ? (
                      <table style={{ width: '50%', marginBottom: '20px', float: 'left' }}>
                        <tbody>
                          <tr>
                            <Td>
                              <TextLabel>ชื่อสินค้า:</TextLabel>{' '}
                              <TextValue>{selectedContract?.products.map((p) => p?.info?.name).join(',')}</TextValue>
                            </Td>
                            <Td>
                              <TextLabel>SN:</TextLabel>{' '}
                              <TextValue>
                                {selectedContract?.products.map((p) => p?.serial_number) !== ''
                                  ? selectedContract?.products.map((p) => p.serial_number).join(', ')
                                  : '-'}
                              </TextValue>
                            </Td>
                          </tr>
                          <tr>
                            <Td>
                              <TextLabel>วันที่ทำสัญญา:</TextLabel>{' '}
                              <TextValue>{new Intl.DateTimeFormat('th-TH').format(new Date(selectedContract?.contract?.transaction_date))}</TextValue>
                            </Td>
                            <Td>
                              <TextLabel>วันครบกำหนด:</TextLabel>{' '}
                              <TextValue>
                                {selectedContract?.unpaid_periods_list[0]?.paid_date
                                  ? new Intl.DateTimeFormat('th-TH').format(new Date(selectedContract?.unpaid_periods_list[0]?.paid_date))
                                  : '-'}
                              </TextValue>
                            </Td>
                          </tr>
                          <tr>
                            <Td>
                              <TextLabel>ยอดจัดสินเชื่อ:</TextLabel>{' '}
                              <TextValue>{new Intl.NumberFormat('th-TH').format(selectedContract?.contract?.capital_amount)}</TextValue>
                            </Td>
                            <Td>
                              <TextLabel>งวดค้าง:</TextLabel> <TextValue>{selectedContract?.unpaid_periods} งวด</TextValue>
                            </Td>
                          </tr>
                          <tr>
                            <Td>
                              <TextLabel>ค่าปรับ(%):</TextLabel> <TextValue>{selectedContract?.contract?.charge_overdue}</TextValue>
                            </Td>
                            <Td>
                              <TextLabel>ค่าทวงถาม:</TextLabel> <TextValue>{selectedContract?.contract?.charge_warning}</TextValue>
                            </Td>
                          </tr>
                          <tr>
                            <Td>
                              <TextLabel>รูปแบบการล็อคเครื่อง:</TextLabel> <TextValue>{getTypeLock(selectedContract?.contract?.type_lock)}</TextValue>
                            </Td>

                            <Td>
                              <TextLabel>ผู้ให้บริการ:</TextLabel> <TextValue>{trackInfo.mdm_provider_code}</TextValue>
                            </Td>
                          </tr>
                          <tr>
                            <Td>
                              <TextLabel>MDM Site:</TextLabel> <TextValue>{selectedContract && selectedContract.contract.mdm_site_code}</TextValue>
                            </Td>
                          </tr>
                          <tr>
                            {selectedContract?.contract?.type_lock?.includes('ICLOUD') && (
                              <>
                                <Td>
                                  <Button
                                    style={{ borderRadius: '6px', background: '#3443eb', borderColor: '#3443eb' }}
                                    type="primary"
                                    icon={<MobileOutlined />}
                                    onClick={() => openConfirm('icloud')}>
                                    Lost Mode iCloud
                                  </Button>
                                </Td>
                                <Td>
                                  <Button
                                    style={{ borderRadius: '6px' }}
                                    danger
                                    type="primary"
                                    icon={<MobileOutlined />}
                                    onClick={() => openConfirm('unlock_icloud')}>
                                    Unlock Lost Mode
                                  </Button>
                                </Td>
                              </>
                            )}
                          </tr>
                          <tr>
                            {/* Conditionally render the Play Sound button if type_lock includes 'ICLOUD' */}
                            {selectedContract?.contract?.type_lock.includes('ICLOUD') && (
                              <td>
                                <Button
                                  style={{
                                    borderRadius: '6px',
                                    background: color.submit,
                                    borderColor: color.submit
                                  }}
                                  type="primary"
                                  icon={<SoundFilled />}
                                  onClick={() => openConfirm('play_sound')}
                                >
                                  Play Sound
                                </Button>
                              </td>
                            )}
                            {/* Action Log button */}
                            <td>
                              <Button
                                style={{
                                  borderRadius: '6px',
                                  background: '#818181',
                                  borderColor: '#818181'
                                }}
                                type="primary"
                                icon={<BarsOutlined />}
                                onClick={() => openConfirm('action_log')}
                              >
                                Action Log
                              </Button>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    ) : (
                      //<Skeleton active />
                      <div>
                        <hr />
                        <ButtonTheme
                          loading={reloading}
                          useFor="CUSTOM"
                          title={'ดึงข้อมูลใหม่'}
                          icon={<ReloadOutlined />}
                          onClick={() => reloadData()}
                        />
                      </div>
                    )}

                    {selectedContract ? (
                      !!selectedContract?.contract?.type_lock &&
                      selectedContract?.contract?.type_lock?.includes('MDM') && (
                        <table style={{ width: '50%', marginBottom: '20px', float: 'right' }}>
                          <tbody>
                            <tr>
                              <Td>
                                <TextLabel>
                                  <u>MDM ข้อมูลสำหรับ {trackInfo.mdm_provider_code}</u>
                                </TextLabel>
                              </Td>
                              <Td>
                                <TextLabel>
                                  <u>MDM เปิด/ปิด ใช้งาน Lost Mode บนโทรศัพท์ของลูกค้า</u>
                                </TextLabel>
                              </Td>
                            </tr>
                            {/* <tr>
                              <Td>
                                <Tooltip title={lockStatus == 'true' ? contentMDM : contentLockStatusProcessMDM} placement="top">
                                  <Button
                                    style={{ width: '120px', height: '32px' }}
                                    type={
                                      lockStatus == 'true' ? 'danger' :
                                        lockStatus == 'false' ? 'primary' :
                                          lockStatus == 'process' ? 'primary' : ''
                                    }
                                    onClick={() => openConfirm(
                                      lockStatus == 'true' ? 'unlock_app' : 'lock_app'
                                    )}
                                    loading={lockAppLoading}
                                    disabled={lockStatus == 'process'}
                                  >
                                    {lockStatus == 'true' ? 'Unlock App' :
                                      lockStatus == 'false' ? 'Lock App' :
                                        lockStatus == 'process' ? 'In Process' : ''}
                                  </Button>
                                </Tooltip>

                              </Td>
                              <Td>
                                <Tooltip title={lostModeStatus == "process" ? contentLostStatusProcessMDM : lostModeStatus == "false" ? contentMDMLostModeEnabled : contentMDMLostModeDisable}>
                                  <Button
                                    type={lostModeStatus == "process" ? "primary" : lostModeStatus == "false" ? "primary" : "danger"}
                                    onClick={() => {
                                      if (lostModeStatus == "true") {
                                        openConfirm('disabled_lost_mode');
                                      } else if (lostModeStatus != "process") {
                                        openConfirm('enabled_lost_mode');
                                      }

                                    }}
                                    loading={lostModeLoading}
                                    style={lostModeStatus != "false" ? { marginRight: 10, width: '120px', height: '32px' } : { width: '120px', height: '32px' }} disabled={lostModeStatus == "process"} // Disable button when status is "process"
                                  >
                                    {lostModeStatus == "process" ? "In Process" : lostModeStatus == "false" ? "Enable" : "Disable"}
                                  </Button>
                                </Tooltip>
                              </Td>
                            </tr> */}
                            <tr>
                              <Td>
                                <Tooltip title={getLockStatusTooltipTitle(lockStatus)} placement="top">
                                  <Button
                                    style={{ width: '120px', height: '32px' }}
                                    type={getLockStatusButtonType(lockStatus)}
                                    onClick={() => openConfirm(lockStatus === 'true' ? 'unlock_app' : 'lock_app')}
                                    loading={lockAppLoading}
                                    disabled={lockStatus === 'process'}
                                  >
                                    {getLockStatusButtonText(lockStatus)}
                                  </Button>
                                </Tooltip>
                              </Td>
                              <Td>
                                <Tooltip title={getLostModeStatusTooltipTitle(lostModeStatus)}>
                                  <Button
                                    type={getLostModeStatusButtonType(lostModeStatus)}
                                    onClick={() => {
                                      if (lostModeStatus === 'true') {
                                        openConfirm('disabled_lost_mode');
                                      } else if (lostModeStatus !== 'process') {
                                        openConfirm('enabled_lost_mode');
                                      }
                                    }}
                                    loading={lostModeLoading}
                                    style={
                                      lostModeStatus !== 'false'
                                        ? { marginRight: 10, width: '120px', height: '32px' }
                                        : { width: '120px', height: '32px' }
                                    }
                                    disabled={lostModeStatus === 'process'}
                                  >
                                    {getLostModeStatusButtonText(lostModeStatus)}
                                  </Button>
                                </Tooltip>
                              </Td>
                            </tr>
                            <tr>
                              <Td>
                                <TextLabel>สถานะ Device:</TextLabel> <TextValue>{MDMdeviceInfo.status}</TextValue>
                              </Td>
                              <Td>
                                <TextLabel>สถานะ Lost Mode ของ Device:</TextLabel>{' '}
                                <TextValue>{lostModeStatus == "process" ? 'กำลังดำเนินการ' : lostModeStatus == 'true' ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</TextValue>
                              </Td>
                            </tr>
                            <tr>
                              <Td>
                                <TextLabel>Deployment Time:</TextLabel> <TextValue>{MDMdeviceInfo.LockappDate || '-'}</TextValue>
                              </Td>
                              <Td>
                                <TextLabel>Deployment Time:</TextLabel> <TextValue>{MDMdeviceInfo.LostmodeDate || '-'}</TextValue>
                              </Td>
                            </tr>
                            <tr>
                              <Td>
                                <TextLabel>Name:</TextLabel>{' '}
                                <TextValue>
                                  {!!MDMdeviceInfo.configProfile && MDMdeviceInfo.configProfile.map((el) => el.configProfile_id) !== ''
                                    ? MDMdeviceInfo.configProfile.map((el) => el.configProfile_name).join(', ')
                                    : '-'}
                                </TextValue>
                              </Td>

                              {/* <Td><TextLabel>Name:</TextLabel> <TextValue>{!!MDMdeviceLostModeInfo && MDMdeviceLostModeInfo[0]?.configProfile_id !== '' ? MDMdeviceLostModeInfo[0]?.configProfile_name : '-'}</TextValue></Td> */}
                            </tr>
                          </tbody>
                        </table>
                      )
                    ) : (
                      <Skeleton active />
                    )}
                    {selectedContract ? <ContractTable periods={selectedContract.periods} isLoad={isLoad} /> : <Skeleton active />}
                    <Divider />
                    {<OtherPaymentTable data={selectedContract?.otherPayment || []} />}
                    {/* <ContractTable periods={selectedContract.periods} /> */}
                    {/* {(selectedContract?.periods != null && typeof selectedContract.periods != 'undefined') ? <ContractTable periods={selectedContract.periods} /> : <Skeleton active />} */}
                    {selectedContract ? (
                      <table style={{ marginTop: '20px', paddingTop: '10px', width: '60%' }}>
                        <tbody>
                          <tr>
                            <Td>
                              <TextLabel>เกินกำหนด:</TextLabel> <TextValue>{selectedContract?.overdued_days}</TextValue> วัน
                            </Td>
                            <Td>
                              <TextLabel>เกินกำหนดช่วงเวลา:</TextLabel> <TextValue>{getStageRange(selectedContract?.stage)}</TextValue>
                            </Td>
                          </tr>
                          <tr>
                            <Td>
                              <TextLabel>ค่างวดค้างชำระ:</TextLabel>{' '}
                              <TextValue>{new Intl.NumberFormat('th-TH').format(selectedContract?.total_unpaid_amount)}</TextValue>
                            </Td>
                            <Td>
                              <TextLabel>ค่าปรับ:</TextLabel>{' '}
                              <TextValue>{new Intl.NumberFormat('th-TH').format(selectedContract?.total_unpaid_charge_overdued)}</TextValue>
                            </Td>
                            <Td>
                              <TextLabel>ค่าทวงถาม:</TextLabel>{' '}
                              <TextValue>{new Intl.NumberFormat('th-TH').format(selectedContract?.total_unpaid_charge_warning)}</TextValue>
                            </Td>
                            <Td>
                              <TextLabel>ค่าใช้จ่ายอื่นๆ:</TextLabel>{' '}
                              <TextValue>{new Intl.NumberFormat('th-TH').format(selectedContract?.other_payment_total)}</TextValue>
                            </Td>
                          </tr>
                          <tr>
                            <Td>
                              <TextLabel>ยอดปิดบัญชี: {new Intl.NumberFormat('th-TH').format(selectedContract?.total_amount_to_close)}</TextLabel>
                            </Td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <Skeleton active />
                    )}
                  </div>
                }
              </div>
            </div>
          </Background>
        </Grid>
      </Div>
      {selectedContract && selectedContract.contract?.contract_no && (
        <>
          <PlaySound contract_no={selectedContract.contract?.contract_no} visible={playSoundVisible} onCancel={() => setPlaySoundVisible(false)} />
          <ActionLog contract_no={selectedContract.contract?.contract_no} serialNumber={SN} visible={actionLogVisible} onCancel={() => setActionLogVisible(false)} />
        </>
      )}

    </Wrapper>
  );
};

export default TrackPaymentInfo;