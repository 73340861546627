export const FORM_ICLOUD_REGISTER = ({data, onChange, loading, onRandomTeliCloud}) => {
  let {birthDate, mail, firstName, lastName, passIcloud, telIcloud} = data;

  return [
    {
      key: '1',
      span: 12,
      name: 'mail',
      label: 'อีเมล์',
      type: 'INPUT',
      defaultValue: mail,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 12,
      name: 'telIcloud',
      disabled: true,
      label: 'เบอร์สมัครไอคลาวน์',
      type: 'INPUT',
      defaultValue: telIcloud,
      copied: true,
      random: true,
      onRandom: onRandomTeliCloud,
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 12,
      name: 'firstName',
      label: 'ชื่อ',
      type: 'INPUT',
      defaultValue: firstName || '',
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      span: 12,
      name: 'lastName',
      label: 'นามสกุล',
      type: 'INPUT',
      defaultValue: lastName,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '5',
      span: 12,
      name: 'birthDate',
      label: 'วดป เกิด',
      type: 'INPUT',
      defaultValue: birthDate,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '6',
      span: 12,
      name: 'passIcloud',
      label: 'รหัสผ่านไอคลาวน์',
      type: 'INPUT',
      defaultValue: passIcloud,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '7',
      span: 12,
      name: 'confirmICloud',
      label: 'สมัครไอคลาวน์แล้วหรือไม่ ?',
      type: 'CHECKBOX',
      showRequired: true,
      rules: {
        required: {
          value: true,
          message: 'กรุณากดยืนยันสมัครไอคลาวน์',
        },
      },
      options: [{value: true, label: 'เรียบร้อย'}],
      onChangeCustom: onChange
    },
  ];
};

