import React, {useState, useEffect} from 'react';
import numeral from 'numeral';
import {Input, Tooltip, InputNumber as InputNum} from 'antd';
import {Controller, useForm} from 'react-hook-form';
import {compareRender} from '../../functions/fn';
import {EyeInvisibleOutlined, EyeTwoTone, SyncOutlined, CopyOutlined, CheckCircleOutlined} from '@ant-design/icons';
import {convertStrToFormat} from '../../functions/fn';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const InputLabel = React.memo(
  ({item, onChange, control}) => {
    const [copied, setCopied] = useState(false);
    const [text, setText] = useState('copy');
    const [loading, setLoading] = useState(false);
    const [textRandom, setTextRandom] = useState('สุ่มใหม่');

    const onCopy = () => {
      setCopied(true);
      setText('copied!');
    };

    const onUnCopy = () => {
      setCopied(false);
      setText('copy');
    };

    const onLoading = () => {
      setLoading(true);
      setTextRandom('กำลังสุ่ม...');
    };

    const onLoadingAndRandom = async () => {
      await onLoading();
      await item.onRandom(item.name);
      await setLoading(false);
      await setTextRandom('สุ่มใหม่');
    };

    return (
      <>
        <Controller
          control={control}
          name={item.name}
          ref={item.ref}
          rules={item.rules}
          value={item.value}
          defaultValue={item.defaultValue}
          render={({field: {onChange, value, ref}}) => (
           
            <>
              <Input
                ref={ref}
                name={item.name}
                prefix={item.prefix}
                suffix={item.suffix}
                autoFocus={item.autoFocus}
                defaultValue={item.defaultValue}
                // value={value ? value : item.defaultValue}
                value={item.value ? item.value : item.number ? convertStrToFormat(value, 'money') : value}
                disabled={item.disabled}
                placeholder={item.placeholder}
                onChange={item.onChangeCustom ? (e) => item.onChangeCustom(e.target.value, e.target.name) : (e) => onChange(e.target.value)}
                onKeyUp={item.handleKeyUp || null}
                style={{margin: '5px 0px', borderRadius: '10px'}}
              />
              {item.copied ? (
                <CopyToClipboard text={value} onCopy={() => onCopy()} onMouseLeave={() => onUnCopy()}>
                  <Tooltip title={text}>
                    {copied ? (
                      <CheckCircleOutlined
                        style={{
                          position: 'absolute',
                          marginTop: '14px',
                          marginLeft: item.random ? '-50px' : '-24px',
                          color: '#009000',
                          fontSize: '16px',
                        }}
                      />
                    ) : (
                      <CopyOutlined
                        style={{
                          position: 'absolute',
                          marginTop: '15px',
                          marginLeft: item.random ? '-50px' : '-24px',
                          color: '#007bff',
                          fontSize: '16px',
                        }}
                      />
                    )}
                  </Tooltip>
                </CopyToClipboard>
              ) : (
                ''
              )}
              {item.random ? (
                <Tooltip title={textRandom}>
                  <SyncOutlined
                    spin={loading}
                    style={{position: 'absolute', marginTop: '15px', marginLeft: '-24px', color: '#fd7e14'}}
                    onClick={() => onLoadingAndRandom()}
                  />
                </Tooltip>
              ) : (
                ''
              )}
            </>
          )}
        />
      </>
    );
  },
  (prev, next) =>
    compareRender(prev, next) && JSON.parse(JSON.stringify(prev.item)) === JSON.parse(JSON.stringify(next.item)) && compareRender(prev, next, 'item'),
);

export const InputLabelBlack = React.memo(
  ({ item, onChange, control }) => {
    const [copied, setCopied] = useState(false);
    const [text, setText] = useState('copy');
    const [loading, setLoading] = useState(false);
    const [textRandom, setTextRandom] = useState('สุ่มใหม่');

    const onCopy = () => {
      setCopied(true);
      setText('copied!');
    };

    const onUnCopy = () => {
      setCopied(false);
      setText('copy');
    };

    const onLoading = () => {
      setLoading(true);
      setTextRandom('กำลังสุ่ม...');
    };

    const onLoadingAndRandom = async () => {
      await onLoading();
      await item.onRandom(item.name);
      await setLoading(false);
      await setTextRandom('สุ่มใหม่');
    };

    return (
      <>
        <Controller
          control={control}
          name={item.name}
          ref={item.ref}
          rules={item.rules}
          value={item.value}
          defaultValue={item.defaultValue}
          render={({ field: { onChange, value, ref } }) => (
            <>
              <Input
                ref={ref}
                name={item.name}
                prefix={item.prefix}
                suffix={item.suffix}
                autoFocus={item.autoFocus}
                defaultValue={item.defaultValue}
                value={
                  item.value
                    ? item.value
                    : item.number
                    ? convertStrToFormat(value, 'money')
                    : value
                }
                disabled={item.disabled}
                placeholder={item.placeholder}
                onChange={
                  item.onChangeCustom
                    ? (e) => item.onChangeCustom(e.target.value, e.target.name)
                    : (e) => onChange(e.target.value)
                }
                onKeyUp={item.handleKeyUp || null}
                style={{
                  margin: '5px 0px',
                  borderRadius: '10px',
                  color: item.disabled ? '#000' : undefined, // ตัวอักษรสีดำเมื่อ disabled
                  backgroundColor: item.disabled ? '#f0f0f0' : undefined, // สีพื้นหลังเมื่อ disabled
                  opacity: item.disabled ? 1 : undefined, // ปิด opacity ที่เป็นค่า default ของ disabled
                }}
              />
              {item.copied ? (
                <CopyToClipboard
                  text={value}
                  onCopy={() => onCopy()}
                  onMouseLeave={() => onUnCopy()}
                >
                  <Tooltip title={text}>
                    {copied ? (
                      <CheckCircleOutlined
                        style={{
                          position: 'absolute',
                          marginTop: '14px',
                          marginLeft: item.random ? '-50px' : '-24px',
                          color: '#009000',
                          fontSize: '16px',
                        }}
                      />
                    ) : (
                      <CopyOutlined
                        style={{
                          position: 'absolute',
                          marginTop: '15px',
                          marginLeft: item.random ? '-50px' : '-24px',
                          color: '#007bff',
                          fontSize: '16px',
                        }}
                      />
                    )}
                  </Tooltip>
                </CopyToClipboard>
              ) : (
                ''
              )}
              {item.random ? (
                <Tooltip title={textRandom}>
                  <SyncOutlined
                    spin={loading}
                    style={{
                      position: 'absolute',
                      marginTop: '15px',
                      marginLeft: '-24px',
                      color: '#fd7e14',
                    }}
                    onClick={() => onLoadingAndRandom()}
                  />
                </Tooltip>
              ) : (
                ''
              )}
            </>
          )}
        />
      </>
    );
  },
  (prev, next) =>
    compareRender(prev, next) &&
    JSON.parse(JSON.stringify(prev.item)) ===
      JSON.parse(JSON.stringify(next.item)) &&
    compareRender(prev, next, 'item')
);


export const InputNumberOnly = React.memo(({item, onChange, control}) => {
  return (
    <Controller
      control={control}
      name={item.name}
      rules={item.rules}
      value={item.value}
      defaultValue={item.defaultValue}
      render={({field: {onChange, value}}) => (
        <Input
          name={item.name}
          prefix={item.prefix}
          value={value || item.defaultValue}
          disabled={item.disabled}
          placeholder={item.placeholder}
          maxLength={item.max}
          onChange={
            item.onChangeCustom
              ? (e) => item.onChangeCustom(convertStrToFormat(e.target.value, 'number_only'), e.target.name)
              : (e) => onChange(convertStrToFormat(e.target.value, 'number_only'))
          }
          style={{margin: '5px 0px', borderRadius: '10px'}}
        />
      )}
    />
  );
}, compareRender);

export const InputNumber = React.memo(({item, onChange, control, setValue}) => {
  // const onBlur = (e) => {
  //   if (e < item.min) {
  //     Modal.error({
  //       title: "ต้องกรอกจำนวนมากกว่าปัจจุบัน",
  //     });
  //   }
  // };
  return (
    <Controller
      control={control}
      name={item.name}
      rules={item.rules}
      // value={item.value}
      defaultValue={item.defaultValue}
      render={({field: {onChange, value}}) => (
        <Input
          name={item.name}
          prefix={item.prefix}
          // value={value}
          value={value || item.defaultValue}
          type={'number'}
          disabled={item.disabled}
          placeholder={item.placeholder}
          min={item.min}
          // onBlur={item.min ? (e) => onBlur(e.target.value) : ""}
          onChange={item.onChangeCustom ? (e) => item.onChangeCustom(e.target.value, e.target.name) : (e) => onChange(e.target.value)}
          style={{margin: '5px 0px', borderRadius: '10px'}}
        />
      )}
    />
  );
}, compareRender);

export const InputPassword = React.memo(({item, onChange, control}) => {
  return (
    <Controller
      control={control}
      name={item.name}
      rules={item.rules}
      value={item.value}
      defaultValue={item.defaultValue}
      render={({field: {onChange, value}}) => (
        <Input.Password
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          name={item.name}
          prefix={item.prefix}
          value={value}
          readOnly={true}
          disabled={item.disabled}
          placeholder={item.placeholder}
          onChange={(e) => onChange(e.target.value)}
          style={{margin: '5px 0px', borderRadius: '10px'}}
        />
      )}
    />
  );
}, compareRender);

export const InputAreaLabel = ({item, control}) => {
  return (
    <Controller
      control={control}
      name={item.name}
      rules={item.rules}
      // value={item.value}
      defaultValue={item.defaultValue}
      render={({field: {onChange, value}}) => (
        <textarea
          // type="textarea"
          className="ant-input"
          disabled={item.disabled}
          name={item.name}
          value={value}
          onChange={item.onChangeCustom ? (e) => item.onChangeCustom(e.target.value, e.target.name) : (e) => onChange(e.target.value)}
          rows={item.rows ? item.rows : 6}
          maxLength={item.maxLength ? item.maxLength : 100}
          placeholder={item.placeholder || `กรอกได้สูงสุด ${item.maxLength ? item.maxLength : 100} ตัวอักษร`}
          style={{margin: '5px 0px', borderRadius: '10px', width: '100%'}}
        />
      )}
    />
  );
};

export const InputPrice = React.memo(({item, onChange, control}) => {
  return (
    <Controller
      control={control}
      name={item.name}
      rules={item.rules}
      value={item.value}
      defaultValue={item.defaultValue}
      render={({field: {onChange, value}}) => (
        <Input
          name={item.name}
          prefix={item.prefix}
          value={value}
          disabled={item.disabled}
          placeholder={item.placeholder}
          maxLength={item.max}
          min={item.min}
          type={'number'}
          onChange={
            item.onChangeCustom
              ? (e) => item.onChangeCustom(convertStrToFormat(e.target.value, 'price'), e.target.name)
              : (e) => onChange(convertStrToFormat(e.target.value, 'price'))
          }
          style={{margin: '5px 0px', borderRadius: '10px', width: '100%'}}
        />
      )}
    />
  );
}, compareRender);
