/* eslint-disable import/order */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { adminLogout } from '../../redux/actions/auth';
import { Link } from 'react-router-dom';
import { auth } from '../../utils/firebase';
import { useAuthState } from '../../utils/hook';
import { Tag, Space } from 'antd'
import { NODE_ENV, SYS_DISPLAY } from '../../env.js'
// import { socketContext } from '../../utils/socketProvider';

const Header = () => {
  const dispatch = useDispatch();
  const [hidden, setHidden] = useState(false)
  const menu_user = JSON.parse(localStorage.getItem('menu_user'));
  // const { socket } =  useContext(socketContext)
  const { user, initializing } = useAuthState(auth);

  useEffect(() => {
    menu_user.forEach((val, i) => {
      // setSelectedMenu([i]);
      if (val.menu) {
        val.menu.forEach((el, k) => {
          if (window.location.pathname === '/sign-second-hand' || window.location.pathname === '/aph-sign-second-hand') {
            setHidden(true);
          }
        });
      }
    });
  }, [window.location.pathname]);

  const logOut = async () => {
    // socket.emit('command-to-logout', { email: user.email, uid: user.uid });
    dispatch(adminLogout())
  }

  return (

    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {!hidden ?
        <ul className="navbar-nav">
          <li className="nav-item">
          <Space size={[0, 8]}>
            <Link className="nav-link" data-widget="pushmenu" to="#" role="button">
              <i className="fas fa-bars" />
            </Link>
            { SYS_DISPLAY ? <Tag style={{fontSize: '22px'}} color={NODE_ENV === 'uat' ? 'red' : 'blue'}>{SYS_DISPLAY}</Tag> : null }
            </Space>
          </li>
        </ul>
        : <></>}

      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link className="nav-link" data-widget="control-sidebar" data-slide="true" to="/#" role="button" onClick={() => logOut()}>
            <i className="fas fa-sign-out-alt" />
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
