import {BookOutlined} from '@ant-design/icons';
import {Card, Modal, Spin} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {ButtonTheme} from '../../components/buttons';
import {CancelDownPaymentModal} from '../../components/modal/DownPayment/CancelDownPaymentModal';
import {DownPaymentDetailModal} from '../../components/modal/DownPayment/DownPaymentDetail';
import {DownPaymentProductModal} from '../../components/modal/DownPayment/DownPaymentProduct';
import {EditFacebookDownPaymentModal} from '../../components/modal/DownPayment/EditFacebookDownPayment';
import {EditLineIdDownPaymentModal} from '../../components/modal/DownPayment/EditLineIdDownPayment';
import {EditRemarkDownPaymentModal} from '../../components/modal/DownPayment/EditRemarkDownPayment';
import {PageLayout} from '../../components/pageLayout/PageLayout';
import {DownPaymentReportSearchForm} from '../../constants/global/SearchForm';
import {DownPaymentReportColumn} from '../../constants/global/columnTableForm';
import {convertStrToFormat} from '../../functions/fn';
import {GET, GET_STATEMENT_DOWN_PAYMENT, GET_STATEMENT_DOWN_PAYMENT_DETAIL} from '../../services/index';
import {ContainerButton} from '../../styles/styledComponentGlobal';
import {useGetReportDownPaymentExcel} from './api';
import Wrapper from '../../views/wrapper/Wrapper';

const StatementDownPaymentScreen = () => {
  const pageRef = useRef(1);
  const sizeRef = useRef(10);

  const [loading, setLoading] = useState(false);
  const [visibleDetailModal, setVisibleDetailModal] = useState(false);
  const [visibleProductModal, setVisibleProductModal] = useState(false);
  const [visibleCancelModal, setVisibleCancelModal] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [, setTotalPage] = useState(0);
  const [idCardState, setIdCardState] = useState(null);
  const [nameState, setNameState] = useState(null);
  const [telState, setTelState] = useState(null);
  const [idBookState, setIdBookState] = useState(null);
  const [statusState, setStatuseState] = useState(null);
  const [defaultValue, setDefaultValue] = useState({});
  const [dataDetail, setDataDetail] = useState({});
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleEditRemarkModal, setVisibleEditRemarkModal] = useState(false);
  const [visibleEditFacebookModal, setVisibleEditFacebookModal] = useState(false);
  const [visibleEditLineIdModal, setVisibleEditLineIdModal] = useState(false);
  const [valueSearch, setValueSearch] = useState({});
  const [logRemark, setLogRemark] = useState(null);
  const [startDateState, setStartDateState] = useState(null);
  const [endDateState, setEndDateState] = useState(null);

  const [resultReport, loadingReport, errorReport, setInputReport] = useGetReportDownPaymentExcel();

  useEffect(() => {
    if (resultReport?.message === 'success' && resultReport?.url) {
      setTimeout(() => {
        window.open(resultReport?.url, '_blank', 'noopener,noreferrer');
        onClose();
      }, 1000);
    }
  }, [resultReport]);

  useEffect(() => {
    if (errorReport) {
      const message = errorReport?.response?.data?.errors;

      Modal.error({title: 'ดึงข้อมูลไม่สำเร็จ', content: message});
      onClose();
    }
  }, [errorReport]);

  useEffect(() => {
    getStatementDownPaymentData({
      page: pageRef.current,
      size: sizeRef.current,
      user_id_card: '',
      name: '',
      tel: '',
      down_payment_id_book: '',
      status: '',
    });
  }, []);

  const createParams = (value) => {
    let params = [];
    let result = '';

    Object.keys(value).forEach((key) => {
      if (value[key]) {
        params.push(key + '=' + value[key]);
      }
    });
    if (params.length !== 0) {
      result += params.join('&');
    }
    // }

    return result;
  };

  const getStatementDownPaymentData = async (value) => {
    value.page = value?.page || 1;
    value.size = value?.size || 10;

    pageRef.current = value.page;
    sizeRef.current = value.size;

    value.user_id_card = value.user_id_card || '';
    value.name = value.name || '';
    value.tel = value.tel || '';
    value.down_payment_id_book = value.down_payment_id_book || '';
    value.start_date = value.start_date ? moment(value.start_date).format('YYYY-MM-DD') : '';
    value.end_date = value.end_date ? moment(value.end_date).format('YYYY-MM-DD') : '';

    let params = createParams(value);

    const {name, tel, user_id_card, down_payment_id_book, status, start_date, end_date} = value;

    setLoading(true);
    setIdCardState(user_id_card);
    setNameState(name);
    setTelState(tel);
    setIdBookState(down_payment_id_book);
    setStatuseState(status);
    setValueSearch(value);
    setStartDateState(start_date);
    setEndDateState(end_date);
    try {
      const res = await GET(GET_STATEMENT_DOWN_PAYMENT(params));
      const {success, data, total_item, total_page} = res;

      if (success) {
        setTotalSize(total_item);
        setTotalPage(total_page);

        const result = data.map((el) => {
          return {
            key: el?._id,
            name: el?.user?.general?.fullname,
            user_id_card: el?.user?.id_card,
            tel: el?.user?.tel,
            ...el,
          };
        });

        setDataSource(result);
      }
    } catch (err) {
      const {error} = err;
      Modal.error({
        title: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onExportExcelReport = async () => {
    const obj = {
      down_payment_id_book: idBookState,
      user_id_card: idCardState,
      name: nameState,
      tel: telState,
      status: statusState,
      start_date: startDateState,
      end_date: endDateState
    };

    const params = createParams(obj);

    await setInputReport(params.length ? `?${params}` : params);
  };

  const onPageSizeChangeCustom = (pageData, sizeData) => {
    const sizeChange = sizeRef.current !== sizeData;
    if (sizeChange) {
      // setPageState(1)
      pageRef.current = 1;
    } else {
      // setPageState(pageData)
      pageRef.current = pageData;
    }
    sizeRef.current = sizeData;

    let id_card = idCardState;
    let name = nameState;
    let tel = telState;
    let id_book = idBookState;
    let status = statusState;
    let start_date = startDateState;
    let end_date = endDateState;
    const params = {
      page: pageRef.current,
      size: sizeRef.current,
      user_id_card: id_card || '',
      name: name || '',
      tel: tel || '',
      down_payment_id_book: id_book || '',
      status: status || '',
      start_date: start_date || '',
      end_date: end_date || '',
    };

    getStatementDownPaymentData(params);
  };

  const openModal = (record, name, log) => {
    if (record) {
      setDefaultValue(record);
      switch (name) {
        case 'VIEW':
          getDataDetail(record.down_payment_id_book);
          break;
        case 'EDIT':
          getDataProduct(record.down_payment_id_book);
          break;
        case 'REMARK':
          setVisibleEditRemarkModal(true);
          setLogRemark(log);
          break;
        case 'CANCEL':
          setVisibleCancelModal(true);
          break;
        case 'FACEBOOK':
          setVisibleEditFacebookModal(true);
          break;
        case 'LINEID':
          setVisibleEditLineIdModal(true);
          break;

        default:
          break;
      }
    }
  };

  const getDataDetail = async (value) => {
    setLoading(true);
    if (value === undefined) {
      Modal.error({
        title: 'ไม่พบเลขที่ออมดาวน์จากรายงานฉบับนี้',
        content: 'ไม่สามารถดึงรายละเอียดข้อมูลมาแสดงได้',
      });
      setLoading(false);
    } else {
      try {
        const res = await GET(GET_STATEMENT_DOWN_PAYMENT_DETAIL(value));
        const { success, data } = res;
        if (success === true) {
          let result = data.map((el) => {
            return {
              user_id: el.user_id,
              downpayment_no: el.downpayment_no,
              profile_image: el?.profile_image,
              id_card: el?.id_card,
              name: el?.name,
              tel: el?.tel,
              facebook: el?.facebook,
              line_id: el?.line_id,
              user_age: el?.user_age,
              status: el?.status,
              model: el?.model,
              storage: el?.storage,
              date_of_birth: el?.date_of_birth ? moment(el?.date_of_birth).format('DD/MM/YYYY') : '',
              color: el?.color,
              down_payment: el?.down_payment ? convertStrToFormat(el?.down_payment, 'money') : 0,
              period: el?.period,
              down_payment_type: el?.down_payment_type,
              payment_per_round: el?.payment_per_round ? convertStrToFormat(el?.payment_per_round, 'money') : 0,
              paid_amount: el?.paid_amount ? convertStrToFormat(el?.paid_amount, 'money') : 0,
              remain_amount: el?.remain_amount ? convertStrToFormat(el?.remain_amount, 'money') : 0,
              start_date: el?.start_date ? moment(el?.start_date).format('DD/MM/YYYY') : '',
              end_date: el?.end_date ? moment(el?.end_date).format('DD/MM/YYYY') : '',
              pdf_url: el?.pdf_url,
              product_history: el?.product_history || [],
              remark: el?.remark,
              combo: el?.combo || []
            };
          });
          setDataDetail(result);
          // setVisibleDetailModal(true);
        }
      } catch (err) {
        const {message, error} = err;
        Modal.error({
          title: message,
          content: error || '',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const getDataProduct = async (value) => {
    setLoading(true);
    if (value === undefined) {
      Modal.error({
        title: 'ไม่พบเลขที่ออมดาวน์จากรายงานฉบับนี้',
        content: 'ไม่สามารถดึงรายละเอียดข้อมูลมาแสดงได้',
      });
      setLoading(false);
    } else {
      try {
        const res = await GET(GET_STATEMENT_DOWN_PAYMENT_DETAIL(value));
        const {success, data} = res;
        if (success === true) {
          let result = data.map((el) => {
            return {
              user_id: el.user_id,
              downpayment_no: el.downpayment_no,
              profile_image: el?.profile_image,
              id_card: el?.id_card,
              name: el?.name,
              tel: el?.tel,
              facebook: el?.facebook,
              line_id: el?.line_id,
              user_age: el?.user_age,
              status: el?.status,
              model: el?.model,
              storage: el?.storage,
              date_of_birth: el?.date_of_birth ? moment(el?.date_of_birth).format('DD/MM/YYYY') : '',
              color: el?.color,
              down_payment: el?.down_payment ? convertStrToFormat(el?.down_payment, 'money') : 0,
              period: el?.period,
              down_payment_type: el?.down_payment_type,
              payment_per_round: el?.payment_per_round ? convertStrToFormat(el?.payment_per_round, 'money') : 0,
              paid_amount: el?.paid_amount ? convertStrToFormat(el?.paid_amount, 'money') : 0,
              remain_amount: el?.remain_amount ? convertStrToFormat(el?.remain_amount, 'money') : 0,
              start_date: el?.start_date ? moment(el?.start_date).format('DD/MM/YYYY') : '',
              end_date: el?.end_date ? moment(el?.end_date).format('DD/MM/YYYY') : '',
              pdf_url: el?.pdf_url,
              product_history: el?.product_history,
              combo: el?.combo || []
            };
          });
          setDefaultValue(result);
          setVisibleProductModal(true);
        }
      } catch (err) {
        const {message, error} = err;
        Modal.error({
          title: message,
          content: error || '',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const onClose = () => setVisibleModal(false);

  useEffect(() => {
    if (dataDetail && JSON.stringify(dataDetail) !== '{}') {
      setVisibleDetailModal(true);
    }
  }, [dataDetail]);

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading || loadingReport}>
          <PageLayout
            searchLayout={{
              title: 'รายงานออมดาวน์',
              icon: <BookOutlined />,
              spanSearch: 24,
              formSearch: DownPaymentReportSearchForm(),
              onSearch: (data) => getStatementDownPaymentData(data),
              formJustify: 'left'
              // onClearCustom
            }}
            tableLayout={{
              columns: DownPaymentReportColumn({openModal}),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                  <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => setVisibleModal(true)} />
                </ContainerButton>
              ),
              rowKey: (record) => record.key,
              pagination: {
                showSizeChanger: true,
                current: pageRef.current,
                pageSize: sizeRef.current,
                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
                total: totalSize || 0,
                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: sizeRef.current,
                defaultCurrent: 1,
              },
            }}
          />
        </Spin>
      </Card>
      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleModal}
        width={550}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={async () => await onExportExcelReport()} />
          <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
        </ContainerButton>
      </Modal>
      <DownPaymentDetailModal
        visible={visibleDetailModal}
        title={'รายละเอียด'}
        onClose={() => {
          setDataDetail({});
          setVisibleDetailModal(false);
          getStatementDownPaymentData({
            page: pageRef.current,
            size: sizeRef.current,
            user_id_card: '',
            name: '',
            tel: '',
            down_payment_id_book: '',
            status: '',
            start_date: '',
            end_date: ''
          });
        }}
        data={{record: dataDetail}}
      />
      <DownPaymentProductModal
        visible={visibleProductModal}
        title={'แก้ไขสินค้า'}
        onClose={() => setVisibleProductModal(false)}
        data={{record: defaultValue}}
      />
      <EditRemarkDownPaymentModal
        visible={visibleEditRemarkModal}
        title={'แก้ไขหมายเหตุ'}
        onClose={() => setVisibleEditRemarkModal(false)}
        data={{reApi: getStatementDownPaymentData, record: defaultValue, logs: logRemark, valueSearch}}
      />
      <EditFacebookDownPaymentModal
        visible={visibleEditFacebookModal}
        title={'แก้ไขชื่อ Facebook'}
        onClose={() => setVisibleEditFacebookModal(false)}
        data={{reApi: getStatementDownPaymentData, record: defaultValue, valueSearch}}
      />
      <EditLineIdDownPaymentModal
        visible={visibleEditLineIdModal}
        title={'แก้ไขชื่อ Line ID'}
        onClose={() => setVisibleEditLineIdModal(false)}
        data={{reApi: getStatementDownPaymentData, record: defaultValue, valueSearch}}
      />
      <CancelDownPaymentModal
        visible={visibleCancelModal}
        title={'ยกเลิกออมดาวน์'}
        onClose={() => setVisibleCancelModal(false)}
        data={{reApi: getStatementDownPaymentData, record: defaultValue, valueSearch}}
      />
    </Wrapper>
  );
};

export default StatementDownPaymentScreen;
