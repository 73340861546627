
import React, { useState, useEffect, useMemo } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Spin } from 'antd';
import { UnlockOutlined } from '@ant-design/icons';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { UnlockIcloudAccountingSearchForm } from '../../constants/global/SearchForm';
import { UnlockIcloudAccountingColumn } from '../../constants/global/columnTableForm';
import { ViewIcloudPasswordModal } from '../../components/modal/UnlockiCloud/ViewIcloudPassword';
import { ApproveUnlockStatus } from '../../components/modal/UnlockiCloud/ApproveUnlockStatus';
import { GET, GET_ICLOUD_PASSWORD_BY_CONTRACT, GET_UNLOCK_ICLOUD_CONTRACT } from '../../services/index';
import { auth } from '../../utils/firebase';
import { useCurrentState } from './../../utils/hook';
import CryptoJS from 'crypto-js';
import { ENCRYPT_KEY } from '../../env.js'
import { ChatconeHistotyModal } from './modal/ChatconeHistotyModal';
import { convertStrToFormat } from '../../functions/fn';
import _ from 'lodash'

const UnlockIcloudAccounting = () => {
  const CONF_PAGE = 1
  const CONF_SIZE = 10
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [visibleViewPasswordModal, setVisibleViewPasswordModal] = useState(false);
  const [visibleApproveModal, setVisibleApproveModal] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const [contractNo, setContractNo] = useState('');
  const { user } = useCurrentState(auth);
  const [visibleChatconeHistory, setVisibleChatconeHistoryModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(CONF_PAGE);
  const [size, setSize] = useState(CONF_SIZE);
  const [totalSize, setTotalSize] = useState(0);

  const listOptions = useMemo(() => {
    return { filters, page, size };
  }, [filters, page, size]);

  useEffect(() => {
    if(!_.isEmpty(listOptions?.filters)){
      console.log('listOptions: ', listOptions);
      getDataUnlockiCloudContract(listOptions);
    }
  }, [listOptions]);

  const getDataUnlockiCloudContract = async (data) => {
    const { approve_status, user_name, user_admin_name } = data?.filters;
    setLoading(true);
    try {
      const res = await GET(GET_UNLOCK_ICLOUD_CONTRACT(approve_status, user_name, user_admin_name, page, size));
      const { success, result } = res;
      if (success) {
        const sortByClosedContractDate = result?.data?.sort((a, b) => new Date(b.closed_contract_date) - new Date(a.closed_contract_date))
        setDataSource(sortByClosedContractDate);
        setTotalSize(result?.totalItem);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const getiCloudPasswordByContract = async (contractNo) => {
    setLoading(true);
    try {
      let res = await GET(GET_ICLOUD_PASSWORD_BY_CONTRACT(contractNo));
      let { success, icloudMail, icloudPassword, icloudPin } = res;
      if (success) {
        let deCryptPassword = CryptoJS.AES.decrypt(icloudPassword, ENCRYPT_KEY).toString(CryptoJS.enc.Utf8);
        let deCryptPin = CryptoJS.AES.decrypt(icloudPin, ENCRYPT_KEY).toString(CryptoJS.enc.Utf8);
        const record = { icloudMail, deCryptPassword, deCryptPin };
        setDefaultValue(record);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  function openReceiptNewTabWithGet(url, data) {
    var form = document.createElement('form');
    form.target = '_blank';
    form.method = 'GET';
    form.action = url;
    form.style.display = 'none';

    for (var key in data) {
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }

  const openModal = (record, name) => {
    if (record) {
      setDefaultValue(record);
      setContractNo(record.contract_no);
      if (name === 'VIEW_PASSWORD') {
        getiCloudPasswordByContract(record.contract_no);
        setVisibleViewPasswordModal(true);
      } else if (name === 'APPROVE_PASSWORD') {
        setVisibleApproveModal(true);
      } else if (name === 'CHATCONE_HISTORY') {
        setVisibleChatconeHistoryModal(true);
      }
    }
  };

  const optionsApprove = [
    { value: 'APPROVED', label: 'อนุมัติแล้ว' },
    { value: 'NO_STATUS', label: 'ไม่ระบุ/รออนุมัติ' },
  ];

  const setPagination = ({page, size}) => {
    setPage(page);
    setSize(size);
  };

  const handleSetFilters = (data) => {
    const filtersData = _.mapValues(data, (value) => 
      value === undefined || value === null || value === "" ? "" : value
  );
    setFilters(filtersData);
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'ปลดล็อกรหัส iCloud ฝ่ายบัญชี',
              icon: <UnlockOutlined />,
              spanSearch: 24,
              formSearch: UnlockIcloudAccountingSearchForm({ options: { optionsApprove } }),
              onSearch: (data) => handleSetFilters(data),
            }}
            tableLayout={{
              columns: UnlockIcloudAccountingColumn({ options: { optionsApprove }, openModal, openReceiptNewTabWithGet, user }),
              dataSource: dataSource,
              pagination: {
                showSizeChanger: true,
                current: page,
                pageSize: size,
                onChange: (p, s) => setPagination({page: p, size: s}),
                pageSizeOptions: ['10', '20', '30', '40', '100'],
                total: totalSize || 0,
                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: size,
                defaultCurrent: 1,
              },
            }}
          />
        </Spin>
      </Card>
      <ViewIcloudPasswordModal
        visible={visibleViewPasswordModal}
        title={'ดูรหัสผ่าน iCloud ของสัญญาเลขที่ ' + contractNo}
        onClose={() => setVisibleViewPasswordModal(false)}
        data={{ reApi: ()=> getDataUnlockiCloudContract(listOptions), record: defaultValue, contractNo }}
      />
      <ApproveUnlockStatus
        visible={visibleApproveModal}
        title={'ยืนยันสถานะขอรหัสไอคลาวน์'}
        onClose={() => setVisibleApproveModal(false)}
        data={{ reApi: ()=> getDataUnlockiCloudContract(listOptions), record: defaultValue, user }}
      />
      
      <ChatconeHistotyModal
        visible={visibleChatconeHistory}
        title={'ยืนยันการส่งแจ้งเตือน'}
        content={'แจ้งเตือนส่งรหัสปลดล็อก iClound'}
        onClose={() => setVisibleChatconeHistoryModal(false)}
        input={defaultValue}
      />
    </Wrapper>
  );
};

export default UnlockIcloudAccounting;
