import moment from 'moment';
import noImage from '../../assets/images/no_image.jpeg';
import {checkPassword} from '../../functions/fn';
import {API_URL} from '../../env';
import _ from 'lodash';

export const AddUserForm = ({option, onChange}) => {
  const {optionRole, optionMenu, optionBranch} = option;

  return [
    {
      key: '1',
      span: 12,
      name: 'email',
      label: 'อีเมล์',
      type: 'INPUT',
      value: '',
      placeholder: 'กรุณากรอกอีเมล์',
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 6,
      name: 'role',
      label: 'role',
      type: 'SELECT',
      options: optionRole,
      onSelect: onChange,
      placeholder: 'กรุณาเลือก Role',
      value: '',
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 6,
      name: 'branch',
      label: 'สาขา',
      type: 'SELECT',
      options: optionBranch,
      onSelect: onChange,
      placeholder: 'กรุณาเลือก สาขา',
      value: '',
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 12,
      name: 'firstname',
      placeholder: 'กรุณากรอกชื่อ',
      label: 'ชื่อ',
      type: 'INPUT',
      value: '',
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      span: 12,
      name: 'surname',
      label: 'นามสกุล',
      placeholder: 'กรุณากรอกนามสกุล',
      type: 'INPUT',
      value: '',
      rules: {
        required: true,
      },
    },

    {
      key: '5',
      span: 24,
      name: 'menu',
      label: 'เมนูการใช้งาน',
      type: 'TREE_SELECT',
      data: optionMenu,
      onSelectCustom: onChange,
      showRequired: true,
      // rules: {
      //   required: true,
      // },
    },
  ];
};

export const EditUserForm = ({option, onChange, data}) => {
  const {optionRole, optionMenu, optionBranch} = option;

  return [
    {
      key: '1',
      span: 12,
      name: 'email',
      label: 'อีเมล์',
      type: 'INPUT',
      value: '',
      defaultValue: data.email || '',
      placeholder: 'กรุณากรอกอีเมล์',
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 6,
      name: 'role',
      label: 'role',
      type: 'SELECT',
      options: optionRole,
      onSelect: onChange,
      placeholder: 'กรุณาเลือก Role',
      value: '',
      defaultValue: data.role || '',
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 6,
      name: 'branch',
      label: 'สาขา',
      type: 'SELECT',
      options: optionBranch,
      onSelect: onChange,
      placeholder: 'กรุณาเลือก สาขา',
      value: '',
      defaultValue: data.branch || null,
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 12,
      name: 'firstname',
      placeholder: 'กรุณากรอกชื่อ',
      label: 'ชื่อ',
      type: 'INPUT',
      value: '',
      defaultValue: data.firstname || '',
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      span: 12,
      name: 'surname',
      label: 'นามสกุล',
      placeholder: 'กรุณากรอกนามสกุล',
      type: 'INPUT',
      value: data.surname || '',
      defaultValue: data.surname || '',
      rules: {
        required: true,
      },
    },

    {
      key: '5',
      span: 24,
      name: 'menu',
      label: 'เมนูการใช้งาน',
      type: 'TREE_SELECT',
      data: optionMenu,
      onSelectCustom: onChange,
      showRequired: true,
      value: '',
      defaultValue: data || [],
      // rules: {
      //   required: true,
      // },
    },
  ];
};

export const CreateAccountForm = ({onChange, onRandomTeliCloud, onRandomTelEmail, defaultValue, options, loading}) => {
  const {confirmUseTel, birthDate, mail, firstName, lastName, passMail, passIcloud, pin, telMail, telIcloud} = defaultValue;

  return [
    {
      key: '1',
      span: 8,
      name: 'mail',
      label: 'อีเมล์',
      type: 'INPUT',
      defaultValue: `ufriend.${mail}`,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 8,
      name: 'domainName',
      label: 'ชื่อโดเมน',
      type: 'SELECT',
      options: options.email,
      onSelect: onChange,
      placeholder: 'กรุณาเลือกชื่อโดเมน',
      value: '',
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 8,
      name: 'passMail',
      label: 'รหัสผ่านอีเมล์',
      type: 'INPUT',
      disabled: true,
      copied: true,
      defaultValue: passMail,
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      span: 8,
      name: 'firstName',
      label: 'ชื่อ',
      type: 'INPUT',
      defaultValue: firstName,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '5',
      span: 8,
      name: 'lastName',
      label: 'นามสกุล',
      type: 'INPUT',
      defaultValue: lastName,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '6',
      span: 8,
      name: 'birthDate',
      label: 'วดป เกิด',
      type: 'INPUT',
      defaultValue: birthDate,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '7',
      span: 8,
      name: 'confirmUseTel',
      label: 'ใช้เบอร์สมัครอีเมล์หรือไม่ ?',
      type: 'CHECKBOX',
      options: [{value: true, label: 'ใช้'}],
      onChangeCustom: onChange,
    },
    {
      key: '8',
      span: 8,
      name: 'telMail',
      label: 'เบอร์สมัครอีเมล์',
      type: 'INPUT',
      defaultValue: telMail,
      disabled: true,
      copied: true,
      random: true,
      hidden: !confirmUseTel,
      // loading: loading,
      onRandom: onRandomTelEmail,
      rules: {
        required: true,
      },
    },
    {
      key: '9',
      span: 8,
      name: 'telIcloud',
      label: 'เบอร์สมัครไอคลาวน์',
      type: 'INPUT',
      defaultValue: telIcloud,
      // loading: loading,
      disabled: true,
      copied: true,
      random: true,
      onRandom: onRandomTeliCloud,
      rules: {
        required: true,
      },
    },
    {
      key: '10',
      span: 8,
      name: 'passIcloud',
      label: 'รหัสผ่านไอคลาวน์',
      type: 'INPUT',
      defaultValue: passIcloud,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '11',
      span: 8,
      name: 'pin',
      label: 'รหัสพิน',
      type: 'INPUT',
      defaultValue: pin,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },

    {
      key: '12',
      span: !confirmUseTel ? 16 : 8,
      label: '',
    },
  ];
};

export const CreateEmailForm = ({onChange, onRandomTelEmail, defaultValue, options, loading}) => {
  const {birthDate, mail, firstName, lastName, passMail, confirmUseTel, telMail} = defaultValue;

  return [
    {
      key: '1',
      span: 8,
      name: 'mail',
      label: 'อีเมล์',
      type: 'INPUT',
      defaultValue: `ufriend.${mail}`,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 8,
      name: 'domainName',
      label: 'ชื่อโดเมน',
      type: 'SELECT',
      options: options.email,
      onSelect: onChange,
      placeholder: 'กรุณาเลือกชื่อโดเมน',
      value: '',
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 8,
      name: 'passMail',
      label: 'รหัสผ่านอีเมล์',
      type: 'INPUT',
      disabled: true,
      copied: true,
      defaultValue: passMail,
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      span: 8,
      name: 'firstName',
      label: 'ชื่อ',
      type: 'INPUT',
      defaultValue: firstName,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '5',
      span: 8,
      name: 'lastName',
      label: 'นามสกุล',
      type: 'INPUT',
      defaultValue: lastName,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '6',
      span: 8,
      name: 'birthDate',
      label: 'วดป เกิด',
      type: 'INPUT',
      defaultValue: birthDate,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '7',
      span: 8,
      name: 'confirmUseTel',
      label: 'ใช้เบอร์สมัครอีเมล์หรือไม่ ?',
      type: 'CHECKBOX',
      options: [{value: true, label: 'ใช้'}],
      onChangeCustom: onChange,
    },
    {
      key: '8',
      span: 8,
      name: 'telMail',
      label: 'เบอร์สมัครอีเมล์',
      type: 'INPUT',
      defaultValue: telMail,
      disabled: true,
      copied: true,
      random: true,
      hidden: !confirmUseTel,
      // loading: loading,
      onRandom: onRandomTelEmail,
      rules: {
        required: true,
      },
    },
  ];
};

export const EditAccountForm = ({data, onChange, onRandom, loading}) => {
  let {birthDate, mail, firstName, lastName, passMail, passIcloud, pin, telMail, telIcloud, confirmUseTel} = data;

  return [
    {
      key: '1',
      span: 8,
      name: 'firstName',
      label: 'ชื่อ',
      type: 'INPUT',
      defaultValue: firstName || '',
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 8,
      name: 'lastName',
      label: 'นามสกุล',
      type: 'INPUT',
      defaultValue: lastName,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 8,
      name: 'birthDate',
      label: 'วดป เกิด',
      type: 'INPUT',
      defaultValue: birthDate,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '7',
      span: 8,
      name: 'confirmUseTel',
      label: 'ใช้เบอร์สมัครอีเมล์หรือไม่ ?',
      type: 'CHECKBOX',
      hidden: telMail,
      options: [{value: true, label: 'ใช้'}],
      onChangeCustom: onChange,
    },

    {
      key: '8',
      span: 8,
      name: 'telMail',
      label: 'เบอร์สมัครอีเมล์',
      type: 'INPUT',
      defaultValue: telMail,
      hidden: !telMail && !confirmUseTel,
      disabled: true,
      copied: true,
      random: true,
      loading: loading,
      onRandom: onRandom,
      rules: {
        required: true,
      },
    },
    {
      key: '9',
      span: 8,
      name: 'telIcloud',
      label: 'เบอร์สมัครไอคลาวน์',
      type: 'INPUT',
      defaultValue: telIcloud,
      // disabled: true,
      copied: true,
      // random: true,
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      span: 8,
      name: 'mail',
      label: 'อีเมล์',
      type: 'INPUT',
      defaultValue: mail,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '6',
      span: 8,
      name: 'passMail',
      label: 'รหัสผ่านอีเมล์',
      type: 'INPUT',
      copied: true,
      defaultValue: passMail,
      rules: {
        required: true,
        maxLength: {
          value: 12,
          message: 'ระบุจำนวน 12 ตัวอักษร',
        },
        minLength: {
          value: 8,
          message: 'ระบุจำนวน 12 ตัวอักษร',
        },
        validate: {
          lengthCheck: (v) =>
            (v.length === 8 || v.length === 12) || 'ความยาวต้องเป็น 8 หรือ 12 ตัวอักษร',
          checkPass: (v) => checkPassword(v) || 'รูปแบบไม่ถูกต้อง',
        },
        pattern: {
          value: /^([0-9A-Za-z!#$%&()*+/<=>?@\[\]^{}])+$/,
          message: 'รูปแบบไม่ถูกต้อง',
        },
      },
    },
    {
      key: '10',
      span: 8,
      name: 'passIcloud',
      label: 'รหัสผ่านไอคลาวน์',
      type: 'INPUT',
      defaultValue: passIcloud,
      copied: true,
      rules: {
        required: true,
        maxLength: {
          value: 8,
          message: 'ระบุจำนวน 8 ตัวอักษร',
        },
        minLength: {
          value: 8,
          message: 'ระบุจำนวน 8 ตัวอักษร',
        },
        validate: {
          checkPass: (v) => checkPassword(v) || 'รูปแบบไม่ถูกต้อง',
        },
        pattern: {
          value: /^([0-9A-Za-z!#$%&()*+/<=>?@\[\]^{}])+$/,
          message: 'รูปแบบไม่ถูกต้อง',
        },
      },
    },
    {
      key: '11',
      span: 8,
      name: 'pin',
      label: 'รหัสพิน',
      type: 'INPUT',
      defaultValue: pin,
      copied: true,
      rules: {
        required: true,
        maxLength: {
          value: 4,
          message: 'ระบุจำนวน 4 ตัวอักษร',
        },
        minLength: {
          value: 4,
          message: 'ระบุจำนวน 4 ตัวอักษร',
        },
        pattern: {
          value: /\b(?:([0-9])(?![0-9]*\1)){4}\b/g,
          message: 'กรอกเฉพาะตัวเลขที่ไม่ซ้ำกัน',
        },
      },
    },
    {
      key: '12',
      span: 16,
      label: '',
    },
    {
      key: '13',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      rows: 2,
      rules: {
        required: true,
        maxLength: 500,
      },
    },
  ];
};
export const ManageIcloudForm = ({data, onChangeCustom, onRandomKeyPass}) => {
  const {mail, passIcloud, pin, telIcloud} = data.record;

  return [
    {
      key: '1',
      span: 12,
      name: 'mail',
      label: 'อีเมล์',
      type: 'INPUT',
      // defaultValue: mail,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 12,
      name: 'telIcloud',
      label: 'เบอร์สมัครไอคลาวน์',
      type: 'INPUT',
      // defaultValue: telIcloud,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 12,
      name: 'passIcloud',
      label: 'รหัสผ่านไอคลาวน์เดิม',
      type: 'INPUT',
      // defaultValue: passIcloud,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      span: 12,
      name: 'pin',
      label: 'รหัสพินเดิม',
      type: 'INPUT',
      // defaultValue: pin,
      disabled: true,
      copied: true,
      rules: {
        required: true,
      },
    },
    // {
    //   key: '4',
    //   span: 24,
    //   name: 'randomPass',
    //   label: 'สุ่มรหัสผ่านไอคลาวน์ และ รหัสพินใหม่',
    //   type: 'RADIO',
    //   onChangeRadio: onChangeCustom,
    //   options: [
    //     { value: 'NEW', label: 'สุ่มรหัสผ่านไอคลาวน์ และ รหัสพินใหม่' },
    //   ],
    //   rules: {
    //     required: true,
    //   },
    // },
    {
      key: '5',
      span: 12,
      name: 'passIcloudNew',
      label: 'รหัสผ่านไอคลาวน์ใหม่',
      placeholder: 'ระบุรหัสไอคลาวน์ 8 หลัก',
      type: 'INPUT',
      copied: true,
      // hidden: !data.customerType || data.customerType === 'OLD' || false,
      rules: {
        required: true,
        maxLength: {
          value: 8,
          message: 'ระบุจำนวน 8 ตัวอักษร',
        },
        minLength: {
          value: 8,
          message: 'ระบุจำนวน 8 ตัวอักษร',
        },
        validate: {
          checkPass: (v) => checkPassword(v) || 'รูปแบบไม่ถูกต้อง',
        },
        pattern: {
          value: /^([0-9A-Za-z!#$%&()*+/<=>?@\[\]^{}])+$/,
          message: 'รูปแบบไม่ถูกต้อง',
        },
      },
    },
    {
      key: '6',
      span: 12,
      name: 'pinNew',
      label: 'รหัสพินใหม่',
      placeholder: 'ระบุรหัสพิน 4 หลัก',
      type: 'INPUT',
      // hidden: !data.customerType || data.customerType === 'OLD' || false,
      copied: true,
      rules: {
        required: true,
        maxLength: {
          value: 4,
          message: 'ระบุจำนวน 4 ตัวอักษร',
        },
        minLength: {
          value: 4,
          message: 'ระบุจำนวน 4 ตัวอักษร',
        },
        pattern: {
          value: /\b(?:([0-9])(?![0-9]*\1)){4}\b/g,
          message: 'กรอกเฉพาะตัวเลขที่ไม่ซ้ำกัน',
        },
      },
    },
    {
      key: '7',
      span: 24,
      name: 'confirmManageIcloud',
      label: '',
      type: 'CHECKBOX',
      onChangeCustom: onChangeCustom,
      options: [{label: 'ยืนยันว่าต้องการจัดการ iCloud นี้'}],
      rules: {
        required: {
          value: true,
          message: 'กรุณากดยืนยันจัดการ iCloud',
        },
      },
    },
    {
      key: '8',
      span: 24,
      name: 'confirm',
      label: '',
      // hidden: !data.customerType || data.customerType === 'OLD' || false,
      type: 'CHECKBOX',
      onChangeCustom: onChangeCustom,
      options: [{label: 'ยืนยันว่าได้ลบข้อมูลทั้งหมดใน iCloud ไม่มีข้อมูลของลูกค้าเก่าแล้ว'}],
      rules: {
        required: {
          value: true,
          message: 'กรุณากดยืนยันลบข้อมูล',
        },
      },
    },
  ];
};

export const RamdomIcloudAccountForm = ({data}) => {
  return [
    {
      key: '1',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      rows: 2,
      rules: {
        required: true,
      },
    },
  ];
};

export const CreateTelephoneForm = () => {
  return [
    {
      key: '1',
      span: 24,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      type: 'INPUT',
      value: '',
      placeholder: 'กรอกเบอร์โทร 10 หลัก',
      rules: {
        required: true,
        maxLength: {
          value: 10,
          message: 'ระบุจำนวน 10 ตัวอักษร',
        },
        minLength: {
          value: 10,
          message: 'ระบุจำนวน 10 ตัวอักษร',
        },
        pattern: {
          value: /^[0-9]+$/,
          message: 'กรอกเฉพาะตัวเลขเท่านั้น',
        },
      },
    },

    {
      key: '2',
      span: 24,
      name: 'expireDate',
      label: 'วันที่หมดอายุ :',
      type: 'DATE_PICKER',
      format: 'DD/MM/YYYY',
      disabledDate: (d) => !d || d.isSameOrBefore(moment().add(-1, 'days')),
      placeholder: 'เลือกวันหมดอายุ',
      rules: {
        required: true,
      },
    },
  ];
};

export const EditTelephoneForm = ({data, onChangeCustom}) => {
  const {tel, expireDate, status, amountSpent} = data;
  return [
    {
      key: '1',
      span: 24,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      type: 'INPUT',
      defaultValue: tel || '',
      disabled: amountSpent > 0 ? true : false,
      placeholder: 'กรอกเบอร์โทร 10 หลัก',
      rules: {
        required: true,
        maxLength: {
          value: 10,
          message: 'ระบุจำนวน 10 ตัวอักษร',
        },
        minLength: {
          value: 10,
          message: 'ระบุจำนวน 10 ตัวอักษร',
        },
        pattern: {
          value: /^[0-9]+$/,
          message: 'กรอกเฉพาะตัวเลขเท่านั้น',
        },
      },
    },

    {
      key: '2',
      span: 24,
      name: 'expireDate',
      label: 'วันที่หมดอายุ :',
      type: 'DATE_PICKER',
      format: 'DD/MM/YYYY',
      // defaultValue: moment(expireDate),
      disabledDate: expireDate
        ? (d) => !d || d.isSameOrBefore(moment(expireDate).add(-1, 'days'))
        : (d) => !d || d.isSameOrBefore(moment().add(-1, 'days')),
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 24,
      name: 'status',
      label: 'เปิดใช้สมัคร iCloud :',
      type: 'SWITCH',
      oneLine: true,
      defaultValue: data.status === 'ACTIVE' ? true : false,
      onChangeCustom: onChangeCustom,
    },
    {
      key: '4',
      span: 24,
      name: 'isEmailRegis',
      label: 'เปิดใช้สมัคร Email :',
      type: 'SWITCH',
      oneLine: true,
      defaultValue: data.isEmailRegis,
      onChangeCustom: onChangeCustom,
    },
    {
      key: '5',
      span: 24,
      name: 'isUseiCloud',
      label: 'เปิดใช้สุ่ม iCloud :',
      type: 'SWITCH',
      page: 'EDIT_MANAGE_TEL',
      defaultValue: data.isUseiCloud,
      onChangeCustom: onChangeCustom,
    },
    {
      key: '6',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      rows: 2,
      rules: {
        required: true,
      },
      // onChangeCustom: onChangeCustom,
    },
  ];
};

export const ViewIcloudForm = () => {
  return [
    {
      key: '1',
      span: 24,
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      // defaultValue: data.fullName || '',
    },
    {
      key: '2',
      span: 12,
      name: 'contractNo',
      label: 'เลขที่สัญญา',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      // defaultValue: data.contractNo || '',
    },
    {
      key: '3',
      span: 12,
      name: 'contractDate',
      label: 'วันที่สร้างสัญญา',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      // defaultValue: data.contractDate || '',
    },
    {
      key: '4',
      span: 24,
      name: 'itemName',
      label: 'ชื่อสินค้า',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      // defaultValue: data.itemName || '',
    },
    {
      key: '5',
      span: 12,
      name: 'mail',
      label: 'อีเมล์',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      value: '',
      // defaultValue: data.mail || '',
    },
    {
      key: '6',
      span: 12,
      name: 'passIcloud',
      label: 'รหัสผ่านไอคลาวน์',
      type: 'INPUT_PASSWORD',
      // disabled:true,
      // defaultValue: data.passIcloud || '',
    },
    {
      key: '7',
      span: 12,
      name: 'telIcloud',
      label: 'เบอร์โทรไอคลาวน์',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      // defaultValue: data.telIcloud || '',
    },
    {
      key: '8',
      span: 12,
      name: 'pin',
      label: 'รหัสพิน',
      type: 'INPUT_PASSWORD',
      // disabled:true,
      // defaultValue: data.pin || '',
    },
  ];
};

export const ViewIcloudWaitRegisterForm = (onChange, onRandomTeliCloud, data) => {
  const {telIclouds} = data;
  return [
    {
      key: '1',
      span: 24,
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 12,
      name: 'contractNo',
      label: 'เลขที่สัญญา',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 12,
      name: 'contractDate',
      label: 'วันที่สร้างสัญญา',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      span: 24,
      name: 'itemName',
      label: 'ชื่อสินค้า',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '5',
      span: 12,
      name: 'mail',
      label: 'อีเมล์',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      rules: {
        required: true,
      },
      copied: true,
    },
    {
      key: '6',
      span: 12,
      name: 'telIclouds',
      label: 'เบอร์สมัครไอคลาวน์',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      rules: {
        required: true,
      },
      copied: true,
      random: true,
      onRandom: onRandomTeliCloud,
      defaultValue: telIclouds,
    },
    {
      key: '7',
      span: 12,
      name: 'icloudFirstName',
      label: 'ชื่อ',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      rules: {
        required: true,
      },
      copied: true,
    },
    {
      key: '8',
      span: 12,
      name: 'icloudLastName',
      label: 'นามสกุล',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      rules: {
        required: true,
      },
      copied: true,
    },
    {
      key: '9',
      span: 12,
      name: 'birthDate',
      label: 'วดป เกิด',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      rules: {
        required: true,
      },
      copied: true,
    },
    {
      key: '10',
      span: 12,
      name: 'passIcloud',
      label: 'รหัสผ่านไอคลาวน์',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      rules: {
        required: true,
      },
      copied: true,
    },
    {
      key: '11',
      span: 12,
      name: 'pin',
      label: 'รหัสพิน',
      type: 'INPUT_BLACK_FILED',
      disabled: true,
      rules: {
        required: true,
      },
      copied: true,
    },
    {
      key: '7',
      span: 12,
      name: 'confirmICloud',
      label: 'สมัครไอคลาวน์แล้วหรือไม่ ?',
      type: 'CHECKBOX',
      showRequired: true,
      rules: {
        required: {
          value: true,
          message: 'กรุณากดยืนยันสมัครไอคลาวน์',
        },
      },
      options: [{value: true, label: 'เรียบร้อย'}],
      onChangeCustom: onChange, // เรียกใช้ onChange เมื่อมีการเปลี่ยนแปลง
    },
  ];
};

export const RecheckRefinanceForm = ({data, onChangeCustom, requiredRemark}) => {
  const {
    contract_no,
    bookBankNo,
    productName,
    lease_amount,
    customerName,
    bookBankName,
    branchBankName,
    bankName,
    updateUser,
    imageFrontIdCard,
    imageBookBank,
    imageNameChangeCertificate,
    remarkContractOnline,
  } = data;

  return [
    {
      key: '1',
      span: 10,
      name: 'contract_no',
      label: 'เลขที่สัญญา',
      type: 'INPUT',
      defaultValue: contract_no,
      disabled: true,
    },

    {
      key: '2',
      span: 10,
      name: 'customerName',
      label: 'ชื่อลูกค้า',
      type: 'INPUT',
      defaultValue: customerName,
      disabled: true,
    },
    {
      key: '3',
      span: 20,
      name: 'productName',
      label: 'ชื่อสินค้า',
      type: 'INPUT',
      defaultValue: productName,
      disabled: true,
    },
    {
      key: '4',
      span: 20,
      name: 'lease_amount',
      label: 'ยอดไอโฟนแลกเงิน',
      type: 'INPUT',
      defaultValue: lease_amount,
      disabled: true,
    },
    {
      key: '5',
      span: 10,
      name: 'bookBankNo',
      label: 'เลขที่บัญชี',
      type: 'INPUT',
      defaultValue: bookBankNo,
      disabled: true,
    },
    {
      key: '6',
      span: 10,
      name: 'bookBankName',
      label: 'ชื่อบัญชี',
      type: 'INPUT',
      defaultValue: bookBankName,
      disabled: true,
    },
    {
      key: '7',
      span: 10,
      name: 'bankName',
      label: 'ธนาคาร',
      type: 'INPUT',
      defaultValue: bankName,
      disabled: true,
    },
    {
      key: '8',
      span: 10,
      name: 'branchBankName',
      label: 'สาขา',
      type: 'INPUT',
      defaultValue: branchBankName,
      disabled: true,
    },
    {
      key: '9',
      span: 20,
      name: 'updateUser',
      label: 'พนักงานขาย',
      type: 'INPUT',
      defaultValue: updateUser,
      disabled: true,
    },
    {
      key: '10',
      span: 7,
      name: 'imageBookBank',
      label: 'รูป bookbank',
      type: 'VIEWIMAGE',
      defaultValue: imageBookBank,
    },
    {
      key: '11',
      span: 7,
      name: 'imageFrontIdCard',
      label: 'รูปทำสัญญา',
      type: 'VIEWIMAGE',
      defaultValue: imageFrontIdCard,
    },
    {
      key: '12',
      span: 7,
      name: 'imageNameChangeCertificate',
      label: 'รูปใบเปลี่ยนชื่อ',
      type: 'VIEWIMAGE',
      defaultValue: imageNameChangeCertificate,
      rules: {
        hidden: true,
      },
    },
    {
      key: '13',
      span: 20,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      rows: 2,
      defaultValue: remarkContractOnline || '',
      rules: {
        required: requiredRemark,
      },
    },
  ];
};

export const EditRemark = () => {
  return [
    {
      key: '1',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      rows: 2,
      rules: {
        required: true,
      },
    },
  ];
};

export const EditFacebook = () => {
  return [
    {
      key: '1',
      span: 24,
      name: 'facebook',
      label: 'ชื่อ Facebook',
      type: 'INPUT_AREA',
      rows: 2,
      rules: {
        required: true,
      },
    },
  ];
};

export const EditLineId = () => {
  return [
    {
      key: '1',
      span: 24,
      name: 'line',
      label: 'Line ID',
      type: 'INPUT_AREA',
      rows: 2,
      rules: {
        required: true,
      },
    },
  ];
};

export const FORM_PRODUCT_DOWN_PAYMENT = ({product, data, options, fn, disabledFiled}) => {
  const {optionsColor, optionsStorage, optionsProducts, optionsPeriodPayment, optionsRoundPayment, optionsCombo, checkboxCombo} = options;
  const {chooseCheckboxCombo} = data;
  let comboOption = [];

  chooseCheckboxCombo?.length > 0 &&
    chooseCheckboxCombo.map((el, idx) => {
      let comboOptions = optionsCombo
        .filter((item) => item.type === el)
        .map((i) => {
          return {
            value: i.name,
            label: i.name,
            ...i,
          };
        });
      let combo = comboOptions?.filter((item) => item.type === el);

      comboOption.push({
        key: idx + 1,
        name: el,
        label: el,
        type: 'SELECT',
        span: 24,
        options: combo || [],
        placeholder: 'กรุณาเลือก',
        onSelect: fn.handleChooseProductCombo,
      });
    });

  return [
    {
      key: '1',
      name: 'model',
      label: 'สินค้าออมดาวน์',
      type: 'SELECT',
      placeholder: 'กรุณาเลือกสินค้าออมดาวน์',
      disabled: disabledFiled,
      span: 24,
      options: optionsProducts || [],
      defaultValue: product && product.model,
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      name: 'storage',
      label: 'ความจุ',
      type: 'SELECT',
      placeholder: 'กรุณาเลือกความจุ',
      disabled: disabledFiled,
      span: 24,
      options: optionsStorage || [],
      defaultValue: product && product.storage,
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      name: 'color',
      label: 'สี',
      type: 'SELECT',
      placeholder: 'กรุณาเลือกสี',
      disabled: disabledFiled,
      span: 24,
      options: optionsColor || [],
      defaultValue: product && product.color,
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    // chooseCheckboxCombo?.length > 0 ? {
    //   key: '7',
    //   name: 'additionalProduct',
    //   label: 'อุปกรณ์เสริม',
    //   span: 24,
    //   hidden: checkboxCombo?.length === 0 && chooseCheckboxCombo?.length === 0,
    //   type: 'CHECKBOX_OPTION',
    //   options: checkboxCombo || [],
    //   onChangeCustom: fn.onChange,
    // } : { label: '', type: '',},
    {
      key: '7',
      name: 'additionalProduct',
      label: 'อุปกรณ์เสริม',
      span: 24,
      hidden:checkboxCombo?.length === 0 && chooseCheckboxCombo?.length === 0,
      type: 'CHECKBOX_OPTION',
      options: checkboxCombo || [],
      onChangeCustom: fn.onChange,
    },
    ...comboOption,
    {
      key: '4',
      name: 'round_payment',
      label: 'รอบการออม',
      type: 'SELECT',
      placeholder: 'กรุณาเลือกรอบการออมดาวน์',
      disabled: disabledFiled,
      span: 24,
      options: optionsRoundPayment || [],
      // defaultValue: product && product.round_payment,
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '5',
      name: 'period_payment',
      label: 'ระยะเวลาออม (เดือน)',
      type: 'SELECT',
      placeholder: 'กรุณาเลือกระยะเวลาออมดาวน์',
      disabled: disabledFiled,
      span: 24,
      options: optionsPeriodPayment || [],
      // defaultValue: product && product.period_payment,
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
  ];
};

export const FORM_SN = ({fn, data}) => {
  return [
    {
      key: '1',
      span: 24,
      name: 'sn',
      label: '',
      placeholder: 'กรุณายิงบาร์โค้ด',
      type: 'INPUT',
      rows: 2,
      onChangeCustom: fn.onChange,
      disabled: data?.docType === 'CONFIRM_CHECKSTOCK' ? true : false,
      autoFocus: true,
    },
  ];
};

export const EditMainSocial = () => {
  return [
    {
      key: '1',
      span: 24,
      name: 'main_social',
      label: 'ช่องทางติดต่อหลัก',
      placeholder: 'เลือกช่องทางหลัก',
      type: 'SELECT',
      options: [
        {value: 'LINE', label: 'LINE'},
        {value: 'FACEBOOK', label: 'FACEBOOK'},
      ],
      rules: {
        required: true,
      },
    },
  ];
};
export const CreatePickSocial = ({options, data}) => {
  return [
    {
      key: '1',
      span: 24,
      name: 'branch_from',
      label: 'สาขาต้นทาง',
      placeholder: 'เลือกสาขาต้นทาง',
      type: 'SELECT',
      options: data.branchDefault === 'CENTER' ? options.branch : options.branch.filter((el) => el.value === data.branchDefault),
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 24,
      name: 'branch_to',
      label: 'สาขาปลายทาง',
      placeholder: 'เลือกสาขาปลายทาง',
      type: 'SELECT',
      options: options.branch,
      rules: {
        required: true,
      },
    },
  ];
};

export const CreateBlacklistCustomerForm = () => {
  return [
    {
      key: '1',
      span: 12,
      name: 'id_card',
      label: 'เลขบัตรประชาชน',
      type: 'INPUT',
      value: '',
      placeholder: 'กรอกเลขบัตรประชาชน 13 หลัก',
      rules: {
        required: true,
        maxLength: {
          value: 13,
          message: 'กรุณาระบุจำนวน 13 ตัว',
        },
        minLength: {
          value: 13,
          message: 'กรุณาระบุจำนวน 13 ตัว',
        },
        pattern: {
          value: /^[0-9]+$/,
          message: 'กรอกเฉพาะตัวเลขเท่านั้น',
        },
      },
    },
    {
      key: '2',
      span: 12,
      name: 'name',
      label: 'ชื่อ-นามสกุล',
      type: 'INPUT',
      value: '',
      placeholder: 'กรอกชื่อ-นามสกุล',
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 12,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      type: 'INPUT',
      value: '',
      placeholder: 'กรอกเบอร์โทร 10 หลัก',
      rules: {
        required: true,
        maxLength: {
          value: 10,
          message: 'กรุณาระบุจำนวน 10 ตัว',
        },
        minLength: {
          value: 10,
          message: 'กรุณาระบุจำนวน 10 ตัว',
        },
        pattern: {
          value: /^[0-9]+$/,
          message: 'กรอกเฉพาะตัวเลขเท่านั้น',
        },
      },
    },
    {
      key: '4',
      span: 12,
      name: 'ref',
      label: 'ลิงก์',
      type: 'INPUT',
      value: '',
      placeholder: 'กรอกลิงก์',
    },
    {
      key: '5',
      span: 24,
      name: 'description',
      label: 'คำอธิบายเพิ่ม',
      type: 'INPUT_AREA',
      value: '',
      placeholder: 'กรอกคำอธิบายเพิ่ม',
    },
  ];
};

export const AddTrackerForm = ({options, onChange, data, defaultValue}) => {
  return [
    {
      key: '4',
      span: 24,
      name: 'jobType',
      label: 'ประเภทงาน',
      type: 'SELECT',
      placeholder: 'เลือกประเภทงาน',
      options: options.contractType,
      onChangeCustom: onChange,
      rules: {
        required: true,
        message: 'กรุณาเลือกประเภทงาน',
      },
      defaultValue: (defaultValue && defaultValue.jobType) || '',
    },
    {
      key: '3',
      span: 24,
      name: 'jobTypeRemark',
      label: '',
      placeholder: 'กรอกโปรดระบุ',
      type: 'INPUT',
      hidden: data?.type !== 'OTHERS',
      rules: {
        required: data?.type === 'OTHERS',
      },
      defaultValue: (defaultValue && defaultValue.jobTypeRemark) || '',
    },
    {
      key: '1',
      span: 24,
      name: 'trackerID',
      label: 'ผู้ติดตาม',
      type: 'SELECT',
      placeholder: 'เลือกผู้ติดตาม',
      options: options.optionTracker,
      defaultValue: (defaultValue && defaultValue.tracker) || '',
      rules: {
        required: true,
        message: 'กรุณาเลือกผู้ติดตาม',
      },
    },

    {
      key: '2',
      span: 24,
      name: 'trackingFee',
      label: 'ค่าติดตาม :',
      type: 'INPUT_PRICE',
      defaultValue: (defaultValue && defaultValue.trackingFee) || '',
      rules: {
        required: true,
        message: 'กรุณาระบุค่าติดตาม',
      },
    },
  ];
};

export const EditTrackerForm = ({defaultValue}) => {
  return [
    {
      key: '1',
      span: 24,
      name: 'trackingFee',
      label: 'ค่าติดตาม :',
      type: 'INPUT_PRICE',
      defaultValue: defaultValue.trackingFee,
      rules: {
        required: true,
        message: 'กรุณาระบุค่าติดตาม',
      },
    },
  ];
};

export const AddPacakagesByProductsForm = ({options, onChange}) => {
  return [
    {
      key: 1,
      span: 24,
      name: 'package_id',
      label: 'แพคเก็จ',
      type: 'SELECT',
      options: options?.packages,
      onSelect: onChange,
      placeholder: 'กรุณาเลือกแพคเก็จ',
      rules: {
        required: true,
      },
    },
    {
      key: 2,
      span: 24,
      name: 'periods',
      label: 'งวด',
      mode: 'multiple',
      type: 'SELECT',
      options: options?.periods,
      onSelect: onChange,
      placeholder: 'กรุณาเลือกแพคเก็จ',
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 24,
      name: 'status_active',
      label: 'สถานะ :',
      type: 'SWITCH',
      defaultValue: true,
      onChangeCustom: onChange,
    },
  ];
};

export const EditPacakagesByProductsForm = ({options, onChange, defaultValue}) => {
  const defaultPeriod = defaultValue?.periods;

  return [
    {
      key: 1,
      span: 24,
      name: 'package_id',
      label: 'แพคเก็จ',
      type: 'SELECT',
      options: options?.packages,
      //defaultValue:defaultValue?.package_id,
      onSelect: onChange,
      placeholder: 'กรุณาเลือกแพคเก็จ',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: 2,
      span: 24,
      name: 'periods',
      label: 'งวด',
      mode: 'multiple',
      type: 'SELECT',
      options: options?.periods,
      //defaultValue:defaultPeriod?defaultPeriod.join().split(',') : [],
      onSelect: onChange,
      placeholder: 'กรุณาเลือกแพคเก็จ',
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 24,
      name: 'status_active',
      label: 'สถานะ :',
      type: 'SWITCH',
      defaultValue: defaultValue.status_active === 'ACTIVE' ? true : false,
      onChangeCustom: onChange,
    },
  ];
};

export const UpDateCustomerIDcardInfo = ({data, placeholder, statusSync, onChangeSetValue}) => {
  return [
    {
      key: '0',
      span: 12,
      name: 'code',
      label: 'เครื่องอ่านบัตร',
      type: 'INPUT',
      // onChangeCustom: (e) => onChangeSetValue(e, 'picture_id_card'),
      placeholder: placeholder,
      component: 'SYNC_ID_CARD',
      passValue: statusSync,
    },
    {
      key: '1',
      span: 12,
      name: 'picture_id_card',
      label: '',
      type: 'VIEWIMAGE',
      defaultValue: !!data ? data : null,
      // onChangeCustom: (e) => onChangeSetValue(e, 'picture_id_card'),
    },
    {
      key: '2',
      span: 12,
      name: 'prefix',
      label: 'คำนำหน้า',
      type: 'INPUT',
      rules: {
        required: true,
      },
      placeholder: 'กรุณากรอกคำนำหน้า',
      // onChangeCustom: (e) => onChangeSetValue(e, 'prefix'),
    },
    {
      key: '3',
      span: 12,
      name: 'fullname',
      label: 'ชื่อ-นามสกุล',
      type: 'INPUT',
      rules: {
        required: true,
      },
      placeholder: 'กรุณากรอกชื่อ-นามสกุล',
      // onChangeCustom: (e) => onChangeSetValue(e, 'fullname'),
    },
    {
      key: '4',
      span: 12,
      name: 'gender',
      label: 'เพศ',
      type: 'SELECT',
      options: [
        {label: 'ชาย', value: 'm'},
        {label: 'หญิง', value: 'f'},
      ],
      placeholder: 'กรุณาเลือกเพศ',
      // onSelect: onChange,
      // onChangeCustom: (e) => onChangeSetValue(e, 'gender'),
      rules: {
        required: true,
      },
    },
    {
      key: '5',
      span: 6,
      name: 'dob',
      label: 'วันเกิด',
      type: 'DATE_PICKER',
      placeholder: 'กรุณาเลือกวันเกิด',
      onChange: (e) => onChangeSetValue(e, 'dob'),
      rules: {
        required: true,
      },
    },
    {
      key: '5',
      span: 6,
      name: 'age',
      label: 'อายุ',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '6',
      span: 24,
      name: 'addressIdCard',
      label: 'ที่อยู่บัตรปชช',
      type: 'INPUT',
      placeholder: 'กรุณากรอกที่อยู่บัตรปชช',
      // onChangeCustom: (e) => onChangeSetValue(e, 'addressIdCard'),
      rules: {
        required: true,
      },
    },
    {
      key: '7',
      span: 12,
      name: 'zipcode',
      label: 'รหัสไปรษณีย์',
      type: 'INPUT',
      // onChangeCustom: (e) => onChangeSetValue(e, 'zipcode'),
      placeholder: 'เช่น 10210',
      rules: {
        required: true,
        maxLength: {
          value: 5,
          message: 'ระบุจำนวน 5 ตัวอักษร',
        },
        minLength: {
          value: 5,
          message: 'ระบุจำนวน 5 ตัวอักษร',
        },
        pattern: {
          value: /^[0-9]+$/,
          message: 'ต้องเป็นตัวเลขเท่านั้น',
        },
      },
    },
    {
      key: '8',
      span: 12,
      name: 'id_card',
      label: 'เลขบัตรปชช',
      type: 'INPUT',
      // onChangeCustom: (e) => onChangeSetValue(e, 'id_card'),
      disabled: true,
      rules: {
        required: true,
        maxLength: {
          value: 13,
          message: 'ระบุจำนวน 13 ตัวอักษร',
        },
        minLength: {
          value: 13,
          message: 'ระบุจำนวน 13 ตัวอักษร',
        },
        pattern: {
          value: /^[0-9]+$/,
          message: 'ต้องเป็นตัวเลขเท่านั้น',
        },
      },
    },
  ];
};

export const UpDateCustomerPersonalInfo = ({data, setAddressPresent, onChangeSetValue, otherTel}) => {
  const job_cat = data;

  return [
    {
      key: '9',
      span: 7,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      type: 'INPUT',
      rules: {
        required: true,
        maxLength: {
          value: 10,
          message: 'ระบุจำนวน 10 ตัวอักษร',
        },
        minLength: {
          value: 10,
          message: 'ระบุจำนวน 10 ตัวอักษร',
        },
        pattern: {
          value: /^[0-9]+$/,
          message: 'ต้องเป็นตัวเลขเท่านั้น',
        },
      },
    },
    {
      key: '8',
      span: 1,
      name: '',
      label: '',
      // hidden: otherTel === '',
      component: 'MODAL_OTHER_TEL',
      passValue: otherTel,
    },
    {
      key: '10',
      span: 8,
      name: 'lineId',
      label: 'ไลน์ไอดี',
      type: 'INPUT',
      // rules: {
      //   required: true,
      // },
      // onChangeCustom: (e) => onChangeSetValue(e, 'lineId'),
    },
    {
      key: '11',
      span: 8,
      name: 'facebook',
      label: 'เฟซบุ๊ค',
      type: 'INPUT',
      rules: {
        required: true,
      },
      // onChangeCustom: (e) => onChangeSetValue(e, 'facebook'),
    },
    {
      key: '12',
      span: 21,
      name: 'addressPresent',
      label: 'ที่อยู่อาศัยปัจจุบัน',
      type: 'INPUT',
      rules: {
        required: true,
      },
      // onChangeCustom: (e) => onChangeSetValue(e, 'addressPresent'),
    },
    {
      key: '13',
      span: 3,
      name: 'addressCheck',
      label: 'ที่อยู่ในบัตรปชช',
      type: 'CHECKBOX',
      options: [{value: true, label: ''}],
      onChangeCustom: (e) => setAddressPresent(e),
    },
    {
      key: '14',
      span: 24,
      name: 'job_cat',
      label: 'อาชีพ',
      type: 'SELECT',
      options: [
        {label: 'ทำงาน', value: 'employed'},
        {label: 'นักเรียน', value: 'student'},
      ],
      // onSelect: onChange,
      onChangeCustom: (e) => onChangeSetValue(e, 'job_cat'),
      rules: {
        required: true,
      },
    },
    {
      key: '15',
      span: 12,
      name: 'career_name',
      label: 'ลักษณะอาชีพ',
      type: 'INPUT',
      hidden: job_cat === 'student',
      // onChangeCustom: (e) => onChangeSetValue(e, 'career_name'),
      rules: {
        required: true,
      },
    },
    {
      key: '16',
      span: 12,
      name: 'position',
      label: 'ตำแหน่งงาน',
      type: 'INPUT',
      hidden: job_cat === 'student',
      // onChangeCustom: (e) => onChangeSetValue(e, 'position'),
    },
    {
      key: '17',
      span: 12,
      name: 'experience',
      label: 'อายุการทำงาน',
      type: 'INPUT',
      hidden: job_cat === 'student',
      // onChangeCustom: (e) => onChangeSetValue(e, 'experience'),
      rules: {
        required: true,
      },
    },
    {
      key: '18',
      span: 12,
      name: 'salary',
      label: 'รายได้ต่อเดือน',
      type: 'INPUT',
      hidden: job_cat === 'student',
      // onChangeCustom: (e) => onChangeSetValue(e, 'salary'),
      rules: {
        required: true,
        pattern: {
          value: /^[0-9]+$/,
          message: 'ต้องเป็นตัวเลขเท่านั้น',
        },
      },
    },
    {
      key: '19',
      span: 12,
      name: 'company_name',
      label: 'บริษัท/หน่วยงาน/ร้านที่ทำงาน/กิจการ',
      type: 'INPUT',
      hidden: job_cat === 'student',
      // onChangeCustom: (e) => onChangeSetValue(e, 'company_name'),
    },
    {
      key: '20',
      span: 12,
      name: 'work_tel',
      label: 'เบอร์ที่ทำงาน/เบอร์ติดต่อกิจการ',
      type: 'INPUT',
      placeholder: 'เช่น 021111111',
      hidden: job_cat === 'student',
      // onChangeCustom: (e) => onChangeSetValue(e, 'work_tel'),
      rules: {
        required: true,
      },
    },
    {
      key: '21',
      span: 24,
      name: 'work_address',
      label: 'ที่อยู่บริษัท/หน่วยงาน/ร้านที่ทำงาน/กิจการ',
      type: 'INPUT',
      hidden: job_cat === 'student',
      // onChangeCustom: (e) => onChangeSetValue(e, 'work_address'),
      rules: {
        required: true,
      },
    },
    {
      key: '22',
      span: 12,
      name: 'degree',
      label: 'ระดับการศึกษา',
      type: 'SELECT',
      options: [
        {label: 'ม.ปลาย', value: 'ม.ปลาย'},
        {label: 'ปวช', value: 'ปวช'},
        {label: 'ปวส', value: 'ปวส'},
        {label: 'ป.ตรี', value: 'ป.ตรี'},
        {label: 'สูงกว่าป.ตรี', value: 'สูงกว่าป.ตรี'},
      ],
      hidden: job_cat === 'employed',
      // onChangeCustom: (e) => onChangeSetValue(e, 'degree'),
      rules: {
        required: true,
      },
    },
    {
      key: '23',
      span: 12,
      name: 'year',
      label: 'ชั้นปีที่',
      type: 'INPUT',
      hidden: job_cat === 'employed',
      // onChangeCustom: (e) => onChangeSetValue(e, 'year'),
      rules: {
        required: true,
      },
    },
    {
      key: '24',
      span: 12,
      name: 'college',
      label: 'ชื่อมหาวิทยาลัย',
      type: 'INPUT',
      hidden: job_cat === 'employed',
      // onChangeCustom: (e) => onChangeSetValue(e, 'college'),
      rules: {
        required: true,
      },
    },
    {
      key: '25',
      span: 12,
      name: 'faculty',
      label: 'คณะ',
      type: 'INPUT',
      hidden: job_cat === 'employed',
      // onChangeCustom: (e) => onChangeSetValue(e, 'faculty'),
      rules: {
        required: true,
      },
    },
    {
      key: '26',
      span: 12,
      name: 'get_student_loan',
      label: 'กู้กยศ./กรอ.หรือไม่',
      type: 'CHECKBOX',
      options: [{value: true, label: ''}],
      hidden: job_cat === 'employed',
      // onChangeCustom: (e) => onChangeSetValue(e, 'get_student_loan'),
    },
    {
      key: '27',
      span: 12,
      name: 'branch',
      label: 'สาขาวิชา',
      type: 'INPUT',
      hidden: job_cat === 'employed',
      // onChangeCustom: (e) => onChangeSetValue(e, 'branch'),
    },
    {
      key: '28',
      span: 12,
      name: 'block_payment',
      label: 'ล็อคการชำระ',
      type: 'CHECKBOX',
      options: [{value: true, label: ''}],
      onChangeCustom: (e) => onChangeSetValue(e, 'block_payment'),
    },
    {
      key: '29',
      span: 6,
      name: 'grade_before',
      label: 'เกรดลูกค้า',
      type: 'SELECT',
      placeholder: 'เลือก',
      options: [
        {label: '', value: '-'},
        {label: 'A (0-5)', value: 'A'},
        {label: 'B (6-15)', value: 'B'},
        {label: 'C (16-59)', value: 'C'},
        {label: 'D (60+)', value: 'D'},
        {label: '? (ยังไม่ระบุ)', value: '?'},
        {label: 'V (VIP)', value: 'V'},
        {label: 'X (Blocked)', value: 'X'},
      ],
      // onChangeCustom: (e) => onChangeSetValue(e, 'grade_before'),
      rules: {
        required: true,
      },
    },
    {
      key: '30',
      span: 6,
      name: 'grade_after',
      label: 'เกรดหลังทำสัญญา',
      type: 'SELECT',
      placeholder: 'เลือก',
      options: [
        {label: '', value: '-'},
        {label: 'A (0-5)', value: 'A'},
        {label: 'B (6-15)', value: 'B'},
        {label: 'C (16-59)', value: 'C'},
        {label: 'D (60+)', value: 'D'},
        {label: '? (ยังไม่ระบุ)', value: '?'},
        {label: 'V (VIP)', value: 'V'},
        {label: 'X (Blocked)', value: 'X'},
      ],
      // onChangeCustom: (e) => onChangeSetValue(e, 'grade_after'),
    },
  ];
};

export const UpDateCustomerContactInfo = ({onChangeSetValue}) => {
  return [
    {
      key: '31',
      span: 12,
      name: 'person1FirstName',
      label: 'ชื่อจริง1',
      type: 'INPUT',
      placeholder: 'เช่น สมชาย',
      rules: {
        required: true,
        same: true,
      },
      // onChangeCustom: (e) => onChangeSetValue(e, 'person1FirstName'),
    },
    {
      key: '32',
      span: 12,
      name: 'person1LastName',
      label: 'นามสกุล1',
      type: 'INPUT',
      placeholder: 'เช่น รักแมว',
      rules: {
        required: true,
      },
      // onChangeCustom: (e) => onChangeSetValue(e, 'person1LastName'),
    },
    {
      key: '33',
      span: 12,
      name: 'person1Tel',
      label: 'เบอร์โทรศัพท์1',
      type: 'INPUT',
      placeholder: 'เช่น 0811111111',
      rules: {
        required: true,
        same: true,
        maxLength: {
          value: 10,
          message: 'ระบุจำนวน 10 ตัวอักษร',
        },
        minLength: {
          value: 10,
          message: 'ระบุจำนวน 10 ตัวอักษร',
        },
        pattern: {
          value: /^[0-9]+$/,
          message: 'ต้องเป็นตัวเลขเท่านั้น',
        },
      },
      // onChangeCustom: (e) => onChangeSetValue(e, 'person1Tel'),
    },
    {
      key: '34',
      span: 12,
      name: 'person1Relationship',
      label: 'ความสัมพันธ์1',
      type: 'INPUT',
      placeholder: 'เช่น พ่อ แม่ เพื่อน แฟน',
      rules: {
        required: true,
      },
      // onChangeCustom: (e) => onChangeSetValue(e, 'person1Relationship'),
    },
    {
      key: '35',
      span: 12,
      name: 'person2FirstName',
      label: 'ชื่อจริง2',
      type: 'INPUT',
      rules: {
        // required: true,
        same: true,
      },
      // onChangeCustom: (e) => onChangeSetValue(e, 'person2FirstName'),
    },
    {
      key: '36',
      span: 12,
      name: 'person2LastName',
      label: 'นามสกุล2',
      type: 'INPUT',
      // rules: {
      //   required: true,
      // },
      // onChangeCustom: (e) => onChangeSetValue(e, 'person2LastName'),
    },
    {
      key: '37',
      span: 12,
      name: 'person2Tel',
      label: 'เบอร์โทรศัพท์2',
      type: 'INPUT',
      rules: {
        // required: true,
        same: true,
        maxLength: {
          value: 10,
          message: 'ระบุจำนวน 10 ตัวอักษร',
        },
        minLength: {
          value: 10,
          message: 'ระบุจำนวน 10 ตัวอักษร',
        },
        pattern: {
          value: /^[0-9]+$/,
          message: 'ต้องเป็นตัวเลขเท่านั้น',
        },
      },
      // onChangeCustom: (e) => onChangeSetValue(e, 'person2Tel'),
    },
    {
      key: '38',
      span: 12,
      name: 'person2Relationship',
      label: 'ความสัมพันธ์2',
      type: 'INPUT',
      // rules: {
      //   required: true,
      // },
      // onChangeCustom: (e) => onChangeSetValue(e, 'person2Relationship'),
    },
    {
      key: '39',
      span: 12,
      name: 'person3FirstName',
      label: 'ชื่อจริง3',
      type: 'INPUT',
      rules: {
        // required: true,
        same: true,
      },
      // onChangeCustom: (e) => onChangeSetValue(e, 'person3FirstName'),
    },
    {
      key: '40',
      span: 12,
      name: 'person3LastName',
      label: 'นามสกุล3',
      type: 'INPUT',
      // rules: {
      //   required: true,
      // },
      // onChangeCustom: (e) => onChangeSetValue(e, 'person3LastName'),
    },
    {
      key: '41',
      span: 12,
      name: 'person3Tel',
      label: 'เบอร์โทรศัพท์3',
      type: 'INPUT',
      rules: {
        // required: true,
        same: true,
        maxLength: {
          value: 10,
          message: 'ระบุจำนวน 10 ตัวอักษร',
        },
        minLength: {
          value: 10,
          message: 'ระบุจำนวน 10 ตัวอักษร',
        },
        pattern: {
          value: /^[0-9]+$/,
          message: 'ต้องเป็นตัวเลขเท่านั้น',
        },
      },
      // onChangeCustom: (e) => onChangeSetValue(e, 'person3Tel'),
    },
    {
      key: '42',
      span: 12,
      name: 'person3Relationship',
      label: 'ความสัมพันธ์3',
      type: 'INPUT',
      // rules: {
      //   required: true,
      // },
      // onChangeCustom: (e) => onChangeSetValue(e, 'person3Relationship'),
    },
  ];
};

export const editablePaymentDetail = ['id_card', 'name', 'tel'];

export const DownPaymentDetailForm = () => {
  return [
    {
      key: '1',
      span: 16,
      name: 'id_card',
      label: 'เลขบัตรประชาชน',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 16,
      name: 'name',
      label: 'ชื่อ-นามสกุล',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 16,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
  ];
};

export const DownPaymentDetailForm2 = () => {
  return [
    {
      key: '4',
      span: 16,
      name: 'date_of_birth',
      label: 'วันเดือนปีเกิด',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '5',
      span: 16,
      name: 'user_age',
      label: 'อายุ',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '6',
      span: 16,
      name: 'facebook',
      label: 'Facebook',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '7',
      span: 16,
      name: 'line_id',
      label: 'Line ID',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '8',
      span: 16,
      name: 'status',
      label: 'สถานะ',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '9',
      span: 16,
      name: 'model',
      label: 'รุ่น',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '10',
      span: 16,
      name: 'storage',
      label: 'ความจุ',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '11',
      span: 16,
      name: 'color',
      label: 'สี',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '11',
      span: 16,
      name: 'combo',
      label: 'คอมโบ',
    },
    {
      key: '12',
      span: 16,
      name: 'down_payment',
      label: 'ราคาดาวน์',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '13',
      span: 16,
      name: 'period',
      label: 'ระยะเวลาออมดาวน์',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '14',
      span: 16,
      name: 'down_payment_type',
      label: 'รอบการออมดาวน์',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '15',
      span: 16,
      name: 'payment_per_round',
      label: 'ยอดออมต่อรอบ',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '16',
      span: 16,
      name: 'paid_amount',
      label: 'จ่ายแล้ว',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '17',
      span: 16,
      name: 'remain_amount',
      label: 'คงเหลือ',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '18',
      span: 16,
      name: 'start_date',
      label: 'วันที่เริ่มออมดาวน์',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '19',
      span: 16,
      name: 'end_date',
      label: 'วันที่สิ้นสุดออมดาวน์',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '20',
      span: 16,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
  ];
};

export const CancelDownPaymentForm = ({data, handleChange, onPreview}) => {
  const {idCardImageList, bookBankImage, checkNegative} = data;

  return [
    {
      key: '1',
      span: 18,
      name: 'id_card_image',
      label: 'อัพโหลดรูปบัตรประชาชน:',
      type: 'FILE_UPLOAD_WITH_HEADER',
      oneLine: true,
      accept: 'image/*',
      onUpload: handleChange,
      fileList: idCardImageList,
      onPreview: onPreview,
      // onRemove: handleRemove,
      urlUpload: `${API_URL}/admin/contract/getUrlUpload?`,
      imageCat: 'downPayment_idCard',
      // disabled: true,
      rules: {
        required: checkNegative,
      },
    },
    {
      key: '2',
      span: 18,
      name: 'book_bank_image',
      label: 'อัพโหลดรูปหน้า Book Bank:',
      type: 'FILE_UPLOAD_WITH_HEADER',
      oneLine: true,
      accept: 'image/*',
      onUpload: handleChange,
      fileList: bookBankImage,
      onPreview: onPreview,
      // onRemove: handleRemove,
      urlUpload: `${API_URL}/admin/contract/getUrlUpload?`,
      imageCat: 'downPayment_bookBank',
      // disabled: true,
      rules: {
        required: checkNegative,
      },
    },
  ];
};

export const CancelDownPaymentForm2 = ({data, handleChange, onPreview}) => {
  const {changeNameImageList, enabledChangeName} = data;

  return [
    {
      key: '1',
      span: 18,
      name: 'change_name_image',
      label: 'อัพโหลดรูปใบเปลี่ยนชื่อ:',
      type: 'FILE_UPLOAD_WITH_HEADER',
      oneLine: true,
      accept: 'image/*',
      onUpload: handleChange,
      fileList: changeNameImageList,
      onPreview: onPreview,
      // onRemove: handleRemove,
      urlUpload: `${API_URL}/admin/contract/getUrlUpload?`,
      imageCat: 'downPayment_changeName',
      // disabled: true,
      rules: {
        required: enabledChangeName,
      },
    },
    {
      key: '2',
      span: 18,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      rows: 2,
      // defaultValue: remarkContractOnline || '',
      // rules: {
      //   required: requiredRemark,
      // },
    },
  ];
};

export const DownPaymentVerifyForm = ({data}) => {
  const {book_bank_image, profile_image, rename_image} = data;

  return [
    {
      key: '1',
      span: 11,
      name: 'downpayment_no',
      label: 'เลขที่หนังสือออมดาวน์',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '2',
      span: 11,
      name: 'name',
      label: 'ชื่อลูกค้า',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '3',
      span: 22,
      name: 'product_name',
      label: 'ชื่อสินค้า',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '4',
      span: 22,
      name: 'return_amount',
      label: 'ยอดคงเหลือหลังหักค่าธรรมเนียม',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '5',
      span: 11,
      name: 'book_bank_no',
      label: 'เลขที่บัญชี',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '6',
      span: 11,
      name: 'book_bank_name',
      label: 'ชื่อบัญชี',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '7',
      span: 11,
      name: 'bank_name',
      label: 'ธนาคาร',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '8',
      span: 11,
      name: 'bank_branch',
      label: 'สาขา',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '9',
      span: 22,
      name: 'admin_cancel',
      label: 'พนักงานที่กดยกเลิก',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '10',
      span: 7,
      name: 'book_bank_image',
      label: 'รูป bookbank',
      type: 'VIEWIMAGE',
      disabled: true,
      defaultValue: book_bank_image !== '-' ? book_bank_image : noImage,
    },
    {
      key: '11',
      label: '',
      span: 1,
    },
    {
      key: '12',
      span: 7,
      name: 'profile_image',
      label: 'รูปคู่บัตรประชาชน',
      type: 'VIEWIMAGE',
      disabled: true,
      defaultValue: profile_image !== '-' ? profile_image : noImage,
    },
    {
      key: '13',
      label: '',
      span: 1,
    },
    {
      key: '14',
      span: 7,
      name: 'rename_image',
      label: 'รูปใบเปลี่ยนชื่อ',
      type: 'VIEWIMAGE',
      disabled: true,
      defaultValue: rename_image !== '-' ? rename_image : noImage,
    },
    {
      key: '15',
      span: 22,
      align: 'right',
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      rows: 2,
    },
  ];
};

export const EditRemarkStockSerial = () => {
  return [
    {
      key: '1',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      rows: 2,
      rules: {
        required: true,
      },
    },
  ];
};
