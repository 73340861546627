import { EditOutlined, UploadOutlined } from '@ant-design/icons';
import { TableContainer } from '@material-ui/core';
import { Col, Modal, Row, Table as TableAntd, Tag, Upload, message, Input, Button, Descriptions, Spin } from 'antd';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import { DownPaymentDetailForm, DownPaymentDetailForm2, editablePaymentDetail } from '../../../constants/global/actionform';
import { useDownPayment } from '../../../hook/useDownPayment';
import { color } from '../../../resources/color';
import { Icon } from '../../../resources/icon';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { StatementDownPaymentDetailLogColumn } from '../../../constants/global/columnTableForm';
import { NOTI_RE_UPLOAD_DOWN_PAYMENT, POST, POST_FORM_DATA, PUT, UPLOAD_PROFILE_IMAGE, USER_DETAIL_UPDATE } from '../../../services/index';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

export const DownPaymentDetailModal = ({ title, visible, onClose, data }) => {
  const [value, setValue] = useState({});
  const { dispatchExportDownPayment } = useDownPayment();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [editable, setEditable] = useState({
    id_card: {
      select: false,
      value: '',
    },
    name: {
      select: false,
      value: '',
    },
    tel: {
      select: false,
      value: '',
    },
  });

  useEffect(() => {
    if (JSON.stringify(data && data.record[0]) !== '{}' && data.record[0]) {
      const newValue = data.record[0];
      setValue(newValue);
      setEditable({
        id_card: {
          select: false,
          value: value.id_card,
        },
        name: {
          select: false,
          value: value.name,
        },
        tel: {
          select: false,
          value: value.tel,
        },
      });
      setImageUrl(newValue.profile_image);
      setFileList([
        {
          uid: '-1',
          status: 'done',
          url: newValue.profile_image,
        },
      ]);
    }
  }, [data]);

  const onCloseMain = () => {
    clear();
    onClose();
  };

  const onNotiReUpload = (params) => {
    if ('user_id' in params) {
      Modal.confirm({
        title: 'ยืนยัน',
        icon: <Icon.warningAlert />,
        content: 'แจ้งเตือน ให้ลูกค้าอัพโหลดรูปภาพใหม่',
        onOk() {
          onNotiReUploadSubmit(params);
        },
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
      });
    }
  };

  const onNotiReUploadSubmit = async (params) => {
    setLoading(true);

    try {
      let res = await POST(NOTI_RE_UPLOAD_DOWN_PAYMENT, {
        user_id: params.user_id,
        downpayment_no: params.downpayment_no,
      });
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const downloadContract = async (downpayment_no) => {
    let result = value.pdf_url ? value.pdf_url : null;

    if (result.startsWith('PRD') || isExpiredLink(result)) {
      dispatchExportDownPayment(downpayment_no);
    } else {
      saveAs(result, `รายงานออมดาวน์เลขที่ ${downpayment_no}.pdf`);
    }

    function isExpiredLink(url) {
      // แยก query parameters จาก URL
      const urlObject = new URL(url);
      let result = false;
      // ตรวจสอบว่ามีคิวรี X-Goog-Expires ใน URL หรือไม่
      const expiresInSec = urlObject.searchParams.get('X-Goog-Expires');
      if (expiresInSec) {
        // หากมีคิวรี X-Goog-Expires ใน URL ให้ถือว่าหมดอายุ
        result = true;
      }

      return result;
    }
  };

  const tagStatus =
    value['status'] === 'รอชำระเงิน'
      ? color.load
      : value['status'] === 'กำลังใช้งาน'
        ? color.on_process
        : value['status'] === 'รอทำสัญญา'
          ? color.edit
          : value['status'] === 'ทำสัญญาเสร็จสิ้น'
            ? color.success
            : value['status'] === 'รอการเงินอนุมัติ'
              ? color.tranfer
              : value['status'] === 'ยกเลิก'
                ? color.danger
                : color.disabled;

  //   upload image profile
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = (info) => {
    if (info.fileList.length > 0) {
      if (info.fileList[0].status === 'uploading') {
        setLoading(true);
        getBase64(info.fileList[0].originFileObj, (url) => {
          info.fileList[0].status = 'done';
          info.fileList[0].url = url;
          setImageUrl(url);
          setFileList(info.fileList);
        });
        return;
      }
    } else {
      setFileList(info.fileList);
    }
  };

  const handleRemove = (file) => {
    setFileList([]);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}>
        Upload
      </div>
    </div>
  );

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      const formData = new FormData();
      formData.append('profile_image', file);
      const response = await POST_FORM_DATA(UPLOAD_PROFILE_IMAGE(value.downpayment_no), formData);
      if (response.success === true) {
        setLoading(false);
        message.success('File uploaded successfully');
      } else {
        onError(response.message || 'Upload failed');
      }
    } catch (error) {
      onError(error.error || 'Upload failed');
    }
  };
  //   end upload image profile
  const clear = () => {
    setEditable({
      id_card: { select: false, value: value.id_card },
      name: { select: false, value: value.name },
      tel: { select: false, value: value.tel },
    });
  };
  const handleEditInfo = async () => {
    setLoading(true);
    try {
      const payload = {
        code: value.id_card,
        id_card: editable.id_card.value,
        fullname: editable.name.value.trim(),
        tel: editable.tel.value,
        downpayment_no: value.downpayment_no
      };

      const isBlank = Object.values(payload).some((value) => /^\s*$/.test(value));

      if (isBlank) throw new Error('โปรดกรอกข้อมูลให้ครบ');
      if (payload.id_card.length < 13) throw new Error('โปรดกรอกเลขบัตรประชาชนให้ครบ 13 หลัก');
      if (payload.tel.length < 10) throw new Error('โปรดกรอกเบอร์โทรให้ครบ 10 หลัก');

      const res = await PUT(USER_DETAIL_UPDATE(value.user_id), payload);
      Modal.success({
        title: res.message,
        afterClose: onCloseMain,
      });

      clear();
    } catch (error) {
      Modal.error({ title: error.error });
    } finally {
      setLoading(false);
    }
  };

  function updateEditable(newValue, field) {
    setEditable((prevEditable) => ({
      ...prevEditable,
      [field.name]: { select: prevEditable[field.name].select, value: newValue },
    }));
  }
  return (
    <>
      <Modal title={title || 'modal'} open={visible} width={900} onCancel={onCloseMain} footer={null} maskClosable={false}>
        <div>
          <Spin spinning={loading}>
            <Row style={{ marginBottom: '22px', marginTop: '22px' }}>
              <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} style={{ textAlign: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                  {fileList.length > 0 ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: '200px' }}
                      onClick={() => {
                        handlePreview(fileList[0]);
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                  <Modal width={1000} open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                      alt="example"
                      style={{
                        width: '100%',
                      }}
                      src={previewImage}
                      onClick={() => {
                        handlePreview(fileList[0]);
                      }}
                    />
                  </Modal>
                </div>
                <br />
                <ContainerButton>
                  <Upload
                    style={{ width: '100%' }}
                    // name="avatar"
                    // listType="picture-card"
                    // className="avatar-uploader"
                    customRequest={customRequest}
                    beforeUpload={beforeUpload}
                    showUploadList={false}
                    onChange={handleChange}
                    onRemove={handleRemove}>
                    <Button style={{ backgroundColor: color.success, color: color.white }} useFor="CUSTOM" className="export">
                      <UploadOutlined /> อัปโหลดไฟล์
                    </Button>
                  </Upload>
                  <Button
                    style={{ backgroundColor: color.edit, color: color.white }}
                    useFor="CUSTOM"
                    className="ant-btn-customize"
                    onClick={() => onNotiReUpload(value)}>
                    แจ้งเตือนให้ลูกค้าเปลี่ยนรูปภาพ
                  </Button>
                </ContainerButton>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }}>
                <Descriptions>
                  {DownPaymentDetailForm().map((field) => (
                    <Descriptions.Item key={field.name} label={field.label} labelStyle={{ fontWeight: 'bold' }} span={24}>
                      {editable[field.name].select ? (
                        <Input
                          style={{ width: '40%' }}
                          defaultValue={value[field.name]}
                          value={editable[field.name].value}
                          onChange={(e) => {
                            switch (field.name) {
                              case 'id_card':
                                const idCardValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 13);
                                updateEditable(idCardValue, field);
                                break;
                              case 'tel':
                                const telValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                updateEditable(telValue, field);
                                break;
                              default:
                                const nameValue = e.target.value.replace(/[^ก-๙\s]/g, '');
                                updateEditable(nameValue, field);
                            }
                          }}
                        />
                      ) : (
                        value[field.name]
                      )}
                      {!'ทำสัญญาเสร็จสิ้น ยกเลิก'.includes(value.status) && (
                        <EditOutlined
                          style={{ marginLeft: 15, color: 'grey' }}
                          onClick={() =>
                            setEditable((prevEditable) => ({
                              ...prevEditable,
                              [field.name]: { select: !prevEditable[field.name].select, value: value[field.name] },
                            }))
                          }
                        />
                      )}
                    </Descriptions.Item>
                  ))}
                  {DownPaymentDetailForm2().map((field) =>
                    field.name === 'model' || field.name === 'storage' || field.name === 'color' ? (
                      <Descriptions.Item key={field.name} label={field.label} labelStyle={{ fontWeight: 'bold' }} span={24}>
                        {value[field.name]?.name}
                      </Descriptions.Item>
                    ) : field.name === 'status' ? (
                      <Descriptions.Item key={field.name} label={field.label} labelStyle={{ fontWeight: 'bold' }} span={24}>
                        <Tag color={tagStatus}>{value['status'] || '-'}</Tag>
                      </Descriptions.Item>
                      )
                        : field.name === 'combo' ? (
                      value['combo']?.length > 0 &&
                      value['combo']?.map((el) => {
                        return (
                          <Descriptions.Item key={el?.code} label={el?.type} labelStyle={{ fontWeight: 'bold' }} span={24}>
                            {el?.name}
                          </Descriptions.Item>
                        );
                      })
                        )
                          : (
                      <Descriptions.Item key={field.name} label={field.label} labelStyle={{ fontWeight: 'bold' }} span={24}>
                        {value[field.name]}
                      </Descriptions.Item>
                    ),
                  )}
                </Descriptions>
                <ContainerButton left>
                  <Button className="export" useFor="EXPORT" onClick={() => downloadContract(data.record[0].downpayment_no)}>
                    ดาวน์โหลดใบสัญญา
                  </Button>
                </ContainerButton>
                {Object.values(editable).some((value) => value.select) && (
                  <div style={{ position: 'absolute', top: -25, right: 0, cursor: 'pointer' }} onClick={() => handleEditInfo()}>
                    <Tag color={color.success}>บันทึก</Tag>
                  </div>
                )}
                {/* <div style={{position: 'relative'}}>
                {DownPaymentDetailForm().map((field) => (
                  <div key={field.key} style={{marginBottom: '10px'}}>
                    <span style={{fontWeight: 'bold', marginRight: '10px'}}>{field.label}:</span>
                    {field.name === 'status' ? (
                      <Tag color={tagStatus}>{value[field.name]}</Tag>
                    ) : editablePaymentDetail.includes(field.name) ? (
                      <span>
                        {editable[field.name].select ? (
                          <Input
                            style={{width: '40%'}}
                            defaultValue={value[field.name]}
                            value={editable[field.name].value}
                            onChange={(e) => {
                              switch (field.name) {
                                case 'id_card':
                                  const idCardValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 13);
                                  updateEditable(idCardValue, field);
                                  break;
                                case 'tel':
                                  const telValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                  updateEditable(telValue, field);
                                  break;
                                default:
                                  const nameValue = e.target.value.replace(/[^ก-๙\s]/g, '')
                                  updateEditable(nameValue, field);
                              }
                            }}
                          />
                        ) : (
                          value[field.name]
                        )}

                        {!'ทำสัญญาเสร็จสิ้น ยกเลิก'.includes(value.status) && (
                          <EditOutlined
                            style={{marginLeft: 15, color: 'grey'}}
                            onClick={() =>
                              setEditable((prevEditable) => ({
                                ...prevEditable,
                                [field.name]: {select: !prevEditable[field.name].select, value: value[field.name]},
                              }))
                            }
                          />
                        )}
                      </span>
                    ) : (
                      <span>{value[field.name]}</span>
                    )}
                  </div>
                ))}
                <ContainerButton left>
                  <Button className="export" useFor="EXPORT" onClick={() => downloadContract(data.record[0].downpayment_no)}>
                    ดาวน์โหลดใบสัญญา
                  </Button>
                </ContainerButton>
                {Object.values(editable).some((value) => value.select) && (
                  <div style={{position: 'absolute', top: -25, right: 0, cursor: 'pointer'}} onClick={() => handleEditInfo()}>
                    <Tag color={color.success}>บันทึก</Tag>
                  </div>
                )}
              </div> */}
              </Col>
            </Row>
          </Spin>
          <TableContainer>
            <TableAntd columns={StatementDownPaymentDetailLogColumn()} dataSource={value?.product_history || []} pagination={false} />
          </TableContainer>
        </div>
      </Modal>
    </>
  );
};
