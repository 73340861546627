import React, {useEffect} from 'react';
import {Modal} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {useLocation} from 'react-router-dom';
import {useSendChatconeByProcess, useGetHistory} from '../../../hook/chatcone';
import {DatatableLog, Spinners, ButtonTheme} from '../../../components';
import {ChatconeHistoryColumn} from './columns/ChatconeHistoryColumn';
import {APP_URL} from '../../../env.js';
import {ContainerButton} from '../../../styles/styledComponentGlobal';

export const ChatconeHistotyModal = ({title, content, visible, onClose, input}) => {
  const {pathname} = useLocation();
  const {chatcone, userId, grade, _id, type_lock} = input;
  const [getChatconeHistoryFn, getChatconeHistoryData, getChatconeHistoryLoading] = useGetHistory();
  const [sendChatconeByProcessFn, sendChatconeByProcessLoading] = useSendChatconeByProcess();

  useEffect(() => {
    if (userId && visible) getChatconeHistoryFn({ref_id: userId, type: 'SEND_ICLOUD_PASSWORD'});
  }, [userId, visible]);

  const openConfirm = () => {
    Modal.confirm({
      title: 'ยืนยันการส่งแจ้งเตือน',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการส่งแจ้งเตือนส่งรหัสปลดล็อก iClound?',
      onOk() {
        onSendChatconeFn();
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onSendChatconeFn = async () => {
    /* CHATCONE: 7.1, 7.2 SEND_ICLOUD_PASSWORD */
    const processType = type_lock === 'ICLOUD' ? 'SEND_ICLOUD_PASSWORD_ICLOUD' : type_lock === 'MDMICLOUD' ? 'SEND_ICLOUD_PASSWORD_MDMICLOUD' : '';
    if (chatcone?.social_id) {
      const setInput = {
        chatcone: chatcone,
        process_type: processType,
        user_id: userId,
        ref_id: _id,
        route_from: `${APP_URL}${pathname}`,
        data: {
          customerGrade: grade,
        },
      };

      await sendChatconeByProcessFn({...setInput});
      onClose();
    }
  };

  return (
    <>
      {getChatconeHistoryLoading || sendChatconeByProcessLoading ? <Spinners /> : null}
      <Modal
        title={
          <>
            <ExclamationCircleOutlined style={{color: '#faad14'}} /> {title || ''}
          </>
        }
        open={visible}
        width={840}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <div style={{textAlign: 'center', fontSize: '20px', marginBottom: '10px'}}>{content || ''}</div>
        <ContainerButton center>
          <ButtonTheme useFor="SEND_NOTI" title="ส่งแจ้งเตือนไปยังแชท" onClick={() => openConfirm()} />
        </ContainerButton>

        <DatatableLog
          style={{marginTop: '20px'}}
          columns={ChatconeHistoryColumn()}
          data={getChatconeHistoryData}
          pagination={{pageSize: 5}}
          showDivider={false}
        />
      </Modal>
    </>
  );
};
